import React, { createContext, useState } from 'react'
import { Box, Button, Container, GlobalStyles } from '@mui/material'

import { featureFlags } from '@partnerslate/core'

import { NavbarDropdown } from '../Navbar/NavbarDropdown'
import { MobileProjectsDropdown, ProjectsDropdown } from '../Navbar/ProjectsDropdown'
import { BannerManager } from './Banner'
import Logo from './Logo'
import MobileMenuHamburger from './MobileMenuHamburger'
import { NavbarLink, NavbarLinkType, NavbarMobileLink, NavbarMobileMainLink } from './NavbarLink'


export const RouterContext = createContext<NavbarRouter>((to: string) => {})

// with main_repo having react-router on v5 while monorepo uses v6, we have decided to instead
// use a function to programatically change routes instead of reconciling the differences between
// the different versions' <Link> components
// the router function should use the correct version of react-router programmatic routing to go to the next route
// i.e. navigate for v6 and history.push for v5
type NavbarRouter = (to: string) => void
export type UserAccount = {
  id: string
  emailAddress: string
  company: {
    slug: string
    msaSignerEmail: string | null
    msaReminderSentAt: Date | null
    marketplaceMSASignatureStatus: string
  }
  plan: {
    id: string
    isActivePaidSubscriber: boolean
    name: string
    planRenewsAt: Date | null
  }
  entitlements: {
    connectionsRemaining: number
    canSendConnections: boolean
    shouldSeeTrialBanner: boolean
    trialExpiresAt: Date | null
  }
}

export type AbridgedProject = {
  id: string
  name: string
  isPaid: boolean
}
type NavbarOrigin = 'monorepo' | 'main_repo'
type NavbarProps = {
  origin: NavbarOrigin
  router: NavbarRouter
  currentUser?: UserAccount | null
  isLoadingUser?: boolean
  showSearch?: boolean
  companyType?: string
  projects?: AbridgedProject[]
  showHowItWorks?: boolean
}

const PS_ACADEMY_URL = 'https://academy.partnerslate.com/'
const HOW_IT_WORKS_URL = 'https://partnerslate.com/manufacturers/'
const PS_CAPITAL_4_COMANS_URL = 'https://capital.partnerslate.com/coman'

export function Navbar({
  origin,
  router,
  currentUser = null,
  isLoadingUser = false,
  showSearch = false,
  companyType = '',
  projects = [],
  showHowItWorks = false,
}: NavbarProps) {
  const isLoggedIn = Boolean(currentUser)
  const isManufacturer = companyType === 'manufacturer'
  const monorepoRouter: NavbarLinkType = origin === 'monorepo' ? 'router' : 'regular'
  const mainrepoRouter: NavbarLinkType = origin === 'main_repo' ? 'router' : 'regular'

  return (
    <RouterContext.Provider value={router}>
      <NavbarDesktop>
        <DesktopMenu
          monorepoRouter={monorepoRouter}
          mainrepoRouter={mainrepoRouter}
          isLoggedIn={isLoggedIn}
          isLoadingUser={isLoadingUser}
          showSearch={showSearch}
          isManufacturer={isManufacturer}
          projects={projects}
          showHowItWorks={showHowItWorks}
          accountPlan={currentUser?.plan}
        />
      </NavbarDesktop>
      <NavbarMobile>
        {({ isOpen, onMainMobileMenuClose }) => (
          <MobileMenu
            monorepoRouter={monorepoRouter}
            mainrepoRouter={mainrepoRouter}
            isLoggedIn={isLoggedIn}
            isLoadingUser={isLoadingUser}
            showSearch={showSearch}
            isManufacturer={isManufacturer}
            isOpen={isOpen}
            onMainMobileMenuClose={onMainMobileMenuClose}
            projects={projects}
            showHowItWorks={showHowItWorks}
            accountPlan={currentUser?.plan}
          />
        )}
      </NavbarMobile>
      {currentUser && isManufacturer ? (
        <BannerManager currentUser={currentUser} origin={origin} />
      ) : null}
    </RouterContext.Provider>
  )
}

function NavbarDesktop({ children }: { children: React.ReactNode }) {
  return (
    <Box
      alignItems="center"
      bgcolor="primary.dark"
      display="flex"
      height={80}
      paddingX={4}
      justifyContent="space-between"
      sx={{
        '@media (max-width: 900px)': {
          display: 'none',
        },
      }}
    >
      <Logo />
      {children}
    </Box>
  )
}

function NavbarMobile({
  children,
}: {
  children: React.FC<{ isOpen: boolean; onMainMobileMenuClose: () => void }>
}) {
  const [isOpen, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box bgcolor="primary.dark">
      <Container
        sx={{
          display: 'none',
          height: 56,
          placeContent: 'center',
          '@media (max-width: 900px)': {
            display: 'grid',
          },
        }}
      >
        <Box
          onClick={() => setOpen(!isOpen)}
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          height={isOpen ? 28 : 24}
          left={32}
          position="absolute"
          top={16}
          width={28}
          sx={{
            cursor: 'pointer',
            '&:hover': isOpen
              ? {}
              : {
                  top: 12,
                  height: 32,
                },
          }}
        >
          <MobileMenuHamburger isOpen={isOpen} />
        </Box>
        {children({ isOpen, onMainMobileMenuClose: handleClose })}
        <Logo />
      </Container>
    </Box>
  )
}

type MenuProps = {
  isLoggedIn: boolean
  isLoadingUser: boolean
  showSearch: boolean
  isManufacturer: boolean
  monorepoRouter: NavbarLinkType
  mainrepoRouter: NavbarLinkType
  projects: AbridgedProject[]
  showHowItWorks: boolean
  accountPlan?: UserAccount['plan']
}

function DesktopMenu({
  isLoggedIn,
  isLoadingUser,
  showSearch,
  isManufacturer,
  monorepoRouter,
  mainrepoRouter,
  projects,
  showHowItWorks,
  accountPlan,
}: MenuProps) {
  const shouldUseNewSettingsPage = featureFlags.useFeatureFlag('use_new_settings_page', null)
  const settingsUrl = shouldUseNewSettingsPage ? '/account' : '/account-settings'

  if (isLoadingUser) return null

  function renderPlanLink(onClose: () => void) {
    if (!accountPlan || !isManufacturer) return null

    if (accountPlan.isActivePaidSubscriber) {
      return (
        <NavbarLink
          text="Manage Plan"
          url={`${settingsUrl}/subscription`}
          linkType={shouldUseNewSettingsPage ? monorepoRouter : mainrepoRouter}
          onClose={onClose}
        />
      )
    }

    return (
      <NavbarLink
        text="Get unlimited connections"
        url="/marketplace/coman-pricing"
        linkType={monorepoRouter}
        onClose={onClose}
        sx={{
          color: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) => theme.palette.secondary.light,
          margin: 0,
          pl: '20px',
          py: 2,
          fontSize: 13,
        }}
      />
    )
  }

  if (!isLoggedIn) {
    return (
      <Box>
        {showHowItWorks ? (
          <NavbarLink text="How It Works" url={HOW_IT_WORKS_URL} external />
        ) : (
          <NavbarLink text="PS Academy" url={PS_ACADEMY_URL} external />
        )}
        <NavbarLink text="Log in" url="/" />
        <Button
          href="/register"
          variant="contained"
          sx={{ fontSize: 16, fontWeight: 'bold', mx: 2, px: 12, py: 1 }}
        >
          Sign up
        </Button>
      </Box>
    )
  }

  return (
    <Box>
      {isManufacturer ? (
        <NavbarLink text="Marketplace" url="/marketplace" linkType={monorepoRouter} />
      ) : null}
      {isManufacturer ? <NavbarLink text="Leads" url="/leads" linkType={monorepoRouter} /> : null}
      {!isManufacturer && showSearch ? (
        <NavbarLink text="Search" url="/_search" linkType={mainrepoRouter} />
      ) : null}
      {!isManufacturer ? <ProjectsDropdown linkType={monorepoRouter} projects={projects} /> : null}
      {isManufacturer ? (
        <NavbarLink text="Engagements" url="/engagements" linkType={mainrepoRouter} />
      ) : null}
      {isManufacturer ? (
        <NavbarLink text="PS Capital" url={PS_CAPITAL_4_COMANS_URL} external />
      ) : null}
      {isManufacturer ? (
        <NavbarLink text="How it works" url={HOW_IT_WORKS_URL} external />
      ) : (
        <NavbarLink text="PS Academy" url={PS_ACADEMY_URL} external />
      )}

      <NavbarDropdown label="Account">
        {({ onClose }) => (
          <Box display="flex" flexDirection="column">
            {renderPlanLink(onClose)}
            <NavbarLink
              text="Company Profile"
              url={'/p/'}
              linkType={monorepoRouter}
              onClose={onClose}
            />
            <NavbarLink
              text="Your Settings"
              url={`${settingsUrl}/your-information`}
              linkType={shouldUseNewSettingsPage ? monorepoRouter : mainrepoRouter}
              onClose={onClose}
            />
            <NavbarLink text="Help" url="http://help.partnerslate.com/en/" external />
            <NavbarLink text="Logout" url="/logout" linkType={mainrepoRouter} onClose={onClose} />
          </Box>
        )}
      </NavbarDropdown>
    </Box>
  )
}

type MobileMenuProps = {
  isLoggedIn: boolean
  isLoadingUser: boolean
  showSearch: boolean
  isManufacturer: boolean
  isOpen: boolean
  onMainMobileMenuClose: () => void
  monorepoRouter: NavbarLinkType
  mainrepoRouter: NavbarLinkType
  projects: AbridgedProject[]
  showHowItWorks: boolean
  accountPlan?: UserAccount['plan']
}

function MobileMenu({
  isLoggedIn,
  isLoadingUser,
  showSearch,
  isManufacturer,
  isOpen,
  onMainMobileMenuClose,
  monorepoRouter,
  mainrepoRouter,
  projects,
  showHowItWorks,
  accountPlan,
}: MobileMenuProps) {
  const shouldUseNewSettingsPage = featureFlags.useFeatureFlag('use_new_settings_page', null)
  const settingsUrl = shouldUseNewSettingsPage ? '/account' : '/account-settings'

  if (isLoadingUser) return null

  function renderPlanLink(onClose: () => void) {
    if (!accountPlan || !isManufacturer) return null

    if (accountPlan.isActivePaidSubscriber) {
      return (
        <NavbarMobileLink
          text="Manage Plan"
          url={`${settingsUrl}/subscription`}
          linkType={shouldUseNewSettingsPage ? monorepoRouter : mainrepoRouter}
          onClick={onClose}
        />
      )
    }

    return (
      <NavbarMobileLink
        text="Get unlimited connections"
        url="/marketplace/coman-pricing"
        linkType={monorepoRouter}
        onClick={onClose}
        sx={{
          color: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) => theme.palette.secondary.light,
        }}
      />
    )
  }

  const PreventMobileBodyScroll = ({ children }: { children: React.ReactNode }) => {
    return (
      <>
        <GlobalStyles
          styles={{
            body: {
              overflowY: isOpen ? 'hidden' : 'auto',
            },
          }}
        />
        {children}
      </>
    )
  }

  if (!isLoggedIn) {
    return (
      <PreventMobileBodyScroll>
        <Box
          bgcolor="primary.dark"
          height="calc(100vh - 56px)"
          left={0}
          display={isOpen ? 'flex' : 'none'}
          flexDirection="column"
          justifyContent="space-between"
          pb={4}
          position="absolute"
          top={56}
          zIndex={10}
          width="100%"
        >
          <Box display="flex" flexDirection="column">
            {showHowItWorks ? (
              <NavbarLink text="How It Works" url={HOW_IT_WORKS_URL} external />
            ) : (
              <NavbarLink text="PS Academy" url={PS_ACADEMY_URL} external />
            )}
            <NavbarMobileLink text="Help" url="http://help.partnerslate.com/en/" external />
          </Box>
          <Box display="flex" flexDirection="column">
            <NavbarMobileMainLink text="Login" url="/" />
            <Button
              href="/register"
              variant="contained"
              sx={{ fontSize: 18, fontWeight: 'bold', mx: 4, px: 12, py: 1 }}
            >
              Sign up
            </Button>
          </Box>
        </Box>
      </PreventMobileBodyScroll>
    )
  }

  return (
    <PreventMobileBodyScroll>
      <Box
        bgcolor="primary.dark"
        height="calc(100vh - 56px)"
        left={0}
        position="absolute"
        display={isOpen ? 'flex' : 'none'}
        flexDirection="column"
        justifyContent="space-between"
        top={56}
        zIndex={10}
        width="100%"
      >
        <Box display="flex" flexDirection="column">
          {renderPlanLink(onMainMobileMenuClose)}
          {isManufacturer ? (
            <NavbarMobileLink
              text="Marketplace"
              url="/marketplace"
              linkType={monorepoRouter}
              onClick={onMainMobileMenuClose}
            />
          ) : null}
          {isManufacturer ? (
            <NavbarMobileLink
              text="Leads"
              url="/leads"
              linkType={monorepoRouter}
              onClick={onMainMobileMenuClose}
            />
          ) : null}
          {!isManufacturer && showSearch ? (
            <NavbarMobileLink
              text="Leads"
              url="/_search"
              linkType={mainrepoRouter}
              onClick={onMainMobileMenuClose}
            />
          ) : null}
          {!isManufacturer ? (
            <MobileProjectsDropdown
              linkType={monorepoRouter}
              onMainMobileMenuClose={onMainMobileMenuClose}
              projects={projects}
            />
          ) : null}
          {isManufacturer ? (
            <NavbarMobileLink
              text="Engagements"
              url="/engagements"
              linkType={mainrepoRouter}
              onClick={onMainMobileMenuClose}
            />
          ) : null}
          {isManufacturer ? (
            <NavbarMobileLink
              text="PS Capital"
              url={PS_CAPITAL_4_COMANS_URL}
              external
              onClick={onMainMobileMenuClose}
            />
          ) : null}
          {isManufacturer ? (
            <NavbarMobileLink
              text="How it works"
              url={HOW_IT_WORKS_URL}
              external
              onClick={onMainMobileMenuClose}
            />
          ) : (
            <NavbarMobileLink
              text="PS Academy"
              url={PS_ACADEMY_URL}
              external
              onClick={onMainMobileMenuClose}
            />
          )}
          <hr
            style={{
              backgroundColor: 'white',
              borderWidth: 0,
              height: 0.5,
              width: 'calc(100% - 48px)',
            }}
          />
          <NavbarMobileLink
            text="Company Profile"
            url={'/p/'}
            linkType={monorepoRouter}
            onClick={onMainMobileMenuClose}
          />
          <NavbarMobileLink
            text="Your Settings"
            url={`${settingsUrl}/your-information`}
            linkType={shouldUseNewSettingsPage ? monorepoRouter : mainrepoRouter}
            onClick={onMainMobileMenuClose}
          />
          <NavbarMobileLink
            text="Help"
            url="http://help.partnerslate.com/en/"
            external
            onClick={onMainMobileMenuClose}
          />
        </Box>
        <NavbarMobileMainLink
          text="Logout"
          url="/logout"
          linkType={mainrepoRouter}
          onClick={onMainMobileMenuClose}
        />
      </Box>
    </PreventMobileBodyScroll>
  )
}
