import { Typography } from '@mui/material'

import { BaseBanner } from './BaseBanner'

type FreeBannerProps = {
  connectionsLeft: number
  repoClickOrigin: 'monorepo' | 'main_repo'
  renewalDate: string | null
}
export function BasicPlanBanner(props: FreeBannerProps) {
  const renewalText = props.renewalDate ? ` Renews on ${props.renewalDate}.` : ''
  const connectionText = props.connectionsLeft === 1 ? 'connection' : 'connections'

  return (
    <BaseBanner
      bannerBody={
        <Typography>{`You have ${props.connectionsLeft} ${connectionText} left for this period.${renewalText}`}</Typography>
      }
    />
  )
}
