import { getUnixTime } from 'date-fns'

import {
  GROUP_KEYS,
  mixpanelAlias,
  mixpanelPeopleSet,
  mixpanelTrack,
  sendCometlyEvent,
} from '@partnerslate/core/instrumentation'
import config from '@partnerslate/feature/central/config'
import {
  CompanyType,
  IntercomUser,
  UserAccount,
} from '@partnerslate/feature/central/domain/userAccount'

import { CompleteSignupForm } from '../domain/signup'

export const openRegisterSignup = () => {
  mixpanelTrack('Open sign up')
}

export const chooseCompanyType = (companyType: CompanyType) => {
  mixpanelTrack('Sign up - Company type', {
    'Account type': companyType,
  })
}

export const chooseManufacturerType = () => {
  mixpanelTrack('Sign up - Manufacturer type')
}

export const fillInPersonalDetails = () => {
  mixpanelTrack('Sign up - Personal details')
}

export const fillInCompanyDetails = () => {
  mixpanelTrack('Sign up - Company details')
}

export const fillInBrandDetails = () => {
  mixpanelTrack('Sign up - Brand details')
}

export const fillInComanCompanyProfile = () => {
  mixpanelTrack('Sign up - Coman Company Profile')
}
export const fillInComanCompanyDetails = () => {
  mixpanelTrack('Sign up - Coman Company Details')
}
export const fillInDiversityDetails = () => {
  mixpanelTrack('Sign up - Demographics Survey')
}

export const signupReadinessWarning = (email: string) => {
  mixpanelTrack('Sign up Readiness Warning', { 'Lead Email': email })
}

export const signupEarlyStageBrandQuestions = (data: CompleteSignupForm): void => {
  mixpanelTrack('Sign up - Early stage brand questions', {
    'Initial production run': data.initialRunSize,
    'Product recipe': data.productDevelopment,
    'Funding state': data.fundingState,
  })
}

export const signupCompleted = async (
  user: UserAccount,
  cb: () => void = () => {},
  readinessWarning: boolean = false,
) => {
  if (user.company.companyType === 'brand') {
    const BRAND_SIGN_UP_COMETLY_URL =
      'https://t.cometlytrack.com/4503599676000035/webhook/01jq7n0v5gp5yd1x7qvsx9e7m2'

    await sendCometlyEvent(
      BRAND_SIGN_UP_COMETLY_URL,
      {
        firstName: user.firstName,
        lastName: user.lastName,
        userId: user.id,
        userEmail: user.emailAddress,
        companyId: user.company.id,
        companyType: user.company.companyType,
        companyPhone: user.company.companyPhone,
      },
      config.appEnv,
    )
  }

  // make a MixPanel alias so that the anonymous distinct_id is "merged" with
  // the correct user.id
  mixpanelAlias(user.id)

  // Complete MixPanel user profile
  // MixPanel sign up properties taken from backend/helpers/mixpanel.py:63
  mixpanelTrack('Sign Up', {
    'User ID': user.id,
    [GROUP_KEYS.companyId]: user.company.id,
    'Sign Up Date': user.dateJoined,
    'Account Type': user.company.companyType,
    'Company Name': user.company.companyName,
    'Business Stage': user.company.brandStage,
    'Readiness Warning': readinessWarning,
  })

  // MixPanel People properties taken from backend/helpers/mixpanel.py:170
  // backend.helpers.mixpanel.MixPanel.update_user
  mixpanelPeopleSet({
    $first_name: user.firstName,
    $last_name: user.lastName,
    $email: user.emailAddress,
    'Account Type': user.company.companyType,
    'MSA Status': user.company.marketplaceMSASignatureStatus,
    [GROUP_KEYS.companyId]: user.company.id,
  })

  const metadata = {
    userId: user.id,
    userEmail: user.emailAddress,
    companyId: user.company.id,
    companyType: user.company.companyType,
    event_callback: cb,
  }
  sendGtagEvent('signup-complete', metadata)
}

const IntercomValues: Record<CompanyType | string, string> = {
  brand: 'Brand',
  manufacturer: 'Manufacturer',
}

export const createIntercomUser = (user: IntercomUser) => {
  if (!window.Intercom) {
    console.warn('No intercom available')
    return
  }

  window.Intercom('boot', {
    name: user.name,
    email: user.email,
    user_id: user.userId,
    created_at: user.dateJoined ? getUnixTime(user.dateJoined) : null,
    type: IntercomValues[user.companyType],
  })
}

export const updateIntercomUser = (user: Partial<IntercomUser>) => {
  if (!window.Intercom) {
    console.warn('No intercom available')
    return
  }

  window.Intercom('update', {
    name: user.name,
    email: user.email,
    user_id: user.userId,
    created_at: user.dateJoined ? getUnixTime(user.dateJoined) : null,
  })
}

export function sendGtagEvent(eventName: string, eventParams: any = {}) {
  if (!window.gtag) {
    console.warn('No gtag available')

    if (eventParams.event_callback) {
      eventParams.event_callback()
    }

    return
  }

  window.gtag('event', eventName, eventParams)
}

export const acceptingTeamInvite = (companyId: string): void => {
  mixpanelTrack('Team Invite Accepting', { 'Company ID': companyId })
}

export const acceptedTeamInvite = (companyId: string): void => {
  mixpanelTrack('Team Invite Accepted', { 'Company ID': companyId })
}
