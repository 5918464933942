import { Box, Container, Typography } from '@mui/material'

import { FourOhFourImg } from '../../assets/images'

type Props = {
  img?: string
  alt?: string
  caption?: string
  isLargeImage?: boolean
}

export default function ErrorPageWithImage({
  img = FourOhFourImg,
  alt = '404',
  caption = 'This page cannot be found.',
  isLargeImage = true,
}: Props) {
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2} mt={4} width={400}>
        <img style={{ width: isLargeImage ? '100%' : '50%' }} src={img} alt={alt} />
        <Typography align="center" color="text.secondary">
          {caption}
        </Typography>
      </Box>
    </Container>
  )
}
