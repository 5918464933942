import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { Box, InputAdornment, Link as MuiLink, TextField, Typography } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'

import { ButtonWithLoading } from '@partnerslate/ui-components'

import LoginPhoto from '../../assets/images/orange-tree.jpg'
import { Layout } from '../../components/Layout'
import { ResetPassword, resetPasswordSchema, useResetPassword } from './resetPassword'

export function ResetPasswordScreen() {
  return (
    <Layout
      left={
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <ResetPasswordForm />
        </Box>
      }
      right={
        <Box
          sx={{
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${LoginPhoto})`,
          }}
        />
      }
    />
  )
}

function ResetPasswordForm() {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetPassword>({
    resolver: zodResolver(resetPasswordSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  })

  const reset = useResetPassword(setError)

  const onSubmit = (data: ResetPassword) => {
    reset.mutate(data)
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: (theme) => theme.spacing(35),
        width: '100%',
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography
        component="h1"
        variant="h3"
        fontWeight={(theme) => theme.typography.fontWeightBold}
      >
        Forgot Your Password?
      </Typography>
      {reset.isSuccess ? (
        <>
          <Typography>If you have an account, a reset link was sent to your email.</Typography>
          <MuiLink component={Link} to="/login">
            <Typography variant="body2">Go Back to Login</Typography>
          </MuiLink>
        </>
      ) : (
        <>
          <Typography>
            Please enter your email below to receive your password reset instructions.
          </Typography>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="email"
                fullWidth
                label="Email"
                variant="outlined"
                placeholder="Your email"
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {errors.root?.['serverError'].message && (
            <Typography variant="caption" color={(theme) => theme.palette.error.main}>
              {errors.root?.['serverError'].message}
            </Typography>
          )}

          <ButtonWithLoading
            isLoading={reset.isLoading}
            type="submit"
            variant="contained"
            size="large"
            fullWidth
          >
            Reset
          </ButtonWithLoading>
        </>
      )}
    </Box>
  )
}
