import { useEffect, useState } from 'react'
import { Check as CheckIcon } from '@mui/icons-material'
import { WarningAmber as WarningAmberIcon } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { differenceInHours } from 'date-fns'

import { UserAccount } from '..'
import { LinkAnchor } from '../../Anchor'
import { ButtonWithLoading } from '../../Button'
import { useSendMSAReminder } from './api/queries'
import { BaseBanner } from './BaseBanner'
import * as instrumentation from './helpers/instrumentation'

type MSABannerProps = {
  currentUser: UserAccount
}

export function MSABanner({ currentUser }: MSABannerProps) {
  const { mutate: sendMSAReminder, isLoading, status } = useSendMSAReminder()
  const [canSendReminder, setCanSendReminder] = useState(false)

  useEffect(() => {
    if (currentUser) {
      setCanSendReminder(isReadyToSendReminder(currentUser.company.msaReminderSentAt, currentUser))
    }
  }, [currentUser])

  if (!currentUser) return null

  // Hellosign has a limitation of one reminder per hour: https://developers.hellosign.com/api/reference/operation/signatureRequestRemind/
  function isReadyToSendReminder(sentAt: Date | null, currentUser: MSABannerProps['currentUser']) {
    const isMSASignedByThisUser = currentUser.company.msaSignerEmail === currentUser.emailAddress
    // If the user is not the MSA signer, she can request a new signature_request
    if (!isMSASignedByThisUser) return true

    // If no reminder has been sent yet, we are good to go
    if (sentAt === null) return true
    const hoursDifference = differenceInHours(new Date(), sentAt)
    return hoursDifference >= 1
  }

  const handleSendReminder = () => {
    sendMSAReminder(
      { companySlug: currentUser.company.slug },
      {
        onSuccess: () => {
          instrumentation.msaTermsReminderSent('Banner')
          setCanSendReminder(false)
        },
      },
    )
  }

  const isButtonDisabled = () => !canSendReminder || ['loading', 'success'].includes(status)

  return (
    <BaseBanner
      bannerBody={
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" alignItems="center" my={1}>
            <WarningAmberIcon
              color="warning"
              sx={{ mr: 1, display: { xs: 'none', md: 'block' } }}
            />
            <Typography fontWeight="bold">
              Your signature is still required on the referral agreement in order to connect with
              brands.{' '}
              <LinkAnchor url="https://partnerslate.com/msa/" text="More info" newWindow={true} />
            </Typography>
          </Box>
          {currentUser.company.msaSignerEmail ? (
            <Typography variant="caption" mb={1}>
              The Referral Agreement has been sent to{' '}
              <strong>{currentUser.company.msaSignerEmail}</strong>. If needed you can re-assign the
              signature when you open the document.
            </Typography>
          ) : null}
        </Box>
      }
      cta={
        <ButtonWithLoading
          variant="contained"
          size="small"
          buttonSx={{ ml: 2 }}
          onClick={handleSendReminder}
          disabled={isButtonDisabled()}
          isLoading={isLoading}
          queryStatus={status}
          querySuccessText={
            <>
              <CheckIcon />
              Email Sent!
            </>
          }
          queryErrorText={"An error occurred: couldn't send to your email."}
        >
          Resend Agreement to your Email
        </ButtonWithLoading>
      }
      ctaTooltipText={
        isButtonDisabled()
          ? 'We only allow one resend per hour. Please check your email for the agreement.'
          : ''
      }
    />
  )
}
