import { ReactNode } from 'react'
import { Box, Button, ButtonProps, CircularProgress, SxProps } from '@mui/material'

type ButtonWithLoadingProps = {
  children: string
  isLoading: boolean
  disabled?: boolean
  fullWidth?: boolean
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
  size?: 'small' | 'medium' | 'large'
  testId?: string | null
  type?: 'button' | 'submit' | 'reset' | undefined
  variant?: 'text' | 'outlined' | 'contained'
  boxSx?: SxProps
  buttonSx?: SxProps
  queryStatus?: 'error' | 'idle' | 'loading' | 'success'
  querySuccessText?: string | ReactNode
  queryErrorText?: string
  color?: ButtonProps['color']
}
export function ButtonWithLoading(props: ButtonWithLoadingProps) {
  const {
    children,
    isLoading,
    disabled = false,
    fullWidth = false,
    onClick = () => {},
    size = 'medium',
    testId = null,
    type = 'button',
    variant = 'text',
    boxSx = {},
    buttonSx = {},
    queryStatus,
    querySuccessText,
    queryErrorText,
    color = 'primary',
    ...rest
  } = props

  function renderButtonState() {
    if (isLoading || queryStatus === 'loading') {
      return (
        <CircularProgress
          color="inherit"
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )
    }

    if (queryErrorText && queryStatus === 'error') {
      return queryErrorText
    }

    if (querySuccessText && queryStatus === 'success') {
      return querySuccessText
    }

    return children
  }

  return (
    <Box sx={{ position: 'relative', ...boxSx }} {...rest}>
      <Button
        disableRipple
        disabled={disabled || isLoading}
        fullWidth={fullWidth}
        onClick={onClick}
        size={size}
        data-testid={testId}
        type={type}
        variant={variant}
        sx={{ minHeight: 40, ...buttonSx }}
        color={color}
      >
        {renderButtonState()}
      </Button>
    </Box>
  )
}
