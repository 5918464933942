import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, Paper, TextField, Typography } from '@mui/material'

import { getReferenceData } from '@partnerslate/feature/central/api/parsers'
import { ReferenceData } from '@partnerslate/feature/central/domain/referenceData'
import {
  fieldOptionsFromActiveReferenceItems,
  fieldOptionsFromActiveReferenceItemsWithGroup,
} from '@partnerslate/segment/projects/domain'
import { ButtonWithLoading } from '@partnerslate/ui-components/lib/Button'

import { CompleteComanProfileForm } from '../domain/signup'
import * as instrumentation from '../instrumentation'
import { useFormContext } from '../SignupContext'
import { completeComanProfileResolver } from '../validations'
import { useRedirectToSignupFirstPage } from './useRedirect'
import { MultiSelect } from '@partnerslate/ui-components/lib/forms/MultiSelect'

export default function CompleteComanProfileScreen() {
  const navigate = useNavigate()
  const REFERENCE_DATA: ReferenceData = getReferenceData()
  const PRODUCT_CATEGORY_OPTIONS = fieldOptionsFromActiveReferenceItemsWithGroup(
    REFERENCE_DATA.productCategories,
  )
  const FACILITY_LOCATION_OPTIONS = fieldOptionsFromActiveReferenceItemsWithGroup(
    REFERENCE_DATA.facilityLocations,
  )
  const PACKAGING_FORMAT_OPTIONS = fieldOptionsFromActiveReferenceItemsWithGroup(
    REFERENCE_DATA.packagingFormats,
  )
  const PROCESSING_CAPABILITIY_OPTIONS = fieldOptionsFromActiveReferenceItems(
    REFERENCE_DATA.processingCapabilities,
  )
  const RUN_SIZE_OPTIONS = fieldOptionsFromActiveReferenceItems(REFERENCE_DATA.productionRunSizes)
  const COMAN_PREVIOUS_BRAND_SIZES = fieldOptionsFromActiveReferenceItems(
    REFERENCE_DATA.comanPreviousBrandSizes,
  )

  const { form, setCompleteComanProfileForm } = useFormContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
  } = useForm<CompleteComanProfileForm>({
    defaultValues: form.completeComanProfileForm,
    resolver: completeComanProfileResolver(),
  })

  const companyType = form.accountTypeForm.companyType
  useRedirectToSignupFirstPage(companyType)

  const onSubmit = (data: CompleteComanProfileForm) => {
    setIsLoading(true)
    instrumentation.fillInComanCompanyProfile()

    setCompleteComanProfileForm(data)

    navigate('/register/company-details')
  }

  return (
    <Container>
      <Typography variant="h2" mt={6} textAlign="center">
        Complete your profile
      </Typography>

      <Grid container my={5} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper>
            <Box onSubmit={handleSubmit(onSubmit)} component="form" px={{ xs: 2, md: 8 }} py={3}>
              <Typography variant="h5" textAlign="center" pt={1} pb={2}>
                Add your facility capabilities (select all that apply)
              </Typography>

              <Box pb={3}>
                <MultiSelect
                  control={control}
                  name="productCategories"
                  options={PRODUCT_CATEGORY_OPTIONS}
                  label="Product Categories *"
                  placeholder="Product Categories *"
                  error={errors.productCategories}
                />
              </Box>

              <Box pb={3}>
                <MultiSelect
                  control={control}
                  name="packagingFormats"
                  options={PACKAGING_FORMAT_OPTIONS}
                  label="Packaging Formats *"
                  placeholder="Packaging Formats *"
                  error={errors.packagingFormats}
                />
              </Box>

              <Box pb={3}>
                <MultiSelect
                  control={control}
                  name={'processingCapabilities'}
                  options={PROCESSING_CAPABILITIY_OPTIONS}
                  label="Processing Capabilities *"
                  placeholder="Processing Capabilities *"
                  error={errors.processingCapabilities}
                />
              </Box>

              <Box pb={3}>
                <MultiSelect
                  control={control}
                  name="productionRunSizes"
                  options={RUN_SIZE_OPTIONS}
                  label="Run Sizes"
                  placeholder="Run Sizes"
                  error={errors.productionRunSizes}
                />
              </Box>

              <>
                <Box pb={3}>
                  <MultiSelect
                    control={control}
                    name="facilityLocations"
                    options={FACILITY_LOCATION_OPTIONS}
                    label="Facility Locations *"
                    placeholder="Facility Locations *"
                    error={errors.facilityLocations}
                  />
                </Box>
                <Box pb={3}>
                  <TextField
                    {...register('numberOfFacilityLocations')}
                    name="numberOfFacilityLocations"
                    label="Number Facility Locations *"
                    placeholder="Number Of Facility Locations *"
                    error={!!errors.numberOfFacilityLocations}
                    fullWidth
                    type="number"
                    helperText={errors.numberOfFacilityLocations?.message ?? ''}
                  />
                </Box>
                <Box pb={1}>
                  <MultiSelect
                    control={control}
                    name="comanPreviousBrandSizes"
                    options={COMAN_PREVIOUS_BRAND_SIZES}
                    label="What Brand Sizes have you worked with in the past? *"
                    placeholder="What Brand Sizes have you worked with in the past? *"
                    error={errors.comanPreviousBrandSizes}
                  />
                </Box>
              </>

              <Box my={4}>
                <ButtonWithLoading
                  fullWidth
                  isLoading={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Continue
                </ButtonWithLoading>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
