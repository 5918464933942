import { useDebugValue, useEffect, useReducer } from 'react'
import { useClient } from '@splitsoftware/splitio-react'

const ANON_SPLIT_KEY = 'anon'

// You should not use this directly. Use the wrapper in feature/central/featureFlags
export const useFeatureFlagIfAvailable = (
  flagName: string,
  userId: string | null,
): boolean | null => {
  const splitKey = userId === null ? ANON_SPLIT_KEY : `user:${userId}`

  const client = useClient(splitKey)

  // forceUpdate's sole purpose is to request another pass through this hook method.
  // courtesy https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  // we have to do a bit of a dance here to make sure that if the Split client
  // isn't yet initialized then a re-render will be triggered as soon it IS ready.
  //
  // A not-ready client will just return a value of 'control', and the flag state
  // won't be re-evaluated until the next render pass. By listening for
  // an SDK_READY event and calling this custom `forceUpdate` helper we
  // trigger another pass through this hook and a re-evaluation of the flag state
  // once the client IS ready.
  useEffect(() => {
    if (client) {
      // trigger a re-evaluation of this hook once the SDK is ready (if it isn't already)
      client.on(client.Event.SDK_READY, forceUpdate)

      return () => {
        client.off(client.Event.SDK_READY, forceUpdate)
      }
    }
    return
  }, [client])

  const flagTreatment = client?.getTreatment(flagName)

  useDebugValue(`@${splitKey} ${flagName}=${flagTreatment}`)

  switch (flagTreatment) {
    // could not verify this ever hits undefined but the types suggest it can (maybe wrong types)
    case undefined:
      return null
    case 'control':
      return null
    case 'on':
      return true
    default:
      return false
  }
}

// You should not use this directly. Use the wrapper in feature/central/featureFlags
// a simpler version of `useFeatureFlagIfAvailable` for when you're fine with default to false while a
// flag's state is unknown (e.g. just after login when Split is still loading flag config for the current user)
export const useFeatureFlag = (flagName: string, userId: string | null): boolean => {
  const featureFlag = useFeatureFlagIfAvailable(flagName, userId)

  // by casting a null to false we have the effect of defaulting
  //  a flag to off if it's unknown (e.g. Split is currently booting)
  return !!featureFlag
}
