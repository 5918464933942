import { useContext } from 'react'
import { Box, Button, SxProps, Theme, Tooltip } from '@mui/material'

import { RouterContext } from '..'

type BaseBannerProps = {
  containerSx?: SxProps
  bodyWrapperSx?: SxProps
  bannerBody: React.ReactNode
  cta?: React.ReactNode
  ctaTooltipText?: string
}
export function BaseBanner({
  containerSx = {},
  bodyWrapperSx = {},
  cta = null,
  bannerBody,
  ctaTooltipText,
}: BaseBannerProps) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'center',
        alignItems: 'center',
        p: 1,
        px: 2,
        backgroundColor: (theme) => theme.palette.secondary.light,
        ...containerSx,
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...bodyWrapperSx }}
      >
        {bannerBody}
      </Box>
      {ctaTooltipText ? (
        // Tooltip usually doesn't show up if title is a Falsy value, but in the PS repo
        // it still shows up (maybe some bug in the MUI version used there) so we use this
        // workaround to conditionally show it.
        <Tooltip title={ctaTooltipText}>
          <span>{cta}</span>
        </Tooltip>
      ) : (
        <span>{cta}</span>
      )}
    </Box>
  )
}

export type RouterType = 'router' | 'regular'

type CtaLinkButtonProps = {
  text: string
  url: string
  routerType: RouterType
  onClick?: () => void | Promise<void>
  sx?: SxProps<Theme>
}

export function CtaLinkButton({
  text,
  url,
  routerType,
  onClick = () => {},
  sx,
}: CtaLinkButtonProps) {
  const router = useContext(RouterContext)

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault() // Prevent any default navigation
    await onClick()
    if (routerType === 'router') {
      router(url)
    } else {
      window.location.href = url
    }
  }

  if (routerType === 'router') {
    return (
      <Button size="small" variant="contained" sx={{ ml: 1, ...sx }} onClick={handleClick}>
        {text}
      </Button>
    )
  }

  if (routerType === 'regular') {
    return (
      <Button
        size="small"
        variant="contained"
        sx={{ ml: 1, ...sx }}
        href={url}
        onClick={handleClick}
      >
        {text}
      </Button>
    )
  }

  return null
}
