import { Controller, useForm } from 'react-hook-form'
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import {
  Box,
  Button,
  InputAdornment,
  Link as MuiLink,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'

import { useQueryAuthenticatedFullUser } from '@partnerslate/feature/central/IdentityProvider/index'
import { ButtonWithLoading } from '@partnerslate/ui-components'

import LoginMarketingHeader from '../../assets/images/coman-background-header.svg'
import LoginMarketingBackground from '../../assets/images/coman-background.svg'
import LoginPhoto from '../../assets/images/login-background.jpg'
import { Layout } from '../../components/Layout'
import { loginFormSchema, LoginFormType, useLogin } from './loginUser'
import { postLoginRoute } from './postLoginRoute'

export function LoginScreen() {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isSmallHeightScreen = useMediaQuery('(max-height:750px)')

  const { data: user, isLoading } = useQueryAuthenticatedFullUser()

  if (isLoading) {
    // TODO: main_repo was using spinner, we should perhaps
    // do something better than blocking the whole login
    return <></>
  }

  if (user) {
    // if main app has existing redirect (i.e. from email) continue to it and ignore projects/engagement count
    if (location.state) {
      return <Navigate to={{ pathname: location.state.toRoute, search: location.search }} replace />
    }

    // if a route has existing redirect continue to it aka a nextUrl
    if (location.search && location.search.includes('nextUrl')) {
      const nextURL = decodeURIComponent(location.search.replace('?nextUrl=', ''))
      // here we use window.location.replace because after login, we want to actually do a
      // browser location redirect, instead of an internal redirection via React Router's
      // <Redirect to=..> which will potentially land on a 404 (for the /leads route)Added
      safeLocationReplace(nextURL)
    } else {
      const nextRoute = postLoginRoute(user)

      if (nextRoute.useReactRouter) {
        navigate(nextRoute.nextUrl)
      } else {
        safeLocationReplace(nextRoute.nextUrl)
      }
    }
  }

  const isComanMarketing =
    searchParams.get('utm_source') === 'ps_app' &&
    searchParams.get('utm_medium') === 'mp_connect_me'

  return (
    <Layout
      left={
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <LoginForm>{isComanMarketing ? 'Welcome' : 'Welcome Back!'}</LoginForm>
          <MuiLink component={Link} to="/reset-your-password">
            <Typography variant="body2">Forgot Password?</Typography>
          </MuiLink>
          <Box display="flex" gap={1}>
            <Typography variant="body2">Don't Have An Account?</Typography>
            <MuiLink component={Link} to="/register">
              <Typography variant="body2">Sign Up</Typography>
            </MuiLink>
          </Box>
        </Box>
      }
      right={
        isComanMarketing ? (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              backgroundSize: '70%, cover',
              backgroundRepeat: 'no-repeat, no-repeat',
              backgroundPosition: 'top center',
              overflow: 'hidden',
              backgroundImage: `url(${LoginMarketingHeader}), url(${LoginMarketingBackground})`,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
              flexDirection="column"
            >
              {isSmallHeightScreen ? <Box height="149px" /> : null}
              <Box
                color="primary.contrastText"
                sx={{
                  px: 1,
                  maxWidth: '600px',
                  display: {
                    xs: 'none',
                    md: 'block',
                  },
                }}
              >
                <Typography
                  pt={2}
                  fontSize="36px"
                  color="primary.contrastText"
                  mb={3}
                  textAlign="center"
                >
                  Welcome to PartnerSlate!
                </Typography>
                <Typography
                  fontSize="24px"
                  lineHeight="28px"
                  color="primary.contrastText"
                  mb={3}
                  textAlign="center"
                >
                  Get started today for FREE and join our network of 6,000+
                  co-manufacturers&nbsp;to:
                </Typography>
                <Box mx={8}>
                  <Box display="flex" flexDirection="column" textAlign="initial" my={1}>
                    <Stack direction="row" alignItems="center" gap={1} py={0.25}>
                      <ArrowCircleRightIcon />
                      <Typography fontSize="18px" color="primary.contrastText">
                        Browse projects on the marketplace
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1} py={0.25}>
                      <ArrowCircleRightIcon />
                      <Typography fontSize="18px" color="primary.contrastText">
                        Get custom leads directly to your inbox
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1} py={0.25}>
                      <ArrowCircleRightIcon />
                      <Typography fontSize="18px" color="primary.contrastText">
                        Leverage PartnerSlate Matching Advisors
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1} py={0.25}>
                      <ArrowCircleRightIcon />
                      <Typography fontSize="18px" color="primary.contrastText">
                        Be found by Fortune 500 companies
                      </Typography>
                    </Stack>
                    <Button
                      component={Link}
                      to="/register"
                      variant="contained"
                      sx={{ mt: 5, mb: 2, py: 1 }}
                      style={{
                        borderRadius: 4,
                        backgroundColor: '#FF6E40',
                      }}
                    >
                      Start for free *
                    </Button>
                    <Typography variant="subtitle1" color="primary.contrastText" textAlign="center">
                      *You only pay a success fee of 3% when you reach production with a
                      PartnerSlate brand.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${LoginPhoto})`,
            }}
          />
        )
      }
    />
  )
}

type LoginFormProps = {
  children: React.ReactNode
}

function LoginForm(props: LoginFormProps) {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginFormType>({
    resolver: zodResolver(loginFormSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const login = useLogin(setError)

  function onSubmit(data: LoginFormType) {
    login.mutate(data)
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: (theme) => theme.spacing(35),
        width: '100%',
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography
        component="h1"
        variant="h2"
        fontWeight={(theme) => theme.typography.fontWeightBold}
      >
        {props.children}
      </Typography>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="email"
            fullWidth
            label="Email"
            variant="outlined"
            placeholder="Your email"
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-testid': 'Login/Username',
            }}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="password"
            fullWidth
            label="Password"
            variant="outlined"
            placeholder="Your password"
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-testid': 'Login/Password',
            }}
          />
        )}
      />

      {errors.root?.['serverError'].message && (
        <Typography variant="caption" color={(theme) => theme.palette.error.main}>
          {errors.root?.['serverError'].message}
        </Typography>
      )}

      <ButtonWithLoading
        isLoading={login.isLoading}
        type="submit"
        variant="contained"
        size="large"
        testId="Login/Submit"
        fullWidth
      >
        Log In
      </ButtonWithLoading>
    </Box>
  )
}

function safeLocationReplace(redirectRoute: string) {
  let safeInternalRedirect = '/'
  if (redirectRoute.charAt(0) === '/') {
    safeInternalRedirect = redirectRoute
  }
  window.location.replace(safeInternalRedirect)
}
