import { titleCase } from '@partnerslate/core/helpers/format'
import { ProjectDetail } from '@partnerslate/ui-components/lib/ProjectDetails/domain/project'

export type Project = BaseProject & ProjectDynamicAttrs

export const PROJECT_PUBLISH_STATUS_KEYS = [
  'PUBLISHED',
  'UNPUBLISHED',
  'SUBMITTED',
  'INCOMPLETE',
] as const

export type ProjectPublishStatuses = typeof PROJECT_PUBLISH_STATUS_KEYS[number]

export const getProjectPublishStatus = (statusKey: ProjectPublishStatuses): string => {
  const statuses: Record<ProjectPublishStatuses, string> = {
    PUBLISHED: 'Published',
    UNPUBLISHED: 'Unpublished',
    SUBMITTED: 'Submitted, Under review',
    INCOMPLETE: 'Incomplete',
  }
  return statuses[statusKey]
}

export type FreeProjectListing = {
  key: string
  requiresPayment: false
}

export type PaidProjectListing = {
  key: string
  requiresPayment: true
  displayPrice: string
  checkoutProductKey: string
}

// discriminated union which can we be discriminated by the `requiresPayment` field
export type ProjectListingPrice = FreeProjectListing | PaidProjectListing

export type BaseProject = {
  id: string
  createdAt: Date
  name: string
  listingPrice: ProjectListingPrice
  company: Company
  allergensPresent: ReferenceItem[]
  certificationRequirements: ReferenceItem[]
  specialEquipmentNeeded: string | null
  additionalNotes: string | null
  marketplacePaidAt: Date | null
  publishedToMarketplaceAt: Date | null
  unpublishedFromMarketplaceAt: Date | null
  publishStatus: ProjectPublishStatuses
  productCategory: ReferenceGroup | null
  summary: string | null
  description: string | null
  productStatus: ProductStatus | null
  rNdNeeded: RAndDNeeded | null
  packagingFormat: ReferenceGroup | null
  skus: number | null
  runSizeVolumePerSku: number | null
  annualVolumePerSku: number | null
  currentDoorCount: number | null
}

export type ProjectDynamicAttrs = {
  commercialFormula: CommercialFormula | null
  aboutUs: string | null
  processAuthorityLetter: ProcessAuthorityLetter | null
  commercializingSupport: CommercializingSupport | null
  formulationHelpLevel: FormulationHelpLevel | null
  formulationHelpType: FormulationHelpType[] | null
  formulationHelpDescription: string | null
  reasonToLookForComan: typeof REASON_TO_LOOK_FOR_COMAN[number][] | null
  processingSteps: string[] | null
  openToAlternateProcessing: OpenToAlternateProcessing | null
  postProductionStorage: PostProductionStorage | null
  openToEquipmentInvestment: OpenToEquipmentInvestment | null
  packagingRequired: PackagingRequired | null
  packagingRequiredReasons: PackagingRequiredReason[] | null
  msrp: number | null
  hideMsrpFromListing: boolean
  restrictedIngredients: RestrictedIngredients[] | null
  targetDateFirstRun: TargetDateFirstRun | null
  additionalVolumeComments: string | null
  needGFSIFacility: NeedGFSIFacility | null
  geoPreference: string | null
  geoPreferenceImportance: GeoPreferenceImportance | null
  serviceModel: ServiceModel | null
  needComanFullfillment: NeedComanFullfillment | null
  comansToAvoid: string | null
  distributedProduct: DistributedProduct[] | null
  currentDistributors: string | null
  currentNotableRetailers: string | null
  currentDoorCount: number | null
  futureNotableRetailers: string | null
  futureDistributors: string | null
  futureDoorCount: number | null
  typeOfPartnership: TypeOfPartnership[] | null
  needSpecialtyIngredientsSourcing: string | null
  kindsOfSpecialtyIngredients: KindOfSpecialtyIngredient[] | null
  otherKindOfSpecialtyIngredients: string | null
  anyIngredientsRequirement: string | null
  // ma refers to Matching Assistant
  maKeyComanQualities: string[] | null
  maPackaging: string | null
  maEquipment: string | null
  maUnitsPerYear: string | null
  maMarketStatus: string | null
  maFinalStorageTemperature: string | null
}

type Company = {
  id: string
  slug: string
  companyName: string
  companyPhone: string | null
  address: {
    address1: string
    address2: string | null
    city: string | null
    state: string | null
    postalCode: string | null
    countryCode: string | null
  } | null
  brandStage: {
    subText: string | null
    title: string | null
  }
  yearFounded: string
  primaryContact: {
    id: string
    email: string
    firstName: string
    lastName: string
  }
  website: string | null
  calendlyUrl: string | null
}

type ReferenceItem = {
  id: number
  active: boolean
  label: string
}

type ReferenceGroup = {
  id: number
  active: boolean
  label: string
  group: {
    id: number
    name: string
    order: number
  }
}

type AbridgedReferenceGroup = {
  label: string
  group: string
}

export const PRODUCT_STATUS_KEYS = ['market', 'retail', 'development'] as const
export type ProductStatus = typeof PRODUCT_STATUS_KEYS[number]
export const getProductStatus = (statusKey: ProductStatus): string => {
  const statuses: Record<ProductStatus, string> = {
    development: 'Currently in Development',
    retail: 'Retail Ready (i.e. nutrition facts, ingredient deck, barcodes, etc)',
    market: 'Currently in Market',
  }
  return statuses[statusKey]
}

export const R_AND_D_NEEDED_KEYS = ['yes', 'no', 'i_dont_know'] as const
export type RAndDNeeded = typeof R_AND_D_NEEDED_KEYS[number]
export const getRAndDNeeded = (statusKey: RAndDNeeded): string => {
  const statuses: Record<RAndDNeeded, string> = {
    yes: 'Yes',
    no: 'No',
    i_dont_know: 'Unknown',
  }
  return statuses[statusKey]
}

export const FORMULATION_HELP_TYPE_KEYS = [
  'FLAVORS_COLORS_AROMAS',
  'NUTRITIONAL_PROFILE',
  'FUNCTIONAL_INGREDIENTS',
  'TEXTURE_STABILIZATION',
  'MANUFACTURING_PROCESS',
] as const
export type FormulationHelpType = typeof FORMULATION_HELP_TYPE_KEYS[number]

export function getFormulationHelpType(formulationLevelOptionKey: FormulationHelpType): string {
  const formulationTypeOptions: Record<FormulationHelpType, string> = {
    FLAVORS_COLORS_AROMAS: 'Taste, Flavor & Color',
    NUTRITIONAL_PROFILE: 'Nutrition & Fortification',
    FUNCTIONAL_INGREDIENTS: 'Functional Additives',
    TEXTURE_STABILIZATION: 'Texture & Consistency',
    MANUFACTURING_PROCESS: 'Manufacturing process',
  }
  return formulationTypeOptions[formulationLevelOptionKey]
}

export const KINDS_OF_SPECIALTY_INGREDIENTS_KEYS = [
  'FLAVORING_COLORS_AROMAS',
  'SEASONING_SPICES_BOTANICALS',
  'FUNCTIONAL_INGREDIENTS',
] as const
export type KindOfSpecialtyIngredient = typeof KINDS_OF_SPECIALTY_INGREDIENTS_KEYS[number]

export function getKindOfSpecialtyIngredient(
  kindOfSpecialtyIngredientOptionKey: KindOfSpecialtyIngredient,
): string {
  const formulationTypeOptions: Record<KindOfSpecialtyIngredient, string> = {
    FLAVORING_COLORS_AROMAS: 'Liquid flavorings, color, and aromas',
    SEASONING_SPICES_BOTANICALS: 'Dry seasoning blends, spices, and botanicals',
    FUNCTIONAL_INGREDIENTS: 'Functional / Nutritional ingredients',
  }
  return formulationTypeOptions[kindOfSpecialtyIngredientOptionKey]
}

export const FORMULATION_HELP_LEVEL_KEYS = [
  'PRIVATE_LABEL',
  'FULL_CUSTOM',
  'PARTIAL',
  'SCALE_UP',
] as const
export type FormulationHelpLevel = typeof FORMULATION_HELP_LEVEL_KEYS[number]

export function getFormulationHelpLevel(formulationLevelOptionKey: FormulationHelpLevel): string {
  const formulationLevelOptions: Record<FormulationHelpLevel, string> = {
    PRIVATE_LABEL: 'Private Label Formulation',
    FULL_CUSTOM: 'Full / Custom Formulation',
    PARTIAL: 'Formulation Enhancement / Modification',
    SCALE_UP: 'Scale-up Formulation',
  }
  return formulationLevelOptions[formulationLevelOptionKey]
}

export const SERVICE_MODEL_KEYS = [
  'TURNKEY',
  'TOLLING',
  'OPEN_TO_EITHER',
  'HYBRID',
  'I_DONT_KNOW',
] as const
export type ServiceModel = typeof SERVICE_MODEL_KEYS[number]
export const getServiceModel = (key: ServiceModel | null): string => {
  if (key) {
    const models: Record<ServiceModel, string> = {
      TURNKEY: 'Turnkey',
      TOLLING: 'Tolling',
      OPEN_TO_EITHER: 'Open to either',
      HYBRID: 'Hybrid',
      I_DONT_KNOW: 'Not specified',
    }
    return models[key]
  }
  return ''
}

export const OPEN_TO_ALTERNATE_PROCESSING_KEYS = [
  'VERY_OPEN',
  'SOMEWHAT_OPEN',
  'ONLY_IF_NO_OTHER_OPTION',
  'NO',
] as const
export type OpenToAlternateProcessing = typeof OPEN_TO_ALTERNATE_PROCESSING_KEYS[number]
export const getOpenToAlternateProcessing = (statusKey: OpenToAlternateProcessing): string => {
  const statuses: Record<OpenToAlternateProcessing, string> = {
    VERY_OPEN: 'Very open',
    SOMEWHAT_OPEN: 'Somewhat open',
    ONLY_IF_NO_OTHER_OPTION: 'Only if no other option',
    NO: 'No',
  }
  return statuses[statusKey]
}

export const POST_PRODUCTION_STORAGE_KEYS = [
  'FROZEN',
  'REFRIGERATED',
  'TEMP_CONTROLLED',
  'AMBIENT',
] as const
export type PostProductionStorage = typeof POST_PRODUCTION_STORAGE_KEYS[number]
export const getPostProductionStorage = (statusKey: PostProductionStorage): string => {
  const statuses: Record<PostProductionStorage, string> = {
    FROZEN: 'Frozen: at or below 0°F (-18°C)',
    REFRIGERATED: 'Refrigerated: 32°F to 40°F (0°C to 5°C)',
    TEMP_CONTROLLED: 'Temperature Controlled: 56°F to 75°F (13°C to 24°C)',
    AMBIENT: 'Ambient: 59°F up to 86°F (15°C to 30°C) depending on climatic conditions',
  }
  return statuses[statusKey]
}

export const OPEN_TO_EQUIPMENT_INVESTMENT_KEYS = ['YES', 'MAYBE', 'NO'] as const
export type OpenToEquipmentInvestment = typeof OPEN_TO_EQUIPMENT_INVESTMENT_KEYS[number]
export const getOpenToEquipmentInvestment = (statusKey: OpenToEquipmentInvestment): string => {
  const statuses: Record<OpenToEquipmentInvestment, string> = {
    YES: 'Yes',
    MAYBE: 'Maybe',
    NO: 'No',
  }
  return statuses[statusKey]
}

export const PROCESSING_STEPS = [
  'Cooking - Baking',
  'Cooking - Frying',
  'Cooking - Kettle',
  'Cooking - Roasting / Grilling',
  'Cooking - Steaming',
  'Cooling - Blast Freezing',
  'Liquid - Brewing',
  'Liquid - Carbonation',
  'Liquid - Cold Fill',
  'Liquid - Homogenization',
  'Liquid - Hot Fill',
  'Liquid - In-line Filtration',
  'Liquid - In-tank Mixing',
  'Liquid - Nitrogenation / Nitro-Dosing',
  'Pasteurization - Aseptic Fill',
  'Pasteurization - ESL (Extended Shelf Life)',
  'Pasteurization - Flash',
  'Pasteurization - HPP (High Pressuring Processing)',
  'Pasteurization - HTST (High Temp Short Time)',
  'Pasteurization - Retort',
  'Pasteurization - Tunnel',
  'Process - Blending, Dry (e.g. Powders, Agglomeration)',
  'Process - Blending, Wet',
  'Process - Cold Form',
  'Process - Dehydrating',
  'Process - Drizzling',
  'Process - Enrobing',
  'Process - Extruding',
  'Process - Fermentation',
  'Process - Freeze Drying',
  'Process - Grinding',
  'Process - Juicing / Pulping',
  'Process - Pan Coating',
  'Process - Rotary Molding',
  'Process - Sheeting',
  'Process - Smoking',
  'Process - Spray Drying',
]

export const RESTRICTED_INGREDIENT_KEYS = ['ALCOHOL', 'CBD', 'CANNABIS'] as const
export type RestrictedIngredients = typeof RESTRICTED_INGREDIENT_KEYS[number]
export const getRestrictedIngredients = (statusKey: RestrictedIngredients): string => {
  const statuses: Record<RestrictedIngredients, string> = {
    ALCOHOL: 'Alcohol',
    CBD: 'CBD',
    CANNABIS: 'Cannabis',
  }
  return statuses[statusKey]
}

export const COMMERCIAL_FORMULA_KEYS = ['YES', 'NO', 'IN_PROCESS'] as const
export type CommercialFormula = typeof COMMERCIAL_FORMULA_KEYS[number]
export const getCommercialFormula = (statusKey: CommercialFormula): string => {
  const statuses: Record<CommercialFormula, string> = {
    YES: 'Yes',
    NO: 'No',
    IN_PROCESS: 'In-process',
  }
  return statuses[statusKey]
}

export const TYPE_OF_PARTNERSHIP_KEYS = ['MANUFACTURER', 'PACKAGER', 'PRIVATE_LABEL'] as const
export type TypeOfPartnership = typeof TYPE_OF_PARTNERSHIP_KEYS[number]
export const getTypeOfPartnership = (statusKey: TypeOfPartnership): string => {
  const statuses: Record<TypeOfPartnership, string> = {
    MANUFACTURER: 'Contract Manufacturer',
    PACKAGER: 'Contract Packer',
    PRIVATE_LABEL: 'Private Label Manufacturer',
  }
  return statuses[statusKey]
}

export const PACKAGING_REQUIRED_KEYS = ['YES', 'NO'] as const
export type PackagingRequired = typeof PACKAGING_REQUIRED_KEYS[number]
export const getPackagingRequired = (statusKey: PackagingRequired): string => {
  const statuses: Record<PackagingRequired, string> = {
    YES: 'Yes',
    NO: 'No',
  }
  return statuses[statusKey]
}

export const PACKAGING_REQUIRED_REASON_KEYS = [
  'VOLUME_INCREASE',
  'LOWER_MOQS',
  'FASTER_DELIVERY',
  'CUSTOMER_REQUIREMENT',
  'HIGHER_QUALITY',
] as const
export type PackagingRequiredReason = typeof PACKAGING_REQUIRED_REASON_KEYS[number]
export const getPackagingRequiredReason = (statusKey: PackagingRequiredReason): string => {
  const statuses: Record<PackagingRequiredReason, string> = {
    VOLUME_INCREASE:
      'My volumes have increased substantially. I need a larger supplier with better unit costs.',
    LOWER_MOQS: 'My current MOQs are too large. I need a supplier that can handle smaller orders.',
    FASTER_DELIVERY: 'My current lead times are too long. I need faster turnaround on delivery.',
    CUSTOMER_REQUIREMENT:
      'I need new packaging to meet retailer or customer requirements (e.g. recyclable, resealable, etc.)',
    HIGHER_QUALITY: 'I need higher quality packaging or have a change in material technical specs.',
  }
  return statuses[statusKey]
}

export const PROCESS_AUTHORITY_LETTER_KEYS = [
  'YES',
  'IN_PROCESS',
  'NO_NEED_TO_ACQUIRE',
  'NO_DO_NOT_REQUIRE',
  'UNSURE_IF_REQUIRED',
] as const
export type ProcessAuthorityLetter = typeof PROCESS_AUTHORITY_LETTER_KEYS[number]
export const getProcessAuthorityLetter = (statusKey: ProcessAuthorityLetter): string => {
  const statuses: Record<ProcessAuthorityLetter, string> = {
    YES: 'Yes',
    IN_PROCESS: 'In-process',
    NO_NEED_TO_ACQUIRE: 'No: need to acquire',
    NO_DO_NOT_REQUIRE: 'No: do not require',
    UNSURE_IF_REQUIRED: 'Unsure if required',
  }
  return statuses[statusKey]
}

export const COMMERCIALIZING_SUPPORT_KEYS = ['YES', 'NO', 'NOT_SURE'] as const
export type CommercializingSupport = typeof COMMERCIALIZING_SUPPORT_KEYS[number]
export const getCommercializingSupport = (statusKey: CommercializingSupport): string => {
  const statuses: Record<CommercializingSupport, string> = {
    YES: 'Yes',
    NO: 'No',
    NOT_SURE: 'Not sure',
  }
  return statuses[statusKey]
}

export const REASON_TO_LOOK_FOR_COMAN = [
  'Looking for my first co-man',
  'Expand capacity',
  'Additional capabilities needed',
  'Better pricing',
  'Mis-alignment with current co-man',
]

export const TARGET_DATE_FIRST_RUN_KEYS = [
  '2_TO_3_MONTHS',
  '4_TO_6_MONTHS',
  '6_TO_12_MONTHS',
  '12_PLUS_MONTHS',
] as const
export type TargetDateFirstRun = typeof TARGET_DATE_FIRST_RUN_KEYS[number]
export const getTargetDateFirstRun = (statusKey: TargetDateFirstRun): string => {
  const statuses: Record<TargetDateFirstRun, string> = {
    '2_TO_3_MONTHS': '2-3 months',
    '4_TO_6_MONTHS': '4-6 months',
    '6_TO_12_MONTHS': '6-12 months',
    '12_PLUS_MONTHS': '12+ months',
  }
  return statuses[statusKey]
}

export const NEED_GFSI_FACILITY_KEYS = ['YES', 'NO', 'NOT_SURE'] as const
export type NeedGFSIFacility = typeof NEED_GFSI_FACILITY_KEYS[number]
export const getNeedGFSIFacility = (statusKey: NeedGFSIFacility): string => {
  const statuses: Record<NeedGFSIFacility, string> = {
    YES: 'Yes',
    NO: 'No',
    NOT_SURE: 'Not sure',
  }
  return statuses[statusKey]
}

export const GEOGRAPHIC_PREFERENCE_IMPORTANCE_KEYS = [
  'MANDATORY',
  'VERY',
  'SOMEWHAT',
  'SLIGHTLY',
  'NOT_AT_ALL',
] as const
export type GeoPreferenceImportance = typeof GEOGRAPHIC_PREFERENCE_IMPORTANCE_KEYS[number]
export const getGeoPreferenceImportance = (statusKey: GeoPreferenceImportance): string => {
  const statuses: Record<GeoPreferenceImportance, string> = {
    MANDATORY: 'Mandatory',
    VERY: 'Very',
    SOMEWHAT: 'Somewhat',
    SLIGHTLY: 'Slightly',
    NOT_AT_ALL: 'Not at all',
  }
  return statuses[statusKey]
}

export const NEED_COMAN_FULLFILLMENT_KEYS = ['YES', 'NO', 'NICE_TO_HAVE'] as const
export type NeedComanFullfillment = typeof NEED_COMAN_FULLFILLMENT_KEYS[number]
export const getNeedComanFullfillment = (statusKey: NeedComanFullfillment): string => {
  const statuses: Record<NeedComanFullfillment, string> = {
    YES: 'Yes',
    NO: 'No',
    NICE_TO_HAVE: 'Nice to have',
  }
  return statuses[statusKey]
}

export const DISTRIBUTED_PRODUCT_KEYS = [
  'NOT_IN_MARKET',
  'DIRECT_TO_CONSUMER',
  'LOCAL',
  'FOOD_SERVICE',
  'REGIONAL',
  'NATIONAL',
] as const
export type DistributedProduct = typeof DISTRIBUTED_PRODUCT_KEYS[number]
export const getDistributedProduct = (statusKey: DistributedProduct): string => {
  const statuses: Record<DistributedProduct, string> = {
    NOT_IN_MARKET: 'Not yet in Market',
    DIRECT_TO_CONSUMER: 'Direct to Consumer',
    LOCAL: 'Local / Self Distributed',
    FOOD_SERVICE: 'Food Service',
    REGIONAL: 'Regional',
    NATIONAL: 'National',
  }
  return statuses[statusKey]
}

export const formatProjectToProjectDetailContent = (project: Project): ProjectDetail => {
  const { address, brandStage, yearFounded } = project.company

  const newProject = {
    ...project,
    description: mustExist('description', project.description),
    skus: project.skus,
    annualVolumePerSku: project.annualVolumePerSku,
    runSizeVolumePerSku: project.runSizeVolumePerSku,
    state: address?.state ?? null,
    brandStage: brandStage.subText,
    yearFounded,
    publishStatusLabel: getProjectPublishStatus(project.publishStatus),
    formattedProductCategory: formatReferenceGroupToLabel(project.productCategory),
    formattedPackagingFormat: formatReferenceGroupToLabel(project.packagingFormat),
    formattedCertificationRequirements: project.certificationRequirements.map((c) => c.label),
    formattedAllergensPresent: project.allergensPresent.map((a) => a.label),
    formattedProductStatus: project.productStatus ? getProductStatus(project.productStatus) : null,
    formattedrNdNeeded: project.rNdNeeded ? getRAndDNeeded(project.rNdNeeded) : null,
    formattedServiceModel: project.serviceModel ? getServiceModel(project.serviceModel) : null,
    formattedOpenToAlternateProcessing: project.openToAlternateProcessing
      ? getOpenToAlternateProcessing(project.openToAlternateProcessing)
      : null,
    formattedPostProductionStorage: project.postProductionStorage
      ? getPostProductionStorage(project.postProductionStorage)
      : null,
    formattedOpenToEquipmentInvestment: project.openToEquipmentInvestment
      ? getOpenToEquipmentInvestment(project.openToEquipmentInvestment)
      : null,
    formattedRestrictedIngredients: project.restrictedIngredients
      ? project.restrictedIngredients.map((i) => getRestrictedIngredients(i))
      : null,
    formattedCommercialFormula: project.commercialFormula
      ? getCommercialFormula(project.commercialFormula)
      : null,
    formattedPackagingRequired: project.packagingRequired
      ? getPackagingRequired(project.packagingRequired)
      : null,
    formattedPackagingRequiredReasons: project.packagingRequiredReasons
      ? project.packagingRequiredReasons.map((i) => getPackagingRequiredReason(i))
      : null,
    formattedProcessAuthorityLetter: project.processAuthorityLetter
      ? getProcessAuthorityLetter(project.processAuthorityLetter)
      : null,
    formattedCommercializingSupport: project.commercializingSupport
      ? getCommercializingSupport(project.commercializingSupport)
      : null,
    formattedTargetDateFirstRun: project.targetDateFirstRun
      ? getTargetDateFirstRun(project.targetDateFirstRun)
      : null,
    formattedNeedGFSIFacility: project.needGFSIFacility
      ? getNeedGFSIFacility(project.needGFSIFacility)
      : null,
    formattedGeoPreferenceImportance: project.geoPreferenceImportance
      ? getGeoPreferenceImportance(project.geoPreferenceImportance)
      : null,
    formattedNeedComanFullfillment: project.needComanFullfillment
      ? getNeedComanFullfillment(project.needComanFullfillment)
      : null,
    formattedDistributedProduct: project.distributedProduct
      ? project.distributedProduct.map((p) => getDistributedProduct(p))
      : null,
    formattedFormulationHelpLevel: project.formulationHelpLevel
      ? getFormulationHelpLevel(project.formulationHelpLevel)
      : null,
    formattedFormulationHelpType: project.formulationHelpType
      ? project.formulationHelpType.map((i) => getFormulationHelpType(i))
      : null,
    formattedFormulationHelpDescription: project.formulationHelpDescription,
    typeOfPartnership: project.typeOfPartnership
      ? project.typeOfPartnership.map((i) => getTypeOfPartnership(i))
      : [],
    kindsOfSpecialtyIngredients: project.kindsOfSpecialtyIngredients
      ? project.kindsOfSpecialtyIngredients.map((i) => getKindOfSpecialtyIngredient(i))
      : [],
    otherKindOfSpecialtyIngredients: project.otherKindOfSpecialtyIngredients
      ? project.otherKindOfSpecialtyIngredients
      : null,
    anyIngredientsRequirement: project.anyIngredientsRequirement
      ? project.anyIngredientsRequirement
      : null,
  }

  return newProject
}

export function formatReferenceGroupToLabel(referenceGroup: ReferenceGroup | null) {
  let label = ''
  if (referenceGroup) {
    if (referenceGroup.group) {
      label = `${titleCase(referenceGroup.group.name)} - `
    }
    label += referenceGroup.label
  }
  return label
}

export function formatAbridgedReferenceGroupToLabel(referenceGroup: AbridgedReferenceGroup) {
  return `${titleCase(referenceGroup.group)} - ${referenceGroup.label}`
}

function mustExist<T>(field: string, value: T | null): T {
  if (value === null) {
    throw new Error(
      `The field ${field} can NOT be null in order to render the project detail content`,
    )
  }
  return value
}
