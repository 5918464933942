import { z } from 'zod'

import { getOrThrow, postOrThrow, updateStoredAuthToken } from '@partnerslate/core'
import { castAxiosError } from '@partnerslate/core/djangoValidation'
import { unearthAffiliateMetadata } from '@partnerslate/feature/central/api/backendGateway'
import { parseAccount, parseFullAccount } from '@partnerslate/feature/central/api/parsers'
import {
  AccountWrapperSchema,
  FullAccountWrapperSchema,
} from '@partnerslate/feature/central/api/schemas'
import { UserAccount } from '@partnerslate/feature/central/domain/userAccount'

import { SignupForm } from '../domain/signup'
import { createAccountRegistrationPayload, parseInviteInfo } from './parsers'
import {
  ExistsSchema,
  InviteInfoPayloadSchema,
  InviteInformation,
  SignUpFormValues,
} from './schemas'

const REGISTER_URL = '/api/v1/accounts/signup/'
const CHECK_EXISTING_EMAIL_URL = (email: string) =>
  `/api/v1/accounts/check-email/?email=${encodeURIComponent(email)}`
const CHECK_EXISTING_COMPANY_URL = (companyName: string) =>
  `/api/v1/companies/check-company/?name=${encodeURIComponent(companyName)}`
const INVITE_INFO_URL = (token: string) => `/api/v1/invites/${token}/`
const ACCEPT_INVITE_URL = (token: string) => `/api/v1/invites/${token}/accept/`
const LOGIN_URL = '/api/v1/accounts/login/'

export const register = async (signupFormData: SignupForm): Promise<UserAccount> => {
  // grab any affiliate metadata that's been stored in the browser as a cookie and
  // include it in our registration payload. It doesn't feel GREAT to be doing this here,
  // but I couldn't think of a nicer solution.
  const affiliateMetadata = unearthAffiliateMetadata()

  const data = createAccountRegistrationPayload(signupFormData, affiliateMetadata)
  let response
  try {
    response = await postOrThrow(REGISTER_URL, AccountWrapperSchema, data)
  } catch (e) {
    throw castAxiosError(e)
  }

  return parseAccount(response.data)
}

export const checkExistingEmail = async (email: string): Promise<boolean> => {
  const response = await getOrThrow(CHECK_EXISTING_EMAIL_URL(email), ExistsSchema)
  return response.exists
}

export const checkExistingCompany = async (companyName: string): Promise<boolean> => {
  const response = await getOrThrow(CHECK_EXISTING_COMPANY_URL(companyName), ExistsSchema)
  return response.exists
}

export async function getInviteInformation(token: string): Promise<InviteInformation> {
  const response = await getOrThrow(INVITE_INFO_URL(token), InviteInfoPayloadSchema)
  return parseInviteInfo(response)
}

export async function acceptTeamInvite(values: SignUpFormValues, token: string) {
  const payload = {
    email: values.email,
    password: values.password,
    first_name: values.firstName,
    last_name: values.lastName,
    job_title: values.jobTitle || '',
  }

  return await postOrThrow(ACCEPT_INVITE_URL(token), z.unknown(), payload)
}

export async function loginAfterAccept(email: string, password: string) {
  const payload = {
    username: email,
    password: password,
  }

  const response = await postOrThrow(LOGIN_URL, FullAccountWrapperSchema, payload)
  updateStoredAuthToken(response.data.token)
  return parseFullAccount(response.data)
}
