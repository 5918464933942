import { Route } from 'react-router-dom'

import ErrorPageWithImage from '@partnerslate/ui-components/lib/ErrorPageWithImage'

import { LoginScreen } from './screens/Login'
import { ResetPasswordScreen } from './screens/ResetPassword'
import { UpdatePasswordScreen } from './screens/UpdatePassword'

export function loginRouteSet() {
  return [
    <Route key="home" path="" element={<LoginScreen />} />,
    <Route key="login" path="login" element={<LoginScreen />} />,
    <Route
      key="reset-your-password"
      path="reset-your-password"
      element={<ResetPasswordScreen />}
    />,
    <Route
      key="reset-password-change"
      path="reset-password-change"
      element={<UpdatePasswordScreen />}
    />,
    <Route key="*" path="*" element={<ErrorPageWithImage />} />,
  ]
}
