import { z } from 'zod'

import { postOrThrow } from '@partnerslate/core'

const SEND_MSA_REMINDER_URL = (companySlug: string) =>
  `/api/v1/companies/${companySlug}/send-msa-reminder/`

export const sendMSAReminder = async (companySlug: string) => {
  const response = await postOrThrow(SEND_MSA_REMINDER_URL(companySlug), z.unknown())
  return response
}
