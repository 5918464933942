import { UseFormSetError } from 'react-hook-form'
import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query'

import { DjangoApiError } from '@partnerslate/core/djangoValidation'
import { UserAccount } from '@partnerslate/feature/central/domain/userAccount'
import { FULL_ACCOUNT_QUERY_KEY } from '@partnerslate/feature/central/IdentityProvider'

import { SignupForm } from '../domain/signup'
import {
  acceptTeamInvite,
  getInviteInformation,
  loginAfterAccept,
  register,
} from './backendGateway'
import { SignUpFormValues } from './schemas'

export function useRegister(): UseMutationResult<UserAccount, DjangoApiError, SignupForm, unknown> {
  return useMutation(register)
}

export function useInviteInformation(token: string | undefined) {
  return useQuery(['inviteInfo', token], () => getInviteInformation(token!), {
    enabled: !!token,
  })
}

export function useAcceptTeamInvite(setError: UseFormSetError<SignUpFormValues>) {
  return useMutation(
    ({ values, token }: { values: SignUpFormValues; token: string }) =>
      acceptTeamInvite(values, token),
    {
      onError: (error: Error) => {
        setError('root.serverError', {
          message: error.message,
        })
      },
    },
  )
}

export function useLoginAfterAccept() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ email, password }: { email: string; password: string }) => loginAfterAccept(email, password),
    {
      onSuccess: (data) => {
        queryClient.setQueryData([FULL_ACCOUNT_QUERY_KEY], data)
      },
    },
  )
}
