import * as Sentry from '@sentry/react'
import SplitIO from '@splitsoftware/splitio-react/types/splitio/splitio'

import { AuthToken } from '@partnerslate/core'

import { ReferenceDataPayload } from './domain/referenceData'

export type BrowserPartnerSlate = {
  appEnv: Environments
  referenceData: ReferenceDataPayload
  hijack?: { token: AuthToken }
}

declare global {
  interface Window {
    gtag: any
    Intercom: any
    cometToken?: () => Promise<string>
    cometFingerprint?: () => Promise<string>
    partnerSlate: BrowserPartnerSlate
  }
}

function configFromHost(): BrowserPartnerSlate | undefined {
  if (typeof window === 'undefined') {
    return undefined
  }
  return window.partnerSlate
}

export function retrieveHijackAuthToken(): AuthToken | null {
  return configFromHost()?.hijack?.token || null
}

interface ConfigType {
  mixpanelToken: string | null
  customerIoWriteKey: string | null
  sentryOptions: ({ dsn, envPrefix }: { dsn: string; envPrefix: string }) => Sentry.BrowserOptions
  splitConfig: SplitIO.IBrowserSettings
  buildstamp: string
  appEnv: Environments
}

const appEnv = configFromHost()?.appEnv
const buildstamp = process.env['NX_PUBLIC_MONOREPO_VERSION'] || 'localdev'

// Sentry SDK requires this to be a globalton, so we can't create
// a new one inside `sentryOptionsForEnv(...)`
const sentryReplayGlobalton = new Sentry.Replay({
  maskAllText: false,
})

export const sentryOptionsForEnv = (
  env: Environments,
  dsn: string,
  envPrefix: string,
): Sentry.BrowserOptions => ({
  dsn,
  environment: `${envPrefix}-${env}`,
  release: buildstamp,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [sentryReplayGlobalton],
  ignoreErrors: [
    /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/,
    "Cannot read properties of undefined (reading 'domInteractive')",
    'Request failed with status code 404',
  ], // c.f. https://github.com/getsentry/sentry-javascript/issues/3440 https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062 https://github.com/cefsharp/CefSharp/blob/ed1763ee9510dca9e54ab766ea7cf305f71d2aa2/CefSharp/Internals/JavascriptObjectRepository.cs#L298
})

const SPLIT_CONFIG_NON_PROD: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: 'ik6tug5uuqj5jig7btga8j6m6i65sfod0ofq',
    key: 'anon',
  },
}

const SPLIT_CONFIG_PROD: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: 'qrdqo3bcvhdnu6qi0mdrcafrnnecvj30uud6',
    key: 'anon',
  },
}

const MIXPANEL_TOKEN_NON_PROD = 'c671de97a0425dea4b7872bf8b22feea'
const MIXPANEL_TOKEN_PROD = 'e211a2deaa8f603456a93ca2cedab90a'
const CUSTOMER_IO_WRITE_KEY_NON_PROD = 'a414f5b92f0641f0c575'
const CUSTOMER_IO_WRITE_KEY_PROD = 'bf627880481afaaf1468'

const DISABLED_SENTRY = ({ dsn, envPrefix }: { dsn: string; envPrefix: string }) => ({})

const localdev: ConfigType = {
  sentryOptions: DISABLED_SENTRY,
  mixpanelToken: MIXPANEL_TOKEN_NON_PROD,
  customerIoWriteKey: CUSTOMER_IO_WRITE_KEY_NON_PROD,
  splitConfig: SPLIT_CONFIG_NON_PROD,
  buildstamp: buildstamp,
  appEnv: 'localdev',
}
const stg: ConfigType = {
  sentryOptions: ({ dsn, envPrefix }) => sentryOptionsForEnv('stg', dsn, envPrefix),
  mixpanelToken: MIXPANEL_TOKEN_NON_PROD,
  customerIoWriteKey: CUSTOMER_IO_WRITE_KEY_NON_PROD,
  splitConfig: SPLIT_CONFIG_NON_PROD,
  buildstamp: buildstamp,
  appEnv: 'stg',
}
const prd: ConfigType = {
  sentryOptions: ({ dsn, envPrefix }) => sentryOptionsForEnv('prd', dsn, envPrefix),
  mixpanelToken: MIXPANEL_TOKEN_PROD,
  customerIoWriteKey: CUSTOMER_IO_WRITE_KEY_PROD,
  splitConfig: SPLIT_CONFIG_PROD,
  buildstamp: buildstamp,
  appEnv: 'prd',
}

const configs = {
  localdev,
  stg,
  prd,
}

export type Environments = keyof typeof configs
const environment: Environments = appEnv || 'localdev'
const config: ConfigType = configs[environment]

export default config
