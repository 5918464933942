import React, { useContext } from 'react'
import { Box, Link, SxProps, Theme } from '@mui/material'

import { RouterContext } from '../'

export type NavbarLinkType = 'router' | 'regular'
type NavbarLinkProps = {
  external?: boolean
  text: string
  icon?: React.ReactNode
  url: string
  linkType?: NavbarLinkType
  onClose?: () => void
  isUppercase?: boolean
  sx?: SxProps<Theme>
}
export function NavbarLink({
  external = false,
  text,
  icon,
  url,
  linkType = 'regular',
  onClose = () => {},
  isUppercase = true,
  sx = {},
}: NavbarLinkProps) {
  const router = useContext(RouterContext)

  function BaseLink(props: any) {
    return (
      <Link
        {...props}
        target={external ? '_blank' : '_self'}
        color="primary.contrast"
        fontFamily="fontFamily"
        fontSize={16}
        fontWeight={600}
        letterSpacing={1.85}
        marginX={2.5}
        marginY={1.5}
        padding={0}
        textTransform={isUppercase ? 'uppercase' : ''}
        sx={{ textDecoration: 'none', cursor: 'pointer', ...sx }}
      >
        {icon ? (
          <Box display="flex" gap={1}>
            {icon} {props.children}
          </Box>
        ) : (
          props.children
        )}
      </Link>
    )
  }

  if (linkType === 'router') {
    return (
      <BaseLink
        onClick={() => {
          onClose()
          router(url)
        }}
      >
        {text}
      </BaseLink>
    )
  }

  if (linkType === 'regular') {
    return <BaseLink href={url}>{text}</BaseLink>
  }

  return null
}

type NavbarMobileLinkProps = {
  external?: boolean
  text: string
  url: string
  linkType?: NavbarLinkType
  onClick?: () => void
  sx?: SxProps<Theme>
}
export function NavbarMobileLink({
  external = false,
  text,
  url,
  linkType = 'regular',
  onClick = () => {},
  sx = {},
}: NavbarMobileLinkProps) {
  const router = useContext(RouterContext)

  function BaseLink(props: any) {
    return (
      <Link
        {...props}
        target={external ? '_blank' : '_self'}
        sx={{ textDecoration: 'none', cursor: 'pointer', ...sx }}
        color="primary.contrast"
        fontFamily="fontFamily"
        fontSize={18}
        fontWeight={600}
        letterSpacing={1.85}
        margin={0}
        paddingY={2.25}
        paddingLeft={4}
        textTransform="uppercase"
      >
        {props.children}
      </Link>
    )
  }

  if (linkType === 'router') {
    return (
      <BaseLink
        onClick={() => {
          onClick()
          router(url)
        }}
      >
        {text}
      </BaseLink>
    )
  }

  if (linkType === 'regular') {
    return (
      <BaseLink onClick={onClick} href={url}>
        {text}
      </BaseLink>
    )
  }

  return null
}

type NavbarMobileMainLinkProps = {
  text: string
  url: string
  linkType?: NavbarLinkType
  onClick?: () => void
  sx?: SxProps<Theme>
}
export function NavbarMobileMainLink({
  text,
  url,
  linkType = 'regular',
  onClick = () => {},
  sx = {},
}: NavbarMobileMainLinkProps) {
  const router = useContext(RouterContext)

  function BaseLink(props: any) {
    return (
      <Link
        {...props}
        sx={{ textDecoration: 'none', cursor: 'pointer', ...sx }}
        color="primary.contrast"
        fontFamily="fontFamily"
        fontSize={18}
        fontWeight={600}
        letterSpacing={1.85}
        margin={0}
        paddingBottom={2}
        textTransform="uppercase"
        textAlign="center"
      >
        {props.children}
      </Link>
    )
  }

  if (linkType === 'router') {
    return (
      <BaseLink
        onClick={() => {
          onClick()
          router(url)
        }}
      >
        {text}
      </BaseLink>
    )
  }

  if (linkType === 'regular') {
    return (
      <BaseLink onClick={onClick} href={url}>
        {text}
      </BaseLink>
    )
  }

  return null
}
