import { UseFormSetError } from 'react-hook-form'
import { useMutation } from 'react-query'
import { z } from 'zod'

export type ResetPassword = z.infer<typeof resetPasswordSchema>
export const resetPasswordSchema = z.object({
  email: z.string().min(1, {
    message: 'Email is required',
  }),
})

export async function resetPassword(payload: ResetPassword) {
  const url =
    '/api/v1/accounts/reset-password/?' +
    new URLSearchParams({
      username: payload.email,
    })
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (!response.ok) {
    throw new Error('An error occurred')
  }
}

export function useResetPassword(setError: UseFormSetError<ResetPassword>) {
  return useMutation(resetPassword, {
    onError: (error) => {
      if (error instanceof Error) {
        setError('root.serverError', {
          message: error.message,
        })
      }
    },
  })
}
