import assert from 'assert'
import { z } from 'zod'

import {
  ProductStatus,
  Project,
  ProjectDynamicAttrs,
  ProjectListingPrice,
  RAndDNeeded,
} from '../domain/project'
import { ReferenceData } from '../domain/referenceData'
import { UserAccount } from '../domain/userAccount'
import { AccountSchema, FullAccountWrapperSchema, ProjectSchema } from './schemas'

export type AccountPayload = z.infer<typeof AccountSchema>
export const parseAccount = (account: AccountPayload): UserAccount => {
  const [company] = account.companies

  return {
    id: account.id,
    emailAddress: account.email,
    firstName: account.first_name,
    lastName: account.last_name,
    jobTitle: account.job_title ?? null,
    hasLeadManager: account.has_lead_manager,
    dateJoined: account.date_joined,
    company: {
      id: company.id,
      marketplaceMSASignatureStatus: company.marketplace_msa_signature_status,
      companyType: company.company_type,
      companyName: company.company_name,
      companyPhone: company.company_phone,
      brandStage: company.brand_stage?.label,
      msaReminderSentAt: company.msa_signature_reminder_sent_at,
      msaSignerEmail: company.msa_signer_email,
      slug: company.slug,
      penalty: company.penalty,
      needsToUpdateAllEngagements: company.needs_to_update_all_engagements,
    },
    projects: parseAbridgedProjects(account.projects),
    entitlements: {
      connectionsRemaining: account.entitlements.connections_remaining ?? Infinity,
      canSendConnections: account.entitlements.can_send_connections,
      shouldSeeTrialBanner: account.entitlements.should_see_trial_banner,
      trialExpiresAt: account.entitlements.trial_expires_at,
    },
    plan: {
      id: account.plan.id,
      isActivePaidSubscriber: account.plan.is_active_paid_subscriber,
      name: account.plan.name,
      planRenewsAt: account.plan.plan_renews_at,
    },
  }
}

type FullAccountPayload = z.infer<typeof FullAccountWrapperSchema>['data']
export type FullAccount = ReturnType<typeof parseFullAccount>
export function parseFullAccount(fullAccount: FullAccountPayload) {
  const parsedAbridgedAccount = parseAccount(fullAccount)
  return {
    ...parsedAbridgedAccount,
    projects: fullAccount.projects.map((project) => {
      return {
        id: project.id,
        name: project.name,
        isPaid: project.is_paid,
        createdAt: project.created_at,
        isMatched: project.is_matched,
      }
    }),
    engagementInfo: {
      engagementsCount: fullAccount.engagement_info.engagements_count,
      firstEngagementId: fullAccount.engagement_info.first_engagement_id,
    },
    projectInfo: {
      projectsCount: fullAccount.project_info.projects_count,
      firstProjectId: fullAccount.project_info.first_project_id,
    },

    token: {
      access: fullAccount.token.access,
      refresh: fullAccount.token.refresh,
    },
  }
}

function parseAbridgedProjects(projects: AccountPayload['projects']) {
  return projects.map((project) => ({
    id: project.id,
    name: project.name,
    isPaid: project.is_paid,
  }))
}

function parseReferenceData(): ReferenceData {
  const { referenceData } = window.partnerSlate

  return {
    additionalServices: referenceData.additional_services,
    allergens: referenceData.allergens,
    brandStages: referenceData.brand_stages.map(
      ({ sub_text, is_too_early_stage, ...otherProps }) => {
        const brandStage: { sub_text: string; is_too_early_stage: boolean } = {
          sub_text,
          is_too_early_stage,
        }
        return {
          ...otherProps,
          subText: brandStage.sub_text,
          isTooEarlyStage: brandStage.is_too_early_stage,
        }
      },
    ),
    companyRevenueRanges: referenceData.company_revenue_ranges,
    businessTypes: referenceData.business_types,
    certifications: referenceData.certifications,
    distributionTypes: referenceData.distribution_types,
    facilityLocations: referenceData.facility_locations,
    manufacturerTypes: referenceData.manufacturer_types,
    packagingFormats: referenceData.packaging_formats,
    processingCapabilities: referenceData.processing_capabilities,
    productCategories: referenceData.product_categories,
    productionRunSizes: referenceData.production_run_sizes,
    servicesOffered: referenceData.services_offered,
    serviceModels: referenceData.service_models,
    diverseBackgrounds: referenceData.diverse_backgrounds,
    diverseSocialImpacts: referenceData.diverse_social_impacts,
    comanPreviousBrandSizes: referenceData.coman_previous_brand_sizes || [],
    formulationHelpLevelOptions: referenceData.formulation_help_level_options,
  }
}

export function getReferenceData(): ReferenceData {
  return parseReferenceData()
}

export type ProjectPayload = z.infer<typeof ProjectSchema>

export const parseProject = (project: ProjectPayload): Project => {
  return {
    id: project.id,
    createdAt: new Date(project.created_at),
    marketplacePaidAt: project.marketplace_paid_at,
    publishedToMarketplaceAt: project.published_to_marketplace_at,
    unpublishedFromMarketplaceAt: project.unpublished_from_marketplace_at,
    publishStatus: project.publish_status,
    productCategory: project.product_category,
    name: project.name,
    skus: project.skus,
    runSizeVolumePerSku: project.run_size_volume_per_sku,
    annualVolumePerSku: project.annual_volume_per_sku,
    listingPrice: parseProjectListingPrice(project.listing_price),
    description: project.description ?? null,
    summary: project.summary,
    productStatus: project.product_status !== '' ? (project.product_status as ProductStatus) : null,
    additionalNotes: project.additional_notes,
    specialEquipmentNeeded: project.special_equipment_needed,
    rNdNeeded: project.r_and_d_needed !== '' ? (project.r_and_d_needed as RAndDNeeded) : null,

    company: {
      id: project.company.id,
      slug: project.company.slug,
      companyName: project.company.company_name,
      companyPhone: project.company.company_phone,
      address: project.company.address
        ? {
            address1: project.company.address.address1,
            address2: project.company.address.address2,
            city: project.company.address.city,
            state: project.company.address.state,
            postalCode: project.company.address.postal_code,
            countryCode: project.company.address.country_code,
          }
        : null,
      brandStage: {
        subText: project.company.brand_stage?.sub_text ?? null,
        title: project.company.brand_stage?.title ?? null,
      },
      yearFounded: project.company.year_founded,
      primaryContact: {
        id: project.company.primary_contact.id,
        email: project.company.primary_contact.email,
        firstName: project.company.primary_contact.first_name,
        lastName: project.company.primary_contact.last_name,
      },
      website: project.company.website,
      calendlyUrl: project.company.calendly_url,
    },
    certificationRequirements: project.certification_requirements,
    allergensPresent: project.allergens_present,
    packagingFormat: project.packaging_format,
    ...parseProjectDynamicAttrs(project.dynamic_attrs),
  }
}

function parseProjectListingPrice(
  listingPricePayload: ProjectPayload['listing_price'],
): ProjectListingPrice {
  if (listingPricePayload.checkout_product_key === null) {
    assert(listingPricePayload.display_price === null) // schema sanity check
    // FreeProjectListing
    return {
      requiresPayment: false,
      key: listingPricePayload.key,
    }
  } else {
    assert(listingPricePayload.display_price !== null) // schema sanity check (and type narrowing)
    // PaidProjectListing
    return {
      requiresPayment: true,
      key: listingPricePayload.key,
      displayPrice: listingPricePayload.display_price,
      checkoutProductKey: listingPricePayload.checkout_product_key,
    }
  }
}

export function parseProjectDynamicAttrs(
  dynamicAttrs: ProjectPayload['dynamic_attrs'],
): ProjectDynamicAttrs {
  return {
    commercialFormula: dynamicAttrs.commercial_formula ?? null,
    aboutUs: dynamicAttrs.about_us ?? null,
    processAuthorityLetter: dynamicAttrs.process_authority_letter ?? null,
    commercializingSupport: dynamicAttrs.commercializing_support ?? null,
    reasonToLookForComan: dynamicAttrs.reason_to_look_for_coman ?? null,
    formulationHelpLevel: dynamicAttrs.formulation_help_level ?? null,
    formulationHelpType: dynamicAttrs.formulation_help_type ?? null,
    formulationHelpDescription: dynamicAttrs.formulation_help_description ?? null,
    processingSteps: dynamicAttrs.processing_steps ?? null,
    openToAlternateProcessing: dynamicAttrs.open_to_alternate_processing ?? null,
    postProductionStorage: dynamicAttrs.post_production_storage ?? null,
    openToEquipmentInvestment: dynamicAttrs.open_to_equipment_investment ?? null,
    msrp: dynamicAttrs.msrp ?? null,
    packagingRequired: dynamicAttrs.packaging_required ?? null,
    packagingRequiredReasons: dynamicAttrs.packaging_required_reasons ?? null,
    hideMsrpFromListing: dynamicAttrs.hide_msrp_from_listing ?? true,
    restrictedIngredients: dynamicAttrs.restricted_ingredients ?? null,
    targetDateFirstRun: dynamicAttrs.target_date_first_run ?? null,
    additionalVolumeComments: dynamicAttrs.additional_volume_comments ?? null,
    needGFSIFacility: dynamicAttrs.need_gfsi_facility ?? null,
    geoPreference: dynamicAttrs.geo_preference ?? null,
    geoPreferenceImportance: dynamicAttrs.geo_preference_importance ?? null,
    serviceModel: dynamicAttrs.service_model ?? null,
    needComanFullfillment: dynamicAttrs.need_coman_fullfillment ?? null,
    comansToAvoid: dynamicAttrs.comans_to_avoid ?? null,
    distributedProduct: dynamicAttrs.distributed_product ?? null,
    currentDistributors: dynamicAttrs.current_distributors ?? null,
    currentNotableRetailers: dynamicAttrs.current_notable_retailers ?? null,
    currentDoorCount: dynamicAttrs.current_door_count ?? null,
    futureNotableRetailers: dynamicAttrs.future_notable_retailers ?? null,
    futureDistributors: dynamicAttrs.future_distributors ?? null,
    futureDoorCount: dynamicAttrs.future_door_count ?? null,
    typeOfPartnership: dynamicAttrs.type_of_partnership ?? [],
    needSpecialtyIngredientsSourcing: dynamicAttrs.need_specialty_ingredients_sourcing ?? null,
    kindsOfSpecialtyIngredients: dynamicAttrs.kinds_of_specialty_ingredients ?? [],
    otherKindOfSpecialtyIngredients: dynamicAttrs.other_kind_of_specialty_ingredients ?? null,
    anyIngredientsRequirement: dynamicAttrs.any_ingredients_requirement ?? null,
    maFinalStorageTemperature: dynamicAttrs.ma_final_storage_temperature ?? null,
    maKeyComanQualities: dynamicAttrs.ma_key_coman_qualities ?? [],
    maMarketStatus: dynamicAttrs.ma_market_status ?? null,
    maPackaging: dynamicAttrs.ma_packaging ?? null,
    maEquipment: dynamicAttrs.ma_equipment ?? null,
    maUnitsPerYear: dynamicAttrs.ma_units_per_year ?? null,
  }
}
