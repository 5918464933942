/* eslint-disable camelcase */
import { z } from 'zod'

import { zod } from '@partnerslate/core'

import {
  COMMERCIAL_FORMULA_KEYS,
  COMMERCIALIZING_SUPPORT_KEYS,
  DISTRIBUTED_PRODUCT_KEYS,
  FORMULATION_HELP_LEVEL_KEYS,
  FORMULATION_HELP_TYPE_KEYS,
  GEOGRAPHIC_PREFERENCE_IMPORTANCE_KEYS,
  KINDS_OF_SPECIALTY_INGREDIENTS_KEYS,
  NEED_COMAN_FULLFILLMENT_KEYS,
  NEED_GFSI_FACILITY_KEYS,
  OPEN_TO_ALTERNATE_PROCESSING_KEYS,
  OPEN_TO_EQUIPMENT_INVESTMENT_KEYS,
  PACKAGING_REQUIRED_KEYS,
  PACKAGING_REQUIRED_REASON_KEYS,
  POST_PRODUCTION_STORAGE_KEYS,
  PROCESS_AUTHORITY_LETTER_KEYS,
  PRODUCT_STATUS_KEYS,
  PROJECT_PUBLISH_STATUS_KEYS,
  R_AND_D_NEEDED_KEYS,
  RESTRICTED_INGREDIENT_KEYS,
  SERVICE_MODEL_KEYS,
  TARGET_DATE_FIRST_RUN_KEYS,
  TYPE_OF_PARTNERSHIP_KEYS,
} from '../domain/project'
import { COMPANY_TYPES, MSA_SIGNATURE_STATUSES } from '../domain/userAccount'

export const AccountSchema = z.object({
  id: z.string(),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  job_title: z.string().nullish(),
  has_lead_manager: z.boolean(),
  date_joined: zod.iso8601(),
  companies: z.array(
    z.object({
      id: z.string(),
      marketplace_msa_signature_status: z.enum(MSA_SIGNATURE_STATUSES),
      msa_signature_reminder_sent_at: z.nullable(zod.iso8601()),
      msa_signer_email: z.string().nullable(),
      company_type: z.enum(COMPANY_TYPES),
      company_name: z.string(),
      company_phone: z.string().nullish(),
      slug: z.string(),
      brand_stage: z.nullable(
        z.object({
          label: z.string(),
        }),
      ),
      penalty: z.nullable(
        z.object({
          title: z.nullable(z.string()),
          body: z.nullable(z.string()),
        }),
      ),
      needs_to_update_all_engagements: z.boolean(),
    }),
  ),
  projects: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      is_paid: z.boolean(),
    }),
  ),
  entitlements: z.object({
    connections_remaining: z.number().nullable(),
    can_send_connections: z.boolean(),
    should_see_trial_banner: z.boolean(),
    trial_expires_at: z.nullable(zod.iso8601()),
  }),
  plan: z.object({
    id: z.string(),
    is_active_paid_subscriber: z.boolean(),
    name: z.string(),
    plan_renews_at: z.nullable(zod.iso8601()),
  }),
})
export const AccountWrapperSchema = z.object({
  data: AccountSchema,
})

export const FullAccountWrapperSchema = z.object({
  data: AccountSchema.merge(
    z.object({
      projects: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          is_paid: z.boolean(),
          created_at: zod.iso8601(),
          is_matched: z.boolean(),
        }),
      ),
      engagement_info: z.object({
        engagements_count: z.number(),
        first_engagement_id: z.string().nullable(),
      }),
      project_info: z.object({
        projects_count: z.number(),
        first_project_id: z.string().nullable(),
      }),
      token: z.object({
        access: z.string(),
        refresh: z.string(),
      }),
    }),
  ),
})

export const CompanySchema = z.object({
  id: z.string(),
  slug: z.string(),
  company_name: z.string(),
  company_phone: z.nullable(z.string()),
  address: z.optional(
    z.nullable(
      z.object({
        address1: z.string(),
        address2: z.nullable(z.string()),
        city: z.nullable(z.string()),
        state: z.nullable(z.string()),
        postal_code: z.nullable(z.string()),
        country_code: z.nullable(z.string()),
      }),
    ),
  ),
  brand_stage: z.nullable(
    z.object({
      sub_text: z.string(),
      title: z.string(),
    }),
  ),
  year_founded: z.string(),
  primary_contact: z.object({
    id: z.string(),
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
  }),
  website: z.nullable(z.string()),
  calendly_url: z.nullable(z.string()),
})

const ReferenceItemSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  label: z.string(),
})

const ReferenceGroupSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  label: z.string(),
  group: z.object({
    id: z.number(),
    name: z.string(),
    order: z.number(),
  }),
})

export const DynamicAttrsSchema = z.object({
  summary: z.optional(z.nullable(z.string())),
  about_us: z.optional(z.nullable(z.string())),
  commercial_formula: z.optional(z.enum(COMMERCIAL_FORMULA_KEYS).nullable()),
  process_authority_letter: z.optional(z.enum(PROCESS_AUTHORITY_LETTER_KEYS).nullable()),
  commercializing_support: z.optional(z.enum(COMMERCIALIZING_SUPPORT_KEYS).nullable()),
  formulation_help_level: z.optional(z.enum(FORMULATION_HELP_LEVEL_KEYS).nullable()),
  formulation_help_type: z.optional(z.array(z.enum(FORMULATION_HELP_TYPE_KEYS))).nullable(),
  formulation_help_description: z.optional(z.nullable(z.string())),
  reason_to_look_for_coman: z.optional(z.array(z.string())),
  processing_steps: z.optional(z.array(z.string())),
  open_to_alternate_processing: z.optional(z.enum(OPEN_TO_ALTERNATE_PROCESSING_KEYS).nullable()),
  post_production_storage: z.optional(z.enum(POST_PRODUCTION_STORAGE_KEYS).nullable()),
  open_to_equipment_investment: z.optional(z.enum(OPEN_TO_EQUIPMENT_INVESTMENT_KEYS).nullable()),
  restricted_ingredients: z.optional(z.array(z.enum(RESTRICTED_INGREDIENT_KEYS))),
  target_date_first_run: z.optional(z.enum(TARGET_DATE_FIRST_RUN_KEYS).nullable()),
  additional_volume_comments: z.optional(z.nullable(z.string())),
  need_gfsi_facility: z.optional(z.enum(NEED_GFSI_FACILITY_KEYS).nullable()),
  geo_preference: z.optional(z.nullable(z.string())),
  geo_preference_importance: z.optional(z.enum(GEOGRAPHIC_PREFERENCE_IMPORTANCE_KEYS).nullable()),
  service_model: z.optional(z.nullable(z.enum(SERVICE_MODEL_KEYS))),
  need_coman_fullfillment: z.optional(z.enum(NEED_COMAN_FULLFILLMENT_KEYS).nullable()),
  comans_to_avoid: z.optional(z.nullable(z.string())),
  distributed_product: z.optional(z.array(z.enum(DISTRIBUTED_PRODUCT_KEYS))),
  current_distributors: z.optional(z.nullable(z.string())),
  current_notable_retailers: z.optional(z.nullable(z.string())),
  current_door_count: z.optional(z.nullable(z.number())),
  future_notable_retailers: z.optional(z.nullable(z.string())),
  future_distributors: z.optional(z.nullable(z.string())),
  future_door_count: z.optional(z.nullable(z.number())),
  packaging_required: z.optional(z.enum(PACKAGING_REQUIRED_KEYS)),
  packaging_required_reasons: z.optional(z.array(z.enum(PACKAGING_REQUIRED_REASON_KEYS))),
  msrp: z.optional(z.nullable(z.number())),
  hide_msrp_from_listing: z.optional(z.boolean()),
  type_of_partnership: z.optional(z.array(z.enum(TYPE_OF_PARTNERSHIP_KEYS))),
  need_specialty_ingredients_sourcing: z.optional(z.nullable(z.string())),
  kinds_of_specialty_ingredients: z.optional(z.array(z.enum(KINDS_OF_SPECIALTY_INGREDIENTS_KEYS))),
  other_kind_of_specialty_ingredients: z.optional(z.nullable(z.string())),
  any_ingredients_requirement: z.optional(z.nullable(z.string())),
  ma_key_coman_qualities: z.optional(z.nullable(z.array(z.string()))),
  ma_packaging: z.optional(z.nullable(z.string())),
  ma_equipment: z.optional(z.nullable(z.string())),
  ma_units_per_year: z.optional(z.nullable(z.string())),
  ma_market_status: z.optional(z.nullable(z.string())),
  ma_final_storage_temperature: z.optional(z.nullable(z.string())),
})

const ProjectListingPriceSchema = z.object({
  key: z.string(),
  // both of these would be null for a free listing, neither would be null for a paid listing
  display_price: z.nullable(z.string()),
  checkout_product_key: z.nullable(z.string()),
})

export const ProjectSchema = z.object({
  id: z.string(),
  name: z.string(),
  created_at: zod.iso8601(),
  marketplace_paid_at: z.nullable(zod.iso8601()),
  published_to_marketplace_at: z.nullable(zod.iso8601()),
  unpublished_from_marketplace_at: z.nullable(zod.iso8601()),
  publish_status: z.enum(PROJECT_PUBLISH_STATUS_KEYS),
  skus: z.nullable(z.number()),
  run_size_volume_per_sku: z.nullable(z.number()),
  product_status: z.nullable(z.string(z.enum(PRODUCT_STATUS_KEYS))),
  product_category: z.nullable(ReferenceGroupSchema),
  description: z.optional(z.nullable(z.string())),
  summary: z.nullable(z.string()),
  is_matched: z.optional(z.boolean()),
  is_paid: z.boolean(),
  packaging_format: z.nullable(ReferenceGroupSchema),
  annual_volume_per_sku: z.nullable(z.number()),
  allergens_present: z.array(ReferenceItemSchema),
  listing_price: ProjectListingPriceSchema,
  company: CompanySchema,
  certification_requirements: z.array(ReferenceItemSchema),
  r_and_d_needed: z.nullable(z.string(z.enum(R_AND_D_NEEDED_KEYS))),

  special_equipment_needed: z.nullable(z.string()),
  additional_notes: z.nullable(z.string()),
  dynamic_attrs: DynamicAttrsSchema,
})
