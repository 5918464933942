import React, { MouseEvent, useState } from 'react'
import { Link, Menu } from '@mui/material'

export function NavbarDropdown({ label, children }: { label: string, children: React.FC<{ onClose: () => void }> }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const isOpen = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Link
        id="basic-Link"
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        color="primary.contrast"
        fontFamily="fontFamily"
        fontSize={16}
        fontWeight={600}
        letterSpacing={1.85}
        marginX={2}
        marginY={1.5}
        padding={0}
        textTransform="uppercase"
        sx={{ textDecoration: 'none', '&:hover': { cursor: 'pointer' } }}
      >
        {label}
      </Link>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-Link' }}
        sx={{
          marginTop: 2.5,
          '& .MuiMenu-list': {
            backgroundColor: 'primary.dark',
            display: 'flex',
            paddingY: 3,
            flexDirection: 'column',
            width: 255,
          },
        }}
      >
        {children({ onClose: handleClose })}
      </Menu>
    </>
  )
}
