import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { UserAccount } from '..'
import { BasicPlanBanner } from './BasicPlanBanner'
import { FreeBanner } from './FreeBanner'
import { MSABanner } from './MSABanner'
import { TrialBanner } from './TrialBanner'

dayjs.extend(utc)

type BannerManagerProps = {
  currentUser: UserAccount
  origin: 'monorepo' | 'main_repo'
}

function formatInUTC(date: Date | null) {
  if (!date) return null

  return dayjs(date).utc().format('MM/DD/YYYY')
}
/**
 * This component will return the appropriate banner based on the current user's status.
 * It MUST be used within a <Navbar /> component so that it can access the Navbar's router function stored in it's provider.
 */
export function BannerManager({ currentUser, origin }: BannerManagerProps) {
  // lite plan needs to stay above isActivePaidSubscriber because we treat
  // all paid plans the same
  if (currentUser.plan.id === 'LITE') {
    return (
      <BasicPlanBanner
        connectionsLeft={currentUser.entitlements.connectionsRemaining}
        repoClickOrigin={origin}
        renewalDate={formatInUTC(currentUser.plan.planRenewsAt)}
      />
    )
  }

  if (currentUser.plan.isActivePaidSubscriber) {
    return null
  }

  if (currentUser.company.marketplaceMSASignatureStatus === 'pending') {
    return <MSABanner currentUser={currentUser} />
  } else if (currentUser.entitlements.shouldSeeTrialBanner) {
    return <TrialBanner currentUser={currentUser} repoClickOrigin={origin} />
  } else if (currentUser.plan.id === 'FREE') {
    return (
      <FreeBanner
        connectionsLeft={currentUser.entitlements.connectionsRemaining}
        repoClickOrigin={origin}
        renewalDate={formatInUTC(currentUser.plan.planRenewsAt)}
      />
    )
  }

  return null
}
