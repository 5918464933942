import * as Sentry from '@sentry/browser'
import Cookies from 'js-cookie'

import { AuthFailure, getOrThrow } from '@partnerslate/core'

import { Project } from '../domain/project'
import { UserAccount } from '../domain/userAccount'
import { parseAccount, parseFullAccount, parseProject } from './parsers'
import { AccountWrapperSchema, FullAccountWrapperSchema, ProjectSchema } from './schemas'

const ACCOUNT_URL = '/api/v1/accounts/current/'
const FULL_ACCOUNT_URL = '/api/v1/accounts/me/'
const PROJECT_URL = (projectId: string) => `/api/v1/projects/${projectId}/`

export const getAccount = async (): Promise<UserAccount | null> => {
  try {
    const response = await getOrThrow(ACCOUNT_URL, AccountWrapperSchema, false)
    return parseAccount(response.data)
  } catch (e) {
    if (e instanceof AuthFailure) {
      // we don't want to always treat not being logged in as an error. If the user is not
      // logged in then we return null, and let the caller decide whether that's a failure or not
      return null
    } else {
      throw e
    }
  }
}

export const getProject = async (projectId: string): Promise<Project> => {
  const data = await getOrThrow(PROJECT_URL(projectId), ProjectSchema)
  return parseProject(data)
}

export async function getFullAccount() {
  try {
    const response = await getOrThrow(FULL_ACCOUNT_URL, FullAccountWrapperSchema, false)
    return parseFullAccount(response.data)
  } catch (e) {
    if (e instanceof AuthFailure) {
      // we don't want to always treat not being logged in as an error. If the user is not
      // logged in then we return null, and let the caller decide whether that's a failure or not
      return null
    } else {
      throw e
    }
  }
}

export function unearthAffiliateMetadata(): Record<string, unknown> | null {
  // this cookie is set by the /lp/rangeme landing page in Django (range_me_landing_page in misc/viewsets.py)
  const affiliateMetadata = Cookies.get('ps_affiliate_metadata')
  if (!affiliateMetadata) {
    return null
  }

  try {
    return JSON.parse(decodeURIComponent(affiliateMetadata))
  } catch (err) {
    Sentry.captureException(err)
    return null
  }
}
