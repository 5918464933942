import { CamelCasedPropertiesDeep } from '@partnerslate/core/helpers/domain_types'
import { getReferenceData } from '@partnerslate/feature/central/api/parsers'
import {
  CommercialFormula,
  CommercializingSupport,
  DistributedProduct,
  FormulationHelpLevel,
  FormulationHelpType,
  GeoPreferenceImportance,
  KindOfSpecialtyIngredient,
  NeedComanFullfillment,
  NeedGFSIFacility,
  OpenToAlternateProcessing,
  OpenToEquipmentInvestment,
  PostProductionStorage,
  ProcessAuthorityLetter,
  ProductStatus,
  Project,
  RAndDNeeded,
  REASON_TO_LOOK_FOR_COMAN,
  RestrictedIngredients,
  ServiceModel,
  TargetDateFirstRun,
  TypeOfPartnership,
} from '@partnerslate/feature/central/domain/project'
import {
  BrandStage,
  ReferenceData,
  ReferenceGroup,
  ReferenceItem,
} from '@partnerslate/feature/central/domain/referenceData'

export type FieldOption<VType extends string = string> = {
  value: VType
  label: string
}

export type FieldFromGroupOption<VType extends string = string> = {
  value: VType
  label: string
  groupId: string
}

export function fieldOptionsFromActiveReferenceItems(
  referenceItems: ReferenceItem[],
): FieldOption[] {
  return referenceItems
    .filter((r: ReferenceItem) => r.active)
    .map((r: ReferenceItem) => {
      return {
        value: r.id.toString(),
        label: r.label,
      }
    })
}

type CamelCaseBrandStage = CamelCasedPropertiesDeep<BrandStage>
export function fieldOptionsFromActiveBrandStage(
  brandStages: CamelCaseBrandStage[],
): FieldOption[] {
  return brandStages
    .filter((r: CamelCaseBrandStage) => r.active)
    .map((r: CamelCaseBrandStage) => {
      return {
        value: r.id.toString(),
        label: r.subText,
      }
    })
}

export function fieldOptionsFromActiveReferenceItemsWithGroup(
  referenceItems: ReferenceGroup[],
): FieldOption[] {
  return referenceItems
    .filter((r) => r.active)
    .map((r) => {
      const groupName = r.group?.name
      return {
        value: r.id.toString(),
        label: `${groupName ? `${groupName}  - ` : ''}${r.label}`,
      }
    })
}

export function fieldOptionsFromActiveReferenceGroups(
  referenceGroups: ReferenceGroup[],
): [FieldOption[], FieldFromGroupOption[]] {
  const uniqueGroups: string[] = []
  const groups: FieldOption[] = []
  const items: FieldFromGroupOption[] = []

  referenceGroups
    .filter((r: ReferenceGroup) => r.active)
    .forEach((r: ReferenceGroup) => {
      const group = r.group
      if (!group) return

      const groupId = group.id.toString()

      items.push({
        value: r.id.toString(),
        label: r.label,
        groupId,
      })

      if (!uniqueGroups.includes(groupId)) {
        uniqueGroups.push(groupId)
        groups.push({
          value: groupId,
          label: group.name,
        })
      }
    })

  return [groups, items]
}

export function fieldOptionsFromPlainStrings(strings: string[]) {
  return strings.map((s: string) => {
    return { value: s, label: s }
  })
}

export type ProjectOverviewValues = {
  name: string
  productCategoryGroup: string | null
  productCategory: string | null
  summary: string | null
  aboutUs: string | null
  description: string | null
  commercialFormula: CommercialFormula | null
  productStatus: ProductStatus | null
  processAuthorityLetter: ProcessAuthorityLetter | null
  rNdNeeded: RAndDNeeded | null
  formulationHelpLevel: FormulationHelpLevel | null
  formulationHelpType: FormulationHelpType[] | null
  formulationHelpDescription: string | null
  commercializingSupport: CommercializingSupport | null
  reasonToLookForComan: typeof REASON_TO_LOOK_FOR_COMAN[number][] | null
  companyId: string
  typeOfPartnership: TypeOfPartnership[]
  needSpecialtyIngredientsSourcing: string | null
  kindsOfSpecialtyIngredients: KindOfSpecialtyIngredient[]
  otherKindOfSpecialtyIngredients: string | null
  anyIngredientsRequirement: string | null
}
export type ProjectOverviewFieldNames = keyof ProjectOverviewValues

export function projectOverviewValuesFromProject(project: Project): ProjectOverviewValues {
  const REFERENCE_DATA: ReferenceData = getReferenceData()
  const productCategory = REFERENCE_DATA.productCategories.filter(
    (category) => category.id.toString() === project.productCategory?.id.toString(),
  )[0]

  return {
    name: project.name,
    productCategoryGroup: productCategory?.group ? productCategory.group.id.toString() : '',
    productCategory: project.productCategory?.id.toString() ?? null,
    summary: project.summary,
    aboutUs: project.aboutUs,
    description: project.description,
    commercialFormula: project.commercialFormula,
    productStatus: project.productStatus,
    processAuthorityLetter: project.processAuthorityLetter,
    rNdNeeded: project.rNdNeeded,
    formulationHelpLevel: project.formulationHelpLevel,
    formulationHelpType: project.formulationHelpType ?? [],
    formulationHelpDescription: project.formulationHelpDescription,
    commercializingSupport: project.commercializingSupport,
    companyId: project.company.id,
    reasonToLookForComan: project.reasonToLookForComan,
    typeOfPartnership: project.typeOfPartnership ?? [],
    needSpecialtyIngredientsSourcing: project.needSpecialtyIngredientsSourcing,
    kindsOfSpecialtyIngredients: project.kindsOfSpecialtyIngredients ?? [],
    otherKindOfSpecialtyIngredients: project.otherKindOfSpecialtyIngredients,
    anyIngredientsRequirement: project.anyIngredientsRequirement,
  }
}

export type ProcessingAndProductionValues = {
  specialEquipmentNeeded: string | null
  processingSteps: string[] | null
  openToAlternateProcessing: OpenToAlternateProcessing | null
  postProductionStorage: PostProductionStorage | null
  openToEquipmentInvestment: OpenToEquipmentInvestment | null
  certificationRequirements: string[]
  allergensPresent: string[]
  restrictedIngredients: RestrictedIngredients[] | null
}
export type ProcessingAndProductionFieldNames = keyof ProcessingAndProductionValues

export function processingAndProductionValuesFromProject(
  project: Project,
): ProcessingAndProductionValues {
  return {
    specialEquipmentNeeded: project.specialEquipmentNeeded ?? null,
    processingSteps: project.processingSteps,
    openToAlternateProcessing: project.openToAlternateProcessing ?? null,
    postProductionStorage: project.postProductionStorage ?? null,
    openToEquipmentInvestment: project.openToEquipmentInvestment ?? null,
    certificationRequirements: project.certificationRequirements.map((c) => c.id.toString()),
    allergensPresent: project.allergensPresent.map((a) => a.id.toString()),
    restrictedIngredients: project.restrictedIngredients,
  }
}

export type VolumeAndTimelineValues = {
  runSizeVolumePerSku: number | null
  annualVolumePerSku: number | null
  targetDateFirstRun: TargetDateFirstRun | null
  additionalVolumeComments: string
}
export type VolumeAndTimelineFieldNames = keyof VolumeAndTimelineValues

export function volumeAndTimelineValuesFromProject(project: Project): VolumeAndTimelineValues {
  return {
    runSizeVolumePerSku: project.runSizeVolumePerSku ?? null,
    annualVolumePerSku: project.annualVolumePerSku ?? null,
    targetDateFirstRun: project.targetDateFirstRun ?? null,
    additionalVolumeComments: project.additionalVolumeComments ?? '',
  }
}

export type FacilityComanValues = {
  needGFSIFacility: NeedGFSIFacility | null
  geoPreference: string | null
  geoPreferenceImportance: GeoPreferenceImportance | null
  serviceModel: ServiceModel | null
  needComanFullfillment: NeedComanFullfillment | null
  comansToAvoid: string | null
}
export type FacilityComanFieldNames = keyof FacilityComanValues

export function facilityComanValuesFromProject(project: Project): FacilityComanValues {
  return {
    needGFSIFacility: project.needGFSIFacility ?? null,
    geoPreference: project.geoPreference ?? null,
    geoPreferenceImportance: project.geoPreferenceImportance ?? null,
    serviceModel: project.serviceModel ?? null,
    needComanFullfillment: project.needComanFullfillment ?? null,
    comansToAvoid: project.comansToAvoid ?? null,
  }
}

export type SalesDistributionValues = {
  distributedProduct: DistributedProduct[] | null
  currentDistributors: string | null
  currentNotableRetailers: string | null
  currentDoorCount: number | null
  futureNotableRetailers: string | null
  futureDistributors: string | null
  futureDoorCount: number | null
}
export type SalesDistributionFieldNames = keyof SalesDistributionValues

export function salesDistributionValuesFromProject(project: Project): SalesDistributionValues {
  return {
    distributedProduct: project.distributedProduct,
    currentDistributors: project.currentDistributors ?? null,
    currentNotableRetailers: project.currentNotableRetailers ?? null,
    currentDoorCount: project.currentDoorCount ?? null,
    futureNotableRetailers: project.futureNotableRetailers ?? null,
    futureDistributors: project.futureDistributors ?? null,
    futureDoorCount: project.futureDoorCount ?? null,
  }
}

export type PackagingValues = {
  packagingFormat: string | null
  packagingRequired: string | null
  packagingRequiredReasons: string[] | null
  skus: number | null
  msrp: number | null
  hideMsrpFromListing: boolean
}
export type PackagingFieldNames = keyof PackagingValues

export function packagingValuesFromProject(project: Project): PackagingValues {
  return {
    packagingFormat: project.packagingFormat?.id.toString() ?? null,
    packagingRequired: project.packagingRequired,
    packagingRequiredReasons: project.packagingRequiredReasons,
    skus: project.skus,
    msrp: project.msrp ?? null,
    hideMsrpFromListing: project.hideMsrpFromListing,
  }
}
