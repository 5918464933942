import { Link } from '@mui/material'

export function PrivacyPolicyLink() {
  return (
    <Link
      href="https://s3-us-west-1.amazonaws.com/pslate.stage/docs/privacy-policy-partnerslate.pdf"
      target="_blank"
      underline="none"
    >
      Privacy Policy
    </Link>
  )
}
