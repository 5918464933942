import isISODate from 'is-iso-date'
import { z } from 'zod'

export const iso8601 = () => {
  return z.string().transform<Date>((value, ctx) => {
    if (!isISODate(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Not a valid ISO-8601 date string',
      })
    }
    return new Date(value)
  })
}

/**
 * regexes taken from main_repo defaultPasswordPatternSchema
 */
export const psPassword = () =>
  z
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(50, 'Password must not exceed 50 characters')
    .regex(/[a-zA-Z]/, 'Password must contain at least one letter')
    .regex(/.*[0-9].*/, 'Password must contain at least one number')
    .regex(
      /(?=.*[~`!@#$%^&*()+=_\-{}[\]|:;"'?/<>,.])/,
      'Password must contain at least one special character',
    )
