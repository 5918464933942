import { UseFormSetError } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { z } from 'zod'

import { updateStoredAuthToken } from '@partnerslate/core'
import { unearthAffiliateMetadata } from '@partnerslate/feature/central/api/backendGateway'
import { parseFullAccount } from '@partnerslate/feature/central/api/parsers'
import { FullAccountWrapperSchema } from '@partnerslate/feature/central/api/schemas'
import { FULL_ACCOUNT_QUERY_KEY } from '@partnerslate/feature/central/IdentityProvider'

export type LoginFormType = z.infer<typeof loginFormSchema>
export const loginFormSchema = z.object({
  email: z.string().min(1, {
    message: 'Email is required',
  }),
  password: z.string().min(1, {
    message: 'Password is required',
  }),
})

export async function loginUser(payload: LoginFormType) {
  const affiliateMetadata = unearthAffiliateMetadata()
  const response = await fetch('/api/v1/accounts/login/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: payload.email,
      password: payload.password,
      affiliate_metadata: affiliateMetadata,
    }),
  })

  if (!response.ok) {
    const errorResponse = await response.json()
    throw new Error(errorResponse.error || 'An error occurred')
  }

  const result = await response.json()

  const fullAccount = FullAccountWrapperSchema.parse(result)
  updateStoredAuthToken(fullAccount.data.token)

  const parsedFullAccount = parseFullAccount(fullAccount.data)
  return parsedFullAccount
}

export function useLogin(setError: UseFormSetError<LoginFormType>) {
  const queryClient = useQueryClient()
  return useMutation(loginUser, {
    onError: (error) => {
      if (error instanceof Error) {
        setError('root.serverError', {
          message: error.message,
        })
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData([FULL_ACCOUNT_QUERY_KEY], data)
    },
  })
}
