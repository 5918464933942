import { Route } from 'react-router-dom'

import ErrorPageWithImage from '@partnerslate/ui-components/lib/ErrorPageWithImage'

import AccountTypeScreen from './screens/AccountType'
import { BookACall } from './screens/BookACall'
import ComanDetailsScreen from './screens/ComanDetails'
import CompanyProfileScreen from './screens/CompanyProfile'
import CompleteComanProfileScreen from './screens/CompleteComanProfile'
import CompleteSignupScreen from './screens/CompleteSignup'
import ConfirmReadiness from './screens/ConfirmReadiness'
import DiversityScreen from './screens/Diversity'
import ManufacturerTypeScreen from './screens/ManufacturerType'
import PersonalAccountScreen from './screens/PersonalAccount'
import TeamInviteAcceptScreen from './screens/TeamInvite'

export function signupRouteSet() {
  return [
    <Route key="register" path="register" element={<AccountTypeScreen />} />,
    <Route key="register/account" path="register/account" element={<PersonalAccountScreen />} />,
    <Route
      key="register/manufacturer"
      path="register/manufacturer"
      element={<ManufacturerTypeScreen />}
    />,
    <Route key="register/company" path="register/company" element={<CompanyProfileScreen />} />,
    <Route
      key="register/company-profile"
      path="register/company-profile"
      element={<CompleteComanProfileScreen />}
    />,
    <Route
      key="register/company-details"
      path="register/company-details"
      element={<ComanDetailsScreen />}
    />,
    <Route key="register/other-info" path="register/other-info" element={<DiversityScreen />} />,
    <Route key="register/complete" path="register/complete" element={<CompleteSignupScreen />} />,
    <Route key="register/readiness" path="register/readiness" element={<ConfirmReadiness />} />,
    <Route key="register/book-a-call" path="register/book-a-call" element={<BookACall />} />,
    <Route
      key="team-invite/:token/accept"
      path="team-invite/:token/accept"
      element={<TeamInviteAcceptScreen />}
    />,
    <Route key="*" path="*" element={<ErrorPageWithImage />} />,
  ]
}
