import { Typography } from '@mui/material'

import { BaseBanner, CtaLinkButton } from './BaseBanner'
import * as instrumentation from './helpers/instrumentation'

type FreeBannerProps = {
  connectionsLeft: number
  repoClickOrigin: 'monorepo' | 'main_repo'
  renewalDate: string | null
}
export function FreeBanner(props: FreeBannerProps) {
  const monorepoRouter = props.repoClickOrigin === 'monorepo' ? 'router' : 'regular'
  const renewalText = props.renewalDate ? ` Renews on ${props.renewalDate}.` : ''
  const connectionText = props.connectionsLeft === 1 ? 'connection' : 'connections'

  return (
    <BaseBanner
      bannerBody={
        <Typography>{`You have ${props.connectionsLeft} ${connectionText} left for this period.${renewalText}`}</Typography>
      }
      cta={
        <CtaLinkButton
          text="Upgrade"
          url={props.connectionsLeft === 0 ? '/marketplace/nmp' : '/marketplace/coman-pricing'}
          routerType={monorepoRouter}
          onClick={() => {
            instrumentation.clickComanUpgrade('Free Banner')
          }}
        />
      }
    />
  )
}
