import { z } from 'zod'

// TODO: use these everywhere
const ReferenceGroupSchema = z.object({
  id: z.number(),
  label: z.string(),
  group: z.object({
    id: z.number(),
    name: z.string(),
  }),
})

// bit of a cheat to be defining a domain-ish thing here, but I don't think
// it's worth exporting a whole domain thing just for this
export type GroupedReferenceItem = {
  id: number
  groupLabel: string
  subLabel: string
}

export const ReferenceItemSchema = z.object({
  id: z.number(),
  label: z.string(),
})

// Although this domain type looks exactly the same as the z.object defined above,
// we intentionally keep them separate to clearly distinguish between the raw data shape
// used for validation and the domain object used in the application. This separation
// reinforces the practice of isolating domain objects from raw data even when both look
// the same.
export type ReferenceItem = {
  id: number
  label: string
}

export const referenceGroupsAsStrings = () => {
  return z.array(ReferenceGroupSchema).transform<GroupedReferenceItem[]>((value) => {
    return value.map((rg) => ({
      id: rg.id,
      groupLabel: rg.group.name,
      subLabel: rg.label,
    }))
  })
}
