import * as Sentry from '@sentry/react'
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { z } from 'zod'

import wait from '../helpers/wait'

export type AuthToken = {
  access: string
  refresh: string
}

const getAuthToken = (): AuthToken | null => {
  const authToken = localStorage.getItem('auth')
  return authToken ? JSON.parse(authToken) : null
}

export const updateStoredAuthToken = (authToken: AuthToken) => {
  localStorage.setItem('auth', JSON.stringify(authToken))
}

const clearStoredAuthToken = () => {
  localStorage.removeItem('auth')
}

export type AppMetadata = {
  appName: string
  buildstamp: string
}
let appMetadataGlobalton: AppMetadata | null = null

export function setAppMetadata(appMetadata: AppMetadata) {
  appMetadataGlobalton = appMetadata
}

function metadataHeaders(): Record<string, string> {
  if (!appMetadataGlobalton) {
    return {}
  } else {
    return {
      'PS-App': appMetadataGlobalton.appName,
      'PS-Buildstamp': appMetadataGlobalton.buildstamp,
      'PS-Interop-Version': '1', // hardcoded for now
    }
  }
}

const updateHeaders = (headers: AxiosRequestHeaders) => {
  headers.concat({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...metadataHeaders(),
    // temporarily put this in place to force API requests to go all the way to the origin, and
    // in doing so forcing all the caches along the way to notice that our responses now
    // include a Cache-Control header
    'Cache-Control': 'no-cache',
  })

  const authToken = getAuthToken()
  if (authToken?.access) {
    headers.setAuthorization(`Bearer ${authToken.access}`)
  }

  return headers
}

const getAxiosInstance = (): AxiosInstance => {
  const axiosInstance = axios.create()

  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      updateHeaders(config.headers)
      return config
    },
    (error: AxiosError) => Promise.reject(error),
  )
  axiosInstance.interceptors.response.use((response: AxiosResponse) => response)

  return axiosInstance
}

const axiosInstance = getAxiosInstance()

const handleRequestError =
  (redirectToLoginOnAuthFailure: boolean) =>
  (err: AxiosError | AuthFailure | Error): Promise<void> => {
    if (err instanceof AuthFailure) {
      console.error(err)
      Sentry.addBreadcrumb({
        category: 'AuthFailure',
        message: err.message,
        level: 'warning',
      })
      if (redirectToLoginOnAuthFailure) {
        // In the process of logging out, some request could fail and land here. If that happens,
        // we don't want to redirect the user to /logout right after login.
        const nextPath = window.location.pathname === '/logout' ? '' : window.location.pathname
        const nextUrl = `${nextPath}${window.location.search}`
        const newLocation = nextUrl ? `/?nextUrl=${encodeURIComponent(nextUrl)}` : '/'
        window.location.replace(newLocation)
        return wait(5000) // give relocation a chance to complete; don't move straight into trying to parse an undefined result (sc-7663)
      } else {
        // callers who asked not to trigger a redirect will instead
        // receive an AuthFailure exception
        throw err
      }
    } else if (axios.isAxiosError(err)) {
      if (err.response && ![401, 403].includes(err.response.status)) {
        Sentry.captureException(err)
      }
      throw err
    } else {
      Sentry.captureException(err)
      throw err
    }
  }

export class AuthFailure extends Error {
  cause: unknown | undefined

  constructor(message: string, cause?: unknown) {
    super(message)
    this.name = 'AuthFailure'
    this.cause = cause
    Object.setPrototypeOf(this, AuthFailure.prototype)
  }
}

const refreshAuthToken = async (failedRequest: AxiosError): Promise<void> => {
  const authToken = getAuthToken()
  const payload = { refresh: authToken?.refresh }
  if (!payload.refresh) {
    throw new AuthFailure('no refresh token')
  }

  try {
    // this will call the refresh endpoint with an Authorization header that includes an
    // expired access token but it still seems to work. We aren't sure if it'd be better
    // to remove that header
    const response = await axios.post('/api/v1/refresh/', payload)

    updateStoredAuthToken(response.data)
    if (failedRequest?.response?.config?.headers) {
      failedRequest.response.config.headers['Authorization'] = `Bearer ${response.data.access}`
    }
  } catch (e) {
    clearStoredAuthToken()
    throw new AuthFailure('refresh failed', e)
  }
}

createAuthRefreshInterceptor(axiosInstance, refreshAuthToken, { statusCodes: [401, 403] })

export const rawGetOrThrow = (
  url: string,
  redirectToLoginOnAuthFailure: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return axiosInstance
    .get(url)
    .then((res) => res.data)
    .catch(handleRequestError(redirectToLoginOnAuthFailure))
}

export const getOrThrow = <RS extends z.ZodTypeAny>(
  url: string,
  responseSchema: RS,
  redirectToLoginOnAuthFailure: boolean = true,
): Promise<z.infer<RS>> => {
  let responsePayload: unknown
  return rawGetOrThrow(url, redirectToLoginOnAuthFailure)
    .then((data) => {
      responsePayload = data
      return data
    })
    .then(responseSchema.parse)
    .catch((err) => {
      if (!(err instanceof AuthFailure)) {
        // If we didn't redirectToLoginOnAuthFailure, the caller should have passed false on that
        // parameter, and thus we can 'ignore' AuthFailure here and only report and throw other
        // types of exceptions

        Sentry.captureException(err, {
          extra: {
            fullError: JSON.stringify(err, null, 2),
            fullStack: err.stack,
            payload: JSON.stringify(responsePayload, null, 2),
          },
        })
      }
      throw err
    })
}

export const postOrThrow = async <RS extends z.ZodTypeAny>(
  url: string,
  responseSchema: RS,
  payload: unknown = undefined,
): Promise<z.infer<RS>> => {
  return axiosInstance
    .post(url, payload)
    .then((res) => {
      return responseSchema.parse(res.data)
    })
    .catch(handleRequestError(true))
}

export const patchOrThrow = async <RS extends z.ZodTypeAny>(
  url: string,
  responseSchema: RS,
  payload: unknown = undefined,
): Promise<z.infer<RS>> => {
  return axiosInstance
    .patch(url, payload)
    .then((res) => responseSchema.parse(res.data))
    .catch(handleRequestError(true))
}

export const deleteOrThrow = async <RS extends z.ZodTypeAny>(
  url: string,
  responseSchema: RS,
): Promise<z.infer<RS>> => {
  return axiosInstance
    .delete(url)
    .then((res) => responseSchema.parse(res.data))
    .catch(handleRequestError(true))
}

export const barePost = async (url: string, payload: unknown = undefined) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })

  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`)
  }
}
