import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function useRedirectToSignupFirstPage(companyType: string) {
  const navigate = useNavigate()

  useEffect(() => {
    if (companyType === '') {
      navigate('/register')
    }
  }, [companyType, navigate])
}
