import maxBy from 'lodash/maxBy'

import { FullAccount } from '@partnerslate/feature/central/api/parsers'

// copied from main_repo
export const Routes = {
  ProjectIntakeNewProject: '/projects/new',
  ProjectIntakeLanding: (projectId: string) => `/projects/${projectId}/landing`,
  EngagementDetailSlug: (engagementId: string) => `/engagement/${engagementId}`,
  BrandMatchesListingSlug: (projectId: string) => `/project/${projectId}/matches`,
  Engagements: '/engagements',
  MyProfile: '/p',
  Marketplace: '/marketplace',
}

function buildResult(url: string, useReactRouter = true) {
  return {
    nextUrl: url,
    useReactRouter,
  }
}

// TODO: revisit client side routing now we are in monorepo
export function postLoginRoute(fullAccount: FullAccount) {
  const { engagementsCount, firstEngagementId } = fullAccount.engagementInfo
  const { company, projects } = fullAccount

  const isBrand = company.companyType === 'brand'
  const isComan = company.companyType === 'manufacturer'

  function _calcNextUrlForBrand() {
    const mostRecentProject = maxBy(projects, (p) => new Date(p.createdAt))

    // if there are no projects, go to new project intake form
    if (projects.length === 0 || mostRecentProject === undefined) {
      return buildResult(Routes.ProjectIntakeNewProject, false)
    }

    // if all projects are unpaid, go to the most recent project's intake landing
    const allProjectsAreUnpaid = projects.every((p) => !p.isPaid)
    if (allProjectsAreUnpaid) {
      return buildResult(Routes.ProjectIntakeLanding(mostRecentProject.id), false)
    }

    // if there's just a single project and engagement, go directly to engagement
    if (engagementsCount === 1 && projects.length === 1 && firstEngagementId !== null) {
      return buildResult(Routes.EngagementDetailSlug(firstEngagementId), false)
    }

    const matchedProjects = projects.filter((p) => p.isMatched)
    const unMatchedProjects = projects.filter((p) => !p.isMatched)

    // if there's just a single matched project go directly to the matches list for that project. Only a brand can have projects
    if (matchedProjects.length === 1) {
      return buildResult(Routes.BrandMatchesListingSlug(matchedProjects[0].id), false)
    }

    if (engagementsCount === 0) {
      // If they don't have engagements, but do have multiple projects that have not been matched, land on the most recent project
      if (matchedProjects.length > 1) {
        return buildResult(Routes.ProjectIntakeLanding(mostRecentProject.id), false)
      }

      // If they don't have engagements, but have a single unmatched project that have not been matched, land on that project intake (if unpaid)
      // or the project matches (if paid)

      if (unMatchedProjects.length >= 1) {
        const firstProject = unMatchedProjects[0]
        if (firstProject.isPaid) {
          // land in project matches (if paid)
          return buildResult(Routes.BrandMatchesListingSlug(firstProject.id), false)
        }
        // land in project intake (if unpaid)
        return buildResult(Routes.ProjectIntakeLanding(firstProject.id), false)
      }
    }

    // Regardless of number of engagements, if they have more than one project, land on the most recent project intake
    if (projects.length > 1) {
      return buildResult(Routes.ProjectIntakeLanding(mostRecentProject.id), false)
    }

    // Fallback to the profile page
    return buildResult(Routes.MyProfile)
  }

  function _calcNextUrlForComan() {
    // if there are any engagements go to engagements list page. Only a coman can have a partnerEngagement
    if (engagementsCount > 0) {
      return buildResult(Routes.Engagements, false)
    }

    // proceed to marketplace for any other scenario
    return buildResult(Routes.Marketplace, false)
  }

  if (isBrand) {
    return _calcNextUrlForBrand()
  }
  if (isComan) {
    return _calcNextUrlForComan()
  }
  // fallback to the profile page in case of tenants that are not Brand nor
  // Manufacturer: i.e. Supplier as of this writing
  return buildResult(Routes.MyProfile)
}
