import { useState } from 'react'
import { Controller, ErrorOption, FieldPath, useForm } from 'react-hook-form'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { MuiTelInput } from 'mui-tel-input'

import { DjangoApiError, isGenericError } from '@partnerslate/core/djangoValidation'
import { getReferenceData } from '@partnerslate/feature/central/api/parsers'
import {
  MultipleSelect,
  Select,
  SelectOption,
} from '@partnerslate/feature/central/components/Select'
import { ReferenceData } from '@partnerslate/feature/central/domain/referenceData'
import { fieldOptionsFromActiveReferenceItems } from '@partnerslate/segment/projects/domain'
import { SupportEmailAnchor } from '@partnerslate/ui-components'
import { ButtonWithLoading } from '@partnerslate/ui-components/lib/Button'
import {
  findAddressComponent,
  PlacesAutocompleteSuggestions,
} from '@partnerslate/ui-components/lib/forms/PlacesAutocompleteSuggestions'

import { AllFormFields, extractBackendFormErrors } from '../api/parsers'
import { useRegister } from '../api/queries'
import { CompanyProfileForm, SignupForm } from '../domain/signup'
import * as instrumentation from '../instrumentation'
import { useFormContext } from '../SignupContext'
import { getCompanyProfileResolver } from '../validations'
import { SignupServerError } from './SignupServerError'
import { useRedirectToSignupFirstPage } from './useRedirect'

const howDidYouHearAboutUsList: string[] = [
  'Referral / Word of Mouth',
  'Consultant',
  'Trade Show',
  'Podcast',
  'Google Search',
  'RangeMe / ECRM',
  'Other',
]
export const howDidYouHearAboutUsOptions: SelectOption[] = howDidYouHearAboutUsList.map((item) => ({
  label: item,
  value: item,
}))

export default function CompanyProfileScreen() {
  const navigate = useNavigate()
  const { form, setCompanyProfileForm } = useFormContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const registerService = useRegister()
  const [genericFormError, setGenericFormError] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<
    [FieldPath<AllFormFields>, ErrorOption][] | null
  >(null)

  const REFERENCE_DATA: ReferenceData = getReferenceData()
  const COMPANY_REVENUE_OPTIONS = fieldOptionsFromActiveReferenceItems(
    REFERENCE_DATA.companyRevenueRanges,
  )

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<CompanyProfileForm>({
    defaultValues: form.companyProfileForm,
    mode: 'onBlur',
    resolver: getCompanyProfileResolver(form.accountTypeForm.companyType),
  })

  const address = watch('address.address1')

  const companyType = form.accountTypeForm.companyType
  const isBrand = companyType === 'brand'

  useRedirectToSignupFirstPage(companyType)

  const handleChangeAddress = (value: string) => {
    setValue('address.address1', value)
  }

  const handleSelectAddress = async (address: string) => {
    const results = await geocodeByAddress(address)
    const [{ address_components: addressComponents }] = results

    const streetNumber = findAddressComponent(addressComponents, 'street_number')
    const route = findAddressComponent(addressComponents, 'route', 'short_name')
    const city = addressComponents.find(
      (component) =>
        component.types.includes('locality') || component.types.includes('postal_town'),
    )?.long_name
    const state = findAddressComponent(
      addressComponents,
      'administrative_area_level_1',
      'short_name',
    )
    const zipCode = findAddressComponent(addressComponents, 'postal_code')
    const countryCode = findAddressComponent(addressComponents, 'country', 'short_name')

    setValue('address.address1', `${streetNumber || ''} ${route || ''}`, {
      shouldValidate: true,
    })
    setValue('address.city', city || '', { shouldValidate: true })
    setValue('address.state', state || '', { shouldValidate: true })
    setValue('address.postalCode', zipCode || '', { shouldValidate: true })
    setValue('address.countryCode', countryCode || '', { shouldValidate: true })
  }

  function getIsSupplierManufacturer(form: SignupForm) {
    const { manufacturerTypes } = getReferenceData()
    const requiredCompanyType = 'manufacturer'
    const supplierManufacturerReferenceType = manufacturerTypes.find(
      (type) => type.title === 'Supplier',
    )

    // beyond .find returning undefined above, someone could change the reference data in admin
    // without us knowing and/or reference data could not load
    if (!supplierManufacturerReferenceType) {
      return false
    }

    const requiredSupplierType = [String(supplierManufacturerReferenceType.id)]

    const isManufacturerType = form.accountTypeForm.companyType === requiredCompanyType
    const isSupplier = isEqual(form.manufacturerTypeForm.manufacturerType, requiredSupplierType)

    return isManufacturerType && isSupplier
  }

  const onSubmit = (data: CompanyProfileForm) => {
    setIsLoading(true)
    instrumentation.fillInCompanyDetails()

    setCompanyProfileForm(data)

    const formData = { ...form, companyProfileForm: data }

    if (isBrand) {
      navigate('/register/complete')
    } else {
      const isSupplierManufacturer = getIsSupplierManufacturer(formData)

      if (isSupplierManufacturer) {
        registerService.mutate(formData, {
          onSuccess: (user) => {
            const formStackLink = `https://partnerslate.formstack.com/forms/suppliers?What%20is%20your%20email=${encodeURIComponent(
              user.emailAddress,
            )}&What%20is%20your%20name-first=${encodeURIComponent(
              user.firstName,
            )}&What%20is%20your%20name-last=${
              user.lastName
            }&What%20is%20the%20name%20of%20your%20company=${encodeURIComponent(
              user.company.companyName,
            )}&company_id=${encodeURIComponent(user.company.id)}`

            const onSignupCompleteRedirect = () => window.location.replace(formStackLink)

            instrumentation.updateIntercomUser({
              name: `${user.firstName} ${user.lastName}`,
              email: user.emailAddress,
              userId: user.id,
              dateJoined: user.dateJoined,
            })
            instrumentation.signupCompleted(user, onSignupCompleteRedirect)
            setIsLoading(false)
          },
          onError: (e: DjangoApiError) => {
            if (isGenericError(e)) {
              setGenericFormError(true)
              return
            }

            setValidationErrors(extractBackendFormErrors(e.fieldErrors))

            setIsLoading(false)
          },
        })
      } else {
        navigate('/register/company-profile')
      }
    }
  }

  let companyNameError: string | JSX.Element = errors.companyName?.message ?? ''
  if (errors.companyName?.type === 'invalid') {
    companyNameError = (
      <>
        This company name already exists. Try logging in above or contacting <SupportEmailAnchor />.
      </>
    )
  }

  const { brandStages, distributionTypes } = getReferenceData()

  const brandStageOptions: SelectOption[] = brandStages.map(({ id, subText }) => ({
    label: subText,
    value: id,
  }))
  const distributionTypeOptions: SelectOption[] = distributionTypes.map(({ id, label }) => ({
    label,
    value: id,
  }))

  return (
    <Container>
      <Typography variant="h2" mt={6} textAlign="center">
        Create your company profile
      </Typography>

      <Grid container my={5} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper>
            <Box onSubmit={handleSubmit(onSubmit)} component="form" px={{ xs: 2, md: 8 }} py={3}>
              <Typography variant="h4" textAlign="center" mt={1}>
                Company information
              </Typography>

              <TextField
                {...register('companyName')}
                error={!!errors.companyName}
                fullWidth
                helperText={companyNameError}
                label="Company Name *"
                margin="normal"
              />
              <TextField label="Website" fullWidth margin="normal" {...register('website')} />
              <PlacesAutocomplete
                value={address}
                onChange={handleChangeAddress}
                onSelect={handleSelectAddress}
                shouldFetchSuggestions={address.length > 1}
                // This callback name is the one declared in the script tag in index.html that loads the google maps api
                googleCallbackName="initPlacesAutoComplete"
              >
                {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => (
                  <Box position="relative">
                    <TextField
                      {...register('address.address1')}
                      {...getInputProps()}
                      error={!!errors.address?.address1}
                      fullWidth
                      helperText={errors.address?.address1?.message ?? ''}
                      label="Company Address *"
                      sx={{ mb: 0, mt: 1.5 }}
                    />
                    <PlacesAutocompleteSuggestions
                      getSuggestionItemProps={getSuggestionItemProps}
                      isLoading={loading}
                      suggestions={suggestions}
                    />
                  </Box>
                )}
              </PlacesAutocomplete>
              <TextField
                {...register('address.address2')}
                error={!!errors.address?.address2}
                fullWidth
                helperText={errors.address?.address2?.message ?? ''}
                label="Address Line 2"
                margin="normal"
                InputLabelProps={{ shrink: !!watch('address.address2') }}
              />
              <TextField
                {...register('address.city')}
                error={!!errors.address?.city}
                fullWidth
                helperText={errors.address?.city?.message ?? ''}
                label="City *"
                margin="normal"
                InputLabelProps={{ shrink: !!watch('address.city') }}
              />
              <TextField
                {...register('address.state')}
                error={!!errors.address?.state}
                fullWidth
                helperText={errors.address?.state?.message ?? ''}
                label="State *"
                margin="normal"
                InputLabelProps={{ shrink: !!watch('address.state') }}
              />
              <TextField
                {...register('address.postalCode')}
                error={!!errors.address?.postalCode}
                fullWidth
                helperText={errors.address?.postalCode?.message ?? ''}
                label="Zip Code *"
                margin="normal"
                InputLabelProps={{ shrink: !!watch('address.postalCode') }}
              />
              <Controller
                name="companyPhone"
                control={control}
                render={({ field }) => (
                  <MuiTelInput
                    {...field}
                    defaultCountry="US"
                    error={!!errors.companyPhone}
                    fullWidth
                    helperText={errors.companyPhone?.message ?? ''}
                    label="Company Phone Number *"
                    margin="normal"
                    preferredCountries={['US', 'CA']}
                  />
                )}
              />
              {isBrand ? (
                <>
                  <TextField
                    {...register('yearFounded')}
                    error={!!errors.yearFounded}
                    fullWidth
                    helperText={errors.yearFounded?.message ?? ''}
                    label="Year Founded *"
                    margin="normal"
                    InputLabelProps={{ shrink: !!watch('yearFounded') }}
                  />
                  <Select
                    error={errors.brandStage}
                    label="Annual Company Revenue *"
                    name="brandStage"
                    options={brandStageOptions}
                    register={register}
                    watch={watch}
                  />
                  <MultipleSelect
                    error={errors.distributionType?.[0]}
                    label="Current Distribution *"
                    name="distributionType"
                    options={distributionTypeOptions}
                    register={register}
                    watch={watch}
                  />
                </>
              ) : null}
              {!isBrand ? (
                <>
                  <Select
                    error={errors.howDidYouHearAboutUs}
                    label="How did you hear about us? *"
                    name="howDidYouHearAboutUs"
                    options={howDidYouHearAboutUsOptions}
                    register={register}
                    watch={watch}
                  />
                  {watch('howDidYouHearAboutUs') === 'Other' ? (
                    <TextField
                      {...register('howDidYouHearAboutUsOther')}
                      fullWidth
                      label="How you heard about us"
                      margin="normal"
                      InputLabelProps={{ shrink: !!watch('howDidYouHearAboutUsOther') }}
                    />
                  ) : null}
                  <Select
                    error={errors.comanRevenue}
                    label="What is your revenue *"
                    name="comanRevenue"
                    options={COMPANY_REVENUE_OPTIONS}
                    register={register}
                    watch={watch}
                  />
                </>
              ) : null}

              <Box my={4}>
                <ButtonWithLoading
                  fullWidth
                  isLoading={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {getIsSupplierManufacturer(form) ? 'Create account' : 'Continue'}
                </ButtonWithLoading>
              </Box>
              <SignupServerError
                isGenericFormError={genericFormError}
                validationErrors={validationErrors}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
