import { Box } from '@mui/material'

export default function MobileMenuHamburger({ isOpen }: { isOpen: boolean }) {
  return (
    <>
      <NavbarMobileMenuLine isOpen={isOpen} />
      <NavbarMobileMenuLine isOpen={isOpen} />
      <NavbarMobileMenuLine isOpen={isOpen} />
    </>
  )
}

function NavbarMobileMenuLine({ isOpen }: { isOpen: boolean }) {
  return (
    <Box
      height={2}
      bgcolor="primary.contrast"
      sx={{
        transformOrigin: 1,
        transition: 'all 0.3s linear',
        '&:first-of-type': {
          transform: isOpen ? 'rotate(45deg)' : 'rotate(0)',
        },
        '&:nth-of-type(2)': {
          opacity: isOpen ? 0 : 1,
          transform: isOpen ? 'translateX(20px)' : 'translateX(0)',
        },
        '&:nth-of-type(3)': {
          transform: isOpen ? 'rotate(-45deg)' : 'rotate(0)',
        },
      }}
    />
  )
}
