//  MSA_SIGNATURE_STATUSES:
//    NOT_CREATED = there is no MSA instance created
//    NOT_SENT = An MSA instance exists, but a signature request has not been sent to the company
//    PENDING = A signature request has been sent to the company, but has not yet been signed
//    SIGNED = The signature request of the MSA has been signed by the company
export const MSA_SIGNATURE_STATUSES = ['not_created', 'not_sent', 'pending', 'signed'] as const
export const COMPANY_TYPES = ['brand', 'manufacturer'] as const

export type CompanyType = typeof COMPANY_TYPES[number]
export type UserAccount = {
  id: string
  emailAddress: string
  firstName: string
  lastName: string
  jobTitle: string | null
  hasLeadManager: boolean
  dateJoined: Date
  company: {
    id: string
    marketplaceMSASignatureStatus: typeof MSA_SIGNATURE_STATUSES[number]
    companyType: CompanyType
    companyName: string
    companyPhone?: string | null
    brandStage?: string
    msaReminderSentAt: Date | null
    msaSignerEmail: string | null
    slug: string
    penalty: Penalty | null
    needsToUpdateAllEngagements: boolean
  }
  projects: {
    id: string
    name: string
    isPaid: boolean
  }[]
  entitlements: {
    connectionsRemaining: number
    canSendConnections: boolean
    shouldSeeTrialBanner: boolean
    trialExpiresAt: Date | null
  }
  plan: {
    id: string
    isActivePaidSubscriber: boolean
    name: string
    planRenewsAt: Date | null
  }
}
export type Penalty = {
  title: string | null
  body: string | null
}

export type IntercomUser = {
  name: string
  email: string
  userId: string | null
  dateJoined: Date | null
  companyType: CompanyType | string
}

const alphanumericPattern = '(?=.*[A-Za-z0-9])'
const specialCharacterPattern = '(?=.*[!@#$%^&*()\\-_=+{};:,<.>])'
const minimumLengthPattern = '.{8,}'
export const PASSWORD_COMPLEXITY_REGEX = new RegExp(
  '^' + alphanumericPattern + specialCharacterPattern + minimumLengthPattern + '$',
)
export const PASSWORD_COMPLEXITY_MESSAGE =
  'Your password must have at least eight characters, one letter, one number, and one special character'
