import * as Sentry from '@sentry/browser'
import mixpanel, { Dict, Mixpanel, RequestOptions } from 'mixpanel-browser'

let mixpanelGlobalton: Mixpanel | null = null

export const GROUP_KEYS = {
  companyId: 'Company ID',
}

// We only expose the underlying Mixpanel instance for legacy
// reasons. Do not use it directly - use `mixpanelTrack`
// or `identify` instead.
export const initMixpanel = (token: string): Promise<Mixpanel> => {
  return new Promise<Mixpanel>((resolve, reject) => {
    try {
      mixpanel.init(token, {
        loaded: (mp: Mixpanel) => {
          mixpanelGlobalton = mp
          resolve(mixpanelGlobalton)
        },
      })
    } catch (error) {
      Sentry.captureException(error)
      reject(error)
    }
  })
}

// we create a instance of the CIO client specifically for tracking company-level events (because
// these are sent to the company bogo in CIO, not the actual user)
let cioCompanyGlobalton: any | null = null
export const initCustomerIO = (cioWriteKey: string) => {
  // we use a dynamic import here because this module imports another module (customerio-gist-web) which
  // does not work correctly in all environments (specifically when running jest tests). Our hacky solution
  // is to defer loading the module (and thus its dependencies) until this specific function is called, which
  // does not happen in Jest tests.
  import('@customerio/cdp-analytics-browser').then(({ AnalyticsBrowser }) => {
    cioCompanyGlobalton = AnalyticsBrowser.load({ writeKey: cioWriteKey })
  })
}

const swallowAndLogException = (fn: () => void) => {
  try {
    fn()
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const mixpanelTrack = (
  eventName: string,
  properties?: Dict,
  requestOptions?: RequestOptions,
): Promise<void> => {
  if (!mixpanelGlobalton) {
    return Promise.resolve()
  }

  const mp = mixpanelGlobalton // assignment to lock in type narrowing
  return new Promise<void>((resolve) => {
    // Important detail about the callback: The callback isn't called when the event is sent
    // bur rather when it's enqued. Ref: https://github.com/mixpanel/mixpanel-js/issues/433#issuecomment-2231296633
    swallowAndLogException(() => {
      mp.track(eventName, properties, requestOptions, () => resolve())
    })
  })
}

export const mixpanelTrackPage = (pageUrl: string): void => {
  mixpanelTrack('View Site Page', { 'Page Url': pageUrl })
}

export const fireCustomerIoEvent = (eventName: string, properties?: Dict) => {
  if (cioCompanyGlobalton) {
    const cio = cioCompanyGlobalton // assignment to lock in type narrowing
    swallowAndLogException(() => {
      cio.track(eventName, properties)
    })
  }
}

// https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#adding-users-to-a-group
// this should send the the key/value pair for this company group on all events triggered by the user
const attachUserToCompanyGroup = (companyId: string) => {
  if (mixpanelGlobalton) {
    mixpanelGlobalton.set_group(GROUP_KEYS.companyId, companyId)
  }
}

type Identity = {
  userId: string
  emailAddress: string
  companyId: string
}
export const identify = ({ userId, emailAddress, companyId }: Identity): void => {
  if (mixpanelGlobalton) {
    mixpanelGlobalton.identify(userId)
    attachUserToCompanyGroup(companyId)
  }
  if (cioCompanyGlobalton) {
    // note that for the company-level CIO client we are associatng the user with their company
    // bogo in CIO, not with their actual user profile
    const companyBogoId = `partnerslate:company:${companyId}`
    cioCompanyGlobalton.identify(companyBogoId)
  }
  Sentry.setUser({
    id: userId,
    email: emailAddress,
  })
}

export const mixpanelAlias = (userId: string): void => {
  if (mixpanelGlobalton) {
    mixpanelGlobalton.alias(userId)
  }
}

export const mixpanelPeopleSet = (properties: Dict, cb?: () => void): void => {
  if (mixpanelGlobalton) {
    mixpanelGlobalton.people.set(properties, cb)
  } else {
    // mixpanelGlobalton is null in environments like journey tests
    // So, it's required to execute the cb call directly to finish the flow correctly
    cb ? cb() : null
  }
}

export const resetMixpanel = () => {
  if (mixpanelGlobalton) {
    mixpanelGlobalton.reset()
  }
}

type EventData = Record<string, unknown>
export const sendCometlyEvent = async (
  url: string,
  data: EventData,
  appEnv: 'localdev' | 'stg' | 'prd', // duplicated as we cannot import from central
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cometToken = window.cometToken ? await window.cometToken() : undefined
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const cometFingerprint = window.cometFingerprint ? await window.cometFingerprint() : undefined

    const eventData = {
      appEnv,
      ...data,
      ...(cometToken && { cometToken }),
      ...(cometFingerprint && { cometFingerprint }),
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData),
    })

    if (!response.ok) {
      throw new Error(`Failed with status: ${response.status}`)
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}
