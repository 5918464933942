import { Suggestion } from 'react-places-autocomplete'
import { Box, CircularProgress, List, ListItemButton } from '@mui/material'

interface PlacesAutocompleteSuggestionsProps {
  getSuggestionItemProps: any
  isLoading: boolean
  suggestions: readonly Suggestion[]
}

export function PlacesAutocompleteSuggestions({
  getSuggestionItemProps,
  isLoading,
  suggestions,
}: PlacesAutocompleteSuggestionsProps) {
  if (isLoading) {
    return (
      <Box p={1}>
        <CircularProgress size={20} />
      </Box>
    )
  }

  return (
    <List
      sx={{
        backgroundColor: 'primary.contrast',
        borderBottom: 1,
        borderLeft: 1,
        borderRight: 1,
        borderColor: 'divider',
        display: suggestions.length ? 'block' : 'none',
        position: 'absolute',
        width: '100%',
        zIndex: 2,
      }}
    >
      {suggestions.map((suggestion) => (
        <ListItemButton
          {...getSuggestionItemProps(suggestion)}
          key={suggestion.placeId}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '&:last-child': { borderBottom: 'none' },
          }}
        >
          {suggestion.description}
        </ListItemButton>
      ))}
    </List>
  )
}

export function findAddressComponent(
  components: google.maps.GeocoderAddressComponent[],
  field: string,
  length: 'long_name' | 'short_name' = 'long_name',
) {
  return components.find((component) => component.types.includes(field))?.[length]
}
