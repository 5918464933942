import { Link, LinkProps } from '@mui/material'





type LinkAnchorProps = {
  url: string
  text: string
  newWindow?: boolean
  linkProps?: Omit<LinkProps, "href"|"target"|"sx">
}

export function LinkAnchor({
  url,
  text,
  newWindow = false,
  linkProps = {},
}: LinkAnchorProps): JSX.Element {
  return (
    <Link
      href={url}
      target={newWindow ? '_blank' : '_self'}
      sx={{ textDecoration: 'none' }}
      {...linkProps}
    >
      {text}
    </Link>
  )
}

type EmailAnchorProps = {
  email: string
  newWindow?: boolean
}

export function EmailAnchor({ email, newWindow = false }: EmailAnchorProps): JSX.Element {
  return (
    <Link
      href={`mailto:${email}`}
      target={newWindow ? '_blank' : '_self'}
      sx={{ textDecoration: 'none' }}
    >
      {email}
    </Link>
  )
}

type SupportEmailAnchorProps = {
  newWindow?: boolean
}

export function SupportEmailAnchor({ newWindow = false }: SupportEmailAnchorProps): JSX.Element {
  return <EmailAnchor email="support@partnerslate.com" newWindow={newWindow} />
}
