import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'

import { IntercomUser } from '@partnerslate/feature/central/domain/userAccount'
import { PrivacyPolicyLink } from '@partnerslate/ui-components/lib/PrivacyPolicyLink'
import { TermsOfServiceLink } from '@partnerslate/ui-components/lib/TermsOfServiceLink'

import { PersonalAccountForm } from '../domain/signup'
import * as instrumentation from '../instrumentation'
import { useFormContext } from '../SignupContext'
import { personalAccountResolver } from '../validations'
import { useRedirectToSignupFirstPage } from './useRedirect'

function CheckboxLabel() {
  return (
    <Typography>
      I agree to PartnerSlate's <TermsOfServiceLink /> & <PrivacyPolicyLink /> *
    </Typography>
  )
}

function AcceptedTosError() {
  return (
    <FormHelperText error margin="dense">
      You must accept the terms to sign up
    </FormHelperText>
  )
}

export default function PersonalAccountScreen() {
  const navigate = useNavigate()
  const { form, setPersonalAccountForm } = useFormContext()
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<PersonalAccountForm>({
    defaultValues: form.personalAccountForm,
    mode: 'onBlur',
    resolver: personalAccountResolver,
  })

  const companyType = form.accountTypeForm.companyType

  useRedirectToSignupFirstPage(companyType)

  const onSubmit = (data: PersonalAccountForm) => {
    setPersonalAccountForm(data)
    instrumentation.fillInPersonalDetails()

    const user: IntercomUser = {
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      userId: null,
      dateJoined: null,
      companyType: form.accountTypeForm.companyType,
    }
    instrumentation.createIntercomUser(user)

    navigate('/register/company')
  }

  const passwordText =
    errors.password?.message ??
    'Password must be 8 characters and contain at least one letter, one number and one special character'

  return (
    <Container>
      <Typography variant="h2" mt={6} textAlign="center">
        Create your account
      </Typography>

      <Grid container my={5} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper>
            <Box onSubmit={handleSubmit(onSubmit)} component="form" px={{ xs: 2, md: 8 }} py={3}>
              <Typography variant="h4" textAlign="center">
                Your information
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    {...register('firstName')}
                    error={!!errors.firstName}
                    fullWidth
                    helperText={errors.firstName?.message ?? ''}
                    label="First Name *"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('lastName')}
                    error={!!errors.lastName}
                    fullWidth
                    helperText={errors.lastName?.message ?? ''}
                    label="Last Name *"
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <TextField label="Job Title" fullWidth margin="normal" {...register('jobTitle')} />

              <Typography variant="h4" textAlign="center" mt={1}>
                Your credentials
              </Typography>

              <TextField
                {...register('email')}
                error={!!errors.email}
                fullWidth
                helperText={errors.email?.message ?? ''}
                label="Email Address *"
                margin="normal"
              />
              <TextField
                {...register('password')}
                error={!!errors.password}
                fullWidth
                helperText={passwordText}
                label="Create Password *"
                margin="normal"
                type="password"
              />
              <TextField
                {...register('confirmPassword')}
                error={!!errors.confirmPassword}
                fullWidth
                helperText={errors.confirmPassword?.message ?? ''}
                label="Confirm Password *"
                margin="normal"
                type="password"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    {...register('acceptedTos')}
                    defaultChecked={form.personalAccountForm.acceptedTos}
                  />
                }
                label={<CheckboxLabel />}
              />
              {errors.acceptedTos ? <AcceptedTosError /> : null}

              <Box my={4}>
                <Button type="submit" variant="contained" fullWidth size="large">
                  Continue
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
