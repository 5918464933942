import { useEffect } from 'react'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { Box, Card, Container, Grid, Link, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'

import { getReferenceData } from '@partnerslate/feature/central/api/parsers'
import { CompanyType } from '@partnerslate/feature/central/domain/userAccount'

import { BrandImg, BusinessSupplierImg, ManufacturerImg } from '../assets/images'
import * as instrumentation from '../instrumentation'
import { useFormContext } from '../SignupContext'

export default function SignupScreen() {
  const { resetForm } = useFormContext()

  useEffect(() => {
    instrumentation.openRegisterSignup()
    resetForm()
  }, [])

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h2" mt={6}>
            Sign up for PartnerSlate
          </Typography>
          <Typography variant="h5" mt={1} color="text.secondary">
            Choose your account type:
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} my={5}>
        <Grid item xs={12} md={4}>
          <CompanyLink companyType="brand">
            <CompanyCard
              title="I am a brand"
              description="I am looking for a contract manufacturer/packager"
              image={BrandImg}
            />
          </CompanyLink>
        </Grid>
        <Grid item xs={12} md={4}>
          <CompanyLink companyType="manufacturer">
            <CompanyCard
              title="I am a manufacturer"
              description="I help food and beverage brands"
              image={ManufacturerImg}
            />
          </CompanyLink>
        </Grid>
        <Grid item xs={12} md={4}>
          <SupplierManufacturerLink>
            <CompanyCard
              title="I am a supplier"
              description="I provide supplies and formulations"
              image={BusinessSupplierImg}
            />
          </SupplierManufacturerLink>
        </Grid>
      </Grid>
      <Typography variant="body1" textAlign="center">
        <Link
          href="https://www.partnerslate.com"
          target="_blank"
          rel="noopener noreferrer"
          variant="h4"
        >
          Learn more about PartnerSlate
        </Link>
      </Typography>
    </Container>
  )
}

function SupplierManufacturerLink({ children }: { children: React.ReactNode }) {
  const companyType = 'manufacturer'
  const { setAccountTypeForm, setManufacturerTypeForm } = useFormContext()
  const { manufacturerTypes } = getReferenceData()
  const navigate = useNavigate()

  const supplierManufacturerReferenceType = manufacturerTypes.find(
    (type) => type.title === 'Supplier',
  )

  if (!supplierManufacturerReferenceType) {
    Sentry.captureException(
      new Error(`manufacturerType of "Supplier" could not be found in referenceData`),
    )

    return null
  }

  const handleClick = () => {
    setAccountTypeForm({ companyType })
    instrumentation.chooseCompanyType(companyType)

    setManufacturerTypeForm({ manufacturerType: [String(supplierManufacturerReferenceType.id)] })
    instrumentation.chooseManufacturerType()

    navigate('/register/account')
  }

  return (
    <Link
      onClick={handleClick}
      data-testid={companyType + '-supplier'}
      sx={{ cursor: 'pointer', textDecoration: 'none' }}
    >
      {children}
    </Link>
  )
}

type CompanyLinkType = {
  children: React.ReactNode
  companyType: CompanyType
}
function CompanyLink({ children, companyType }: CompanyLinkType) {
  const { setAccountTypeForm } = useFormContext()

  const handleClick = () => {
    setAccountTypeForm({ companyType })
    instrumentation.chooseCompanyType(companyType)
  }

  const isManufacturer = companyType === 'manufacturer'

  return (
    <Link
      to={isManufacturer ? '/register/manufacturer' : '/register/account'}
      component={ReactRouterLink}
      onClick={handleClick}
      underline="none"
      data-testid={companyType}
    >
      {children}
    </Link>
  )
}

type CompanyCardType = {
  title: string
  description: string
  image: string
  height?: number
  width?: number
}
// we only need to add height/width here because we don't yet have a proper illustration for Supplier

function CompanyCard({ image, title, description, height, width }: CompanyCardType) {
  return (
    <Card
      sx={{
        '&:hover': { cursor: 'pointer' },
        pointerEvents: 'none',
        textDecoration: 'none',
      }}
    >
      <Box minHeight={270} mx={2} my={2} textAlign="center" display="flex" flexDirection="column">
        {/* we can remove this once we get a proper image for Supplier */}
        <Box
          height={195}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={image} alt={title} height={height} width={width} />
        </Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1" color="text.secondary">
          {description}
        </Typography>
      </Box>
    </Card>
  )
}
