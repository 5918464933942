import { Controller, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'

import { ButtonWithLoading } from '@partnerslate/ui-components'

import LoginPhoto from '../../assets/images/orange-tree.jpg'
import { Layout } from '../../components/Layout'
import { UpdatePassword, updatePasswordSchema, useUpdatePassword } from './updatePassword'

export function UpdatePasswordScreen() {
  return (
    <Layout
      left={
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <UpdatePasswordForm />
        </Box>
      }
      right={
        <Box
          sx={{
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${LoginPhoto})`,
          }}
        />
      }
    />
  )
}

function UpdatePasswordForm() {
  const [searchParams] = useSearchParams()

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<UpdatePassword>({
    resolver: zodResolver(updatePasswordSchema),
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })

  const reset = useUpdatePassword(setError)

  const onSubmit = (data: UpdatePassword) => {
    const token = searchParams.get('token') ?? ''
    const payload = {
      ...data,
      token,
    }

    reset.mutate(payload)
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: (theme) => theme.spacing(35),
        width: '100%',
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography
        component="h1"
        variant="h3"
        fontWeight={(theme) => theme.typography.fontWeightBold}
      >
        Reset Your Password
      </Typography>
      <>
        <Typography>Please enter your new password.</Typography>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              fullWidth
              label="Enter New Password"
              variant="outlined"
              placeholder="Your Password"
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          name="passwordConfirmation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              fullWidth
              label="Confirm New Password"
              variant="outlined"
              placeholder="Your Password"
              error={Boolean(errors.passwordConfirmation)}
              helperText={errors.passwordConfirmation?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        {errors.root?.['serverError'].message && (
          <Typography variant="caption" color={(theme) => theme.palette.error.main}>
            {errors.root?.['serverError'].message}
          </Typography>
        )}

        <ButtonWithLoading
          isLoading={reset.isLoading}
          type="submit"
          variant="contained"
          size="large"
          fullWidth
        >
          Save New Password
        </ButtonWithLoading>
      </>
    </Box>
  )
}
