import { useState } from 'react'
import { ErrorOption, FieldPath, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, Paper, TextField, Typography } from '@mui/material'

import { DjangoApiError, isGenericError } from '@partnerslate/core/djangoValidation'
import { getReferenceData } from '@partnerslate/feature/central/api/parsers'
import { Select } from '@partnerslate/feature/central/components/Select'
import { ButtonWithLoading } from '@partnerslate/ui-components/lib/Button'

import { AllFormFields, extractBackendFormErrors } from '../api/parsers'
import { useRegister } from '../api/queries'
import { CompanyProfileForm, CompleteSignupForm } from '../domain/signup'
import * as instrumentation from '../instrumentation'
import { useFormContext } from '../SignupContext'
import { completeSignupResolver } from '../validations'
import { howDidYouHearAboutUsOptions } from './CompanyProfile'
import { SignupServerError } from './SignupServerError'
import { useRedirectToSignupFirstPage } from './useRedirect'

const fundingOptions = [
  { label: 'Self-funded', value: 'Self-funded' },
  {
    label: 'Bank line of credit / alternative financing',
    value: 'Bank line of credit / alternative financing',
  },
  { label: 'Angel investors', value: 'Angel investors' },
  { label: 'Venture backed', value: 'Venture backed' },
  { label: 'I need help with financing', value: 'I need help with financing' },
  { label: 'I am currently raising funding', value: 'I am currently raising funding' },
]
const productDevelopmentOptions = [
  {
    label: 'I have an idea that I need to develop',
    value: 'I have an idea that I need to develop',
  },
  { label: 'I have a recipe that I need to scale', value: 'I have a recipe that I need to scale' },
  {
    label: 'I have a commercial formula and am ready for a co-packer',
    value: 'I have a commercial formula and am ready for a co-packer',
  },
]
const initialRunSizeOptions = [
  { label: '<25,000 units', value: '<25,000 units' },
  { label: '25,000 - 50,000 units', value: '25,000 - 50,000 units' },
  { label: '>50,000 units', value: '>50,000 units' },
]

export default function ProfileCompleteScreen() {
  const { form, setCompleteSignupForm } = useFormContext()
  const [genericFormError, setGenericFormError] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<
    [FieldPath<AllFormFields>, ErrorOption][] | null
  >(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const registerService = useRegister()
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<CompleteSignupForm>({
    defaultValues: form.completeSignupForm,
    resolver: completeSignupResolver,
  })

  const isNotReadyForProduction = (
    companyProfileForm: CompanyProfileForm,
    completeSignupForm: CompleteSignupForm,
  ) => {
    const { brandStages } = getReferenceData()
    const { brandStage } = companyProfileForm
    const { initialRunSize } = completeSignupForm

    const selectedBrandStage = brandStages.find((stage) => stage.id === parseInt(brandStage))
    //  initialRunSizeOptions[0] is hard-coded above
    return (
      selectedBrandStage?.isTooEarlyStage === true &&
      initialRunSize === initialRunSizeOptions[0].value
    )
  }

  const companyType = form.accountTypeForm.companyType
  const isBrand = companyType === 'brand'
  useRedirectToSignupFirstPage(companyType)

  const onSubmit = (data: CompleteSignupForm) => {
    setIsLoading(true)
    instrumentation.fillInBrandDetails()

    setCompleteSignupForm(data)
    setGenericFormError(false)

    const formData = { ...form, completeSignupForm: data }

    if (
      isBrand &&
      isNotReadyForProduction(formData.companyProfileForm, formData.completeSignupForm)
    ) {
      navigate('/register/readiness')
    } else {
      registerService.mutate(formData, {
        onSuccess: (user) => {
          const goToLogout = () => window.location.replace('/logout')

          instrumentation.signupEarlyStageBrandQuestions(data)
          instrumentation.updateIntercomUser({
            name: `${user.firstName} ${user.lastName}`,
            email: user.emailAddress,
            userId: user.id,
            dateJoined: user.dateJoined,
          })
          instrumentation.signupCompleted(user, goToLogout)
        },
        onError: (e: DjangoApiError) => {
          if (isGenericError(e)) {
            setGenericFormError(true)
            return
          }

          setValidationErrors(extractBackendFormErrors(e.fieldErrors))
          setIsLoading(false)
        },
      })
    }
  }

  return (
    <Container>
      <Typography variant="h2" mt={6} textAlign="center">
        Complete your profile
      </Typography>

      <Grid container my={5} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper>
            <Box onSubmit={handleSubmit(onSubmit)} component="form" px={{ xs: 2, md: 8 }} py={3}>
              <Typography variant="h4" textAlign="center" mt={1}>
                Help us find you the right match
              </Typography>

              <Select
                error={errors.fundingState}
                label="How are you currently funding your business? *"
                name="fundingState"
                options={fundingOptions}
                register={register}
                watch={watch}
              />
              <Select
                error={errors.productDevelopment}
                label="Where are you currently in your product development? *"
                name="productDevelopment"
                options={productDevelopmentOptions}
                register={register}
                watch={watch}
              />
              <Select
                error={errors.initialRunSize}
                label="How many units will you produce in your initial product run? *"
                name="initialRunSize"
                options={initialRunSizeOptions}
                register={register}
                watch={watch}
              />
              <Select
                error={errors.howDidYouHearAboutUs}
                label="How did you hear about us? *"
                name="howDidYouHearAboutUs"
                options={howDidYouHearAboutUsOptions}
                register={register}
                watch={watch}
              />
              {watch('howDidYouHearAboutUs') === 'Other' ? (
                <TextField
                  {...register('howDidYouHearAboutUsOther')}
                  fullWidth
                  label="Other"
                  margin="normal"
                  InputLabelProps={{ shrink: !!watch('howDidYouHearAboutUsOther') }}
                />
              ) : null}

              <Box my={4}>
                <ButtonWithLoading
                  fullWidth
                  isLoading={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create account
                </ButtonWithLoading>
              </Box>
              <SignupServerError
                isGenericFormError={genericFormError}
                validationErrors={validationErrors}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
