import { Link } from '@mui/material'

export function TermsOfServiceLink() {
  return (
    <Link
      href="https://partnerslate.com/wp-content/uploads/2023/03/PartnerSlate-Terms-of-Service.pdf"
      target="_blank"
      underline="none"
    >
      Terms of Service
    </Link>
  )
}
