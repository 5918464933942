import { Typography } from '@mui/material'
import { differenceInDays } from 'date-fns'

import { UserAccount } from '..'
import { BaseBanner, CtaLinkButton } from './BaseBanner'
import * as instrumentation from './helpers/instrumentation'

type TrialBannerProps = {
  currentUser: UserAccount
  repoClickOrigin: 'monorepo' | 'main_repo'
}
export function TrialBanner({ currentUser, repoClickOrigin }: TrialBannerProps) {
  const monorepoRouter = repoClickOrigin === 'monorepo' ? 'router' : 'regular'

  const daysUntilTrialExpiry = currentUser.entitlements.trialExpiresAt
    ? differenceInDays(currentUser.entitlements.trialExpiresAt, new Date())
    : null

  if (daysUntilTrialExpiry === null) {
    return null
  }

  const bannerText =
    daysUntilTrialExpiry === 0
      ? 'Your full access trial ends today'
      : `Your full access trial ends in ${daysUntilTrialExpiry} days`

  return (
    <BaseBanner
      bannerBody={<Typography>{bannerText}</Typography>}
      cta={
        <CtaLinkButton
          text="Upgrade"
          url="/marketplace/coman-pricing"
          routerType={monorepoRouter}
          onClick={() => instrumentation.clickComanUpgrade('Trial Banner')}
        />
      }
    />
  )
}
