import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

import { sendMSAReminder } from './backendGateway'

export const ACCOUNT_QUERY_KEY = 'account'

export function useSendMSAReminder(): UseMutationResult<
  unknown,
  unknown,
  { readonly companySlug: string },
  unknown
> {
  const queryClient = useQueryClient()

  return useMutation(
    ({ companySlug }) => {
      return sendMSAReminder(companySlug)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(ACCOUNT_QUERY_KEY)
      },
    },
  )
}
