import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, Paper, TextField, Typography } from '@mui/material'

import { ButtonWithLoading } from '@partnerslate/ui-components'

import { CompleteComanDetailsForm } from '../domain/signup'
import * as instrumentation from '../instrumentation'
import { useFormContext } from '../SignupContext'
import { completeComanDetailsFormResolver } from '../validations'
import { useRedirectToSignupFirstPage } from './useRedirect'

export default function ComanDetailsScreen() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { form, setCompleteComanDetailsForm } = useFormContext()
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<CompleteComanDetailsForm>({
    defaultValues: form.completeComanDetailsForm,
    resolver: completeComanDetailsFormResolver,
  })

  const companyType = form.accountTypeForm.companyType
  useRedirectToSignupFirstPage(companyType)

  const onSubmit = (data: CompleteComanDetailsForm) => {
    setIsLoading(true)
    instrumentation.fillInComanCompanyDetails()

    setCompleteComanDetailsForm(data)

    navigate('/register/other-info')
  }

  const capabilitiesPlaceholderText =
    '- Products: cookies, enrobed cookies, vegan cookies, gluten free cookies, keto cookies, health cookies, breakfast cookies, stuffed breakfast bars, baked bars, raw bars.\n' +
    '- Equipment/Processing: dry blending, mixing, tunnel oven, Vemag extruder, enrobing, drizzling, metal detector, scale, VFFS, pouching machine\n' +
    '- Packaging: flow wrap, stand up gusset pouches'

  const matchPreferencesPlaceholderText =
    '- Capacity: Open capacity on all lines and ability to add shifts, MOQ 10,000 units per SKU, pilot runs available.\n' +
    '- Ideal brand qualities: start-ups and emerging brands; offer additional services for R&D, marketing, and logistics. Brand must have formula, we can assist with scale up.\n'
  return (
    <Container>
      <Grid container justifyContent="center" mt={6}>
        <Grid item xs={12} md={8} flexDirection="column">
          <Typography variant="h2" textAlign="center">
            Add your company profile
          </Typography>
          <Typography variant="h5" textAlign="center" pt={1} pb={2}>
            You are not required to answer any of these questions, however, your answers will
            improve the accuracy of the project matches we will automatically send to you.
          </Typography>
        </Grid>
      </Grid>
      <Grid container my={5} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper>
            <Box onSubmit={handleSubmit(onSubmit)} component="form" px={{ xs: 2, md: 8 }} py={3}>
              <Box pb={3}>
                <Typography>About us</Typography>
                <TextField
                  {...register('about')}
                  error={!!errors.about}
                  placeholder={'Please provide a brief description of your company here...\n'}
                  fullWidth
                  multiline
                  helperText={errors.about?.message ?? ''}
                  label=""
                  sx={{ mt: 0 }}
                />
              </Box>

              <Box pb={3}>
                <Typography>Services and capabilities (this will be public to brands)</Typography>
                <TextField
                  {...register('servicesAndCapabilities')}
                  error={!!errors.servicesAndCapabilities}
                  fullWidth
                  multiline
                  helperText={errors.servicesAndCapabilities?.message ?? ''}
                  label=""
                  placeholder={capabilitiesPlaceholderText}
                  sx={{ mt: 0 }}
                />
              </Box>

              <Typography>Tell us everything else (this will not be public to brands)</Typography>
              <TextField
                {...register('matchPreferences')}
                multiline
                error={!!errors.matchPreferences}
                fullWidth
                helperText={
                  errors.matchPreferences?.message ??
                  "This field is for you to put any other details that you want to share about your match preferences that you do not want brands to see. This information is private and is only read by PartnerSlate's AI algorithm to match you with projects"
                }
                label=""
                placeholder={matchPreferencesPlaceholderText}
                sx={{ mt: 0 }}
              />

              <Box my={4}>
                <ButtonWithLoading
                  fullWidth
                  isLoading={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Continue
                </ButtonWithLoading>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
