import { UseFormSetError } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { zod } from '@partnerslate/core'

export type UpdatePassword = z.infer<typeof updatePasswordSchema>
export const updatePasswordSchema = z
  .object({
    password: zod.psPassword(),
    passwordConfirmation: z.string().min(1, 'Please confirm password!'),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'Passwords should match!',
    path: ['passwordConfirmation'],
  })

export async function updatePassword(data: UpdatePassword & { token: string }) {
  const response = await fetch('/api/v1/accounts/reset-password/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password: data.password,
      token: data.token,
    }),
  })

  if (!response.ok) {
    const errorResponse = await response.json()
    throw new Error(errorResponse.error || 'An error occurred')
  }
}

export function useUpdatePassword(setError: UseFormSetError<UpdatePassword>) {
  const navigate = useNavigate()
  return useMutation(updatePassword, {
    onError: (error) => {
      if (error instanceof Error) {
        setError('root.serverError', {
          message: error.message,
        })
      }
    },
    onSuccess: () => {
      navigate('/')
    },
  })
}
