import { yupResolver } from '@hookform/resolvers/yup'
import { matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'

import {
  CompanyType,
  PASSWORD_COMPLEXITY_MESSAGE,
  PASSWORD_COMPLEXITY_REGEX,
} from '@partnerslate/feature/central/domain/userAccount'

import { checkExistingCompany, checkExistingEmail } from './api/backendGateway'

const YEAR_REGEX = /^(17\d{2}|18\d{2}|19\d{2}|20[0-2]\d)$/

const STRING = yup.string()
const REQUIRED_STRING = STRING.required('Required').test(
  'required',
  () => 'Required',
  (value) => value.trim().length > 0,
)

const POSITIVE_NUMBER = yup
  .number()
  .typeError('Please enter a valid number')
  .integer('Please enter a positive number')
  .positive('Please enter a positive number')
const REQUIRED_POSITIVE_NUMBER = POSITIVE_NUMBER.required('Required').required('Required')

const REQUIRED_AND_MAX_LENGTH_STRING = (label: string, max: number) =>
  REQUIRED_STRING.test({
    name: 'max',
    params: { label, max },
    message: '${label} must be less than ${max} characters',
    test: (value: string) => value.length <= max,
  })

const REQUIRED_STRING_ARRAY = yup
  .array(REQUIRED_STRING)
  .min(1, 'Select at least one option')
  .required()

const personalAccountSchema = yup.object({
  firstName: REQUIRED_AND_MAX_LENGTH_STRING('First Name', 32),
  lastName: REQUIRED_AND_MAX_LENGTH_STRING('Last Name', 32),
  jobTitle: STRING,
  email: REQUIRED_STRING.email('Invalid email').test(
    'invalid',
    'This email address is already associated with an account. Try logging in above.',
    async (value) => {
      const emailExists = await checkExistingEmail(value)
      return emailExists === false
    },
  ),
  password: REQUIRED_STRING.matches(PASSWORD_COMPLEXITY_REGEX, PASSWORD_COMPLEXITY_MESSAGE),
  confirmPassword: REQUIRED_STRING.oneOf([yup.ref('password')], 'Your passwords do not match'),
  acceptedTos: yup.boolean().oneOf([true]).required(),
})
export const personalAccountResolver = yupResolver(personalAccountSchema)

const manufacturerTypeSchema = yup.object({
  manufacturerType: yup
    .array(REQUIRED_STRING)
    .min(1, 'Select at least one type of service')
    .required(),
})
export const manufacturerTypeResolver = yupResolver(manufacturerTypeSchema)

export const getCompanyProfileResolver = (companyType: CompanyType | '') => {
  let companyProfileSchema = {
    companyName: REQUIRED_STRING.test('invalid', async (value) => {
      const companyExists = await checkExistingCompany(value)
      return companyExists === false
    }),
    website: STRING,
    address: yup.object({
      address1: REQUIRED_STRING,
      address2: STRING,
      city: REQUIRED_STRING,
      state: REQUIRED_AND_MAX_LENGTH_STRING('State', 10),
      postalCode: REQUIRED_AND_MAX_LENGTH_STRING('Zip Code', 9),
    }),
    companyPhone: REQUIRED_STRING.test(
      'invalid',
      () => 'Invalid phone number',
      (value) => {
        if (!value) {
          return true
        }
        return matchIsValidTel(value)
      },
    ),
    yearFounded: STRING,
    servicesOffered: yup.array(STRING),
    brandStage: STRING,
    distributionType: yup.array(STRING),
    howDidYouHearAboutUs: STRING,
    comanRevenue: STRING,
  }

  if (companyType === 'brand') {
    companyProfileSchema = {
      ...companyProfileSchema,
      yearFounded: REQUIRED_STRING.matches(YEAR_REGEX, 'Invalid year'),
      brandStage: REQUIRED_STRING,
      distributionType: REQUIRED_STRING_ARRAY,
    }
  } else if (companyType === 'manufacturer') {
    companyProfileSchema = {
      ...companyProfileSchema,
      howDidYouHearAboutUs: REQUIRED_STRING,
      comanRevenue: REQUIRED_STRING,
    }
  }

  return yupResolver(yup.object(companyProfileSchema))
}

const completeSignupSchema = yup.object({
  fundingState: REQUIRED_STRING,
  productDevelopment: REQUIRED_STRING,
  initialRunSize: REQUIRED_STRING,
  howDidYouHearAboutUs: REQUIRED_STRING,
})
export const completeSignupResolver = yupResolver(completeSignupSchema)

export const completeComanProfileResolver = () =>
  yupResolver(
    yup.object({
      productCategories: REQUIRED_STRING_ARRAY,
      packagingFormats: REQUIRED_STRING_ARRAY,
      facilityLocations: REQUIRED_STRING_ARRAY,
      numberOfFacilityLocations: REQUIRED_POSITIVE_NUMBER,
      processingCapabilities: REQUIRED_STRING_ARRAY,
      runSizes: yup.array(STRING),
      matchPreferences: STRING,
      comanPreviousBrandSizes: REQUIRED_STRING_ARRAY,
    }),
  )

export const diversityResolver = yupResolver(
  yup.object({
    background: yup.array(STRING),
    socialImpact: yup.array(STRING),
    missionSummary: STRING,
  }),
)

export const completeComanDetailsFormResolver = yupResolver(
  yup.object({
    about: STRING,
    servicesAndCapabilities: STRING,
    matchPreferences: STRING,
  }),
)
