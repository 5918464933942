import { createContext, PropsWithChildren, useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { queryClient } from '@partnerslate/core/api/queryClient'
import { identify } from '@partnerslate/core/instrumentation'

import { getAccount, getFullAccount } from '../api/backendGateway'
import { UserAccount } from '../domain/userAccount'

interface ContextValue {
  userAccount: UserAccount | null
  refreshAccountInfo?: () => Promise<void>
  isLoading: boolean
}

const IdentityContext = createContext<ContextValue>({ userAccount: null, isLoading: false })

export const ACCOUNT_QUERY_KEY = 'account'
export const FULL_ACCOUNT_QUERY_KEY = 'full-account'

export function IdentityProvider({ children }: PropsWithChildren) {
  const { data, isLoading } = useQueryAuthenticatedUser()

  return (
    <IdentityContext.Provider value={{ userAccount: data || null, isLoading }}>
      {children}
    </IdentityContext.Provider>
  )
}

// hook which lets any component access the currently authenticated user
export function useCurrentUser(): UserAccount | null {
  return useIdentity().currentUser
}
export function useIdentity() {
  const contextValue = useContext(IdentityContext)
  const qc = useQueryClient()

  function refreshAccountInfo() {
    return qc.invalidateQueries(ACCOUNT_QUERY_KEY)
  }

  function bounceToLoginIfUnauthenticated() {
    if (!contextValue.isLoading && !contextValue.userAccount) {
      window.location.replace(
        `/?nextUrl=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`,
      )
    }
  }

  return {
    currentUser: contextValue.userAccount,
    isLoading: contextValue.isLoading,
    refreshAccountInfo: refreshAccountInfo,
    bounceToLoginIfUnauthenticated,

    // for use where you always want the latest and freshest User
    // in most areas we can use the User from context at the top of the app where
    // calling useIdentity() will not trigger a refetch
    useLatestUser: useQueryAuthenticatedUser,
  }
}

// internal hook which actually fetches account info via API (and caches it)
function useQueryAuthenticatedUser() {
  return useQuery([ACCOUNT_QUERY_KEY], getAccount, {
    onSuccess: (user: UserAccount | null) => {
      if (user) {
        identify({ userId: user.id, emailAddress: user.emailAddress, companyId: user.company.id })
      }
    },
  })
}

export function useQueryAuthenticatedFullUser() {
  return useQuery([FULL_ACCOUNT_QUERY_KEY], getFullAccount, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  })
}

// For functions outside of React where we cannot use hooks
export function getCurrentUser() {
  return queryClient.getQueryData<UserAccount>(ACCOUNT_QUERY_KEY)
}
