import { Avatar, Box, Button, Container, Grid, Paper, Typography } from '@mui/material'

import AccountManagerHeadshot from '../assets/images/account-manager.jpg'

export function BookACall() {
  return (
    <Container>
      <Typography variant="h2" mt={6} textAlign="center">
        Congrats on Signing Up
      </Typography>
      <Grid container my={5} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper>
            <Box px={{ xs: 2, md: 8 }} py={3}>
              <Box pb={3} display="flex" justifyContent="center" alignItems="center" gap={1.5}>
                <Typography variant="h3" textAlign="center" pt={1} pb={2}>
                  Meet your Account Manager!
                </Typography>
                <Avatar
                  alt="Account Manager headshot"
                  src={AccountManagerHeadshot}
                  sx={{ width: 64, height: 64 }}
                />
              </Box>
              <ul>
                <Box component="li" pb={1}>
                  <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>
                    Optimize Your Profile for Better Leads
                  </Typography>
                  <ul>
                    <li>
                      (Discover how to fine-tune your profile to stand out and attract brands
                      looking for your unique capabilities)
                    </li>
                  </ul>
                </Box>
                <Box component="li" pb={1}>
                  <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>
                    Unlock Exclusive Access to Retailers and Big CPG Brands
                  </Typography>
                  <ul>
                    <li>
                      (Learn how our RFI process will connect you to major industry players and
                      elevate your business)
                    </li>
                  </ul>
                </Box>
                <Box component="li" pb={1}>
                  <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>
                    Optimize Your Profile for Better Leads
                  </Typography>
                  <ul>
                    <li>(Learn how to use our marketplace and how to gain new customers)</li>
                  </ul>
                </Box>
              </ul>
              <Box display="flex" gap={2} justifyContent="flex-end">
                <Button href="/logout" variant="outlined" sx={{ width: '100px' }}>
                  Skip
                </Button>

                <Button
                  component="a"
                  href="/logout"
                  onClick={(e) => {
                    // we keep href to show a preview when the user hovers but because we want to open a tab
                    // to calendly while also redirecting to /logout, we have this onClick
                    e.preventDefault()
                    window.open('https://calendly.com/tea-partnerslate/co-man-onboarding', '_blank')
                    window.location.href = '/logout'
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained"
                  sx={{ width: '100px' }}
                >
                  Schedule
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
