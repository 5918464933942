import React from 'react'
import { Control, Controller, FieldError, FieldValues, Merge, Path } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import { default as MuiTextField } from '@mui/material/TextField'

type FieldOption<VType extends string = string> = {
  value: VType
  label: string
}
type ErrorInput = Merge<FieldError, (FieldError | undefined)[]> | undefined

type MultiSelectProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  options: FieldOption[]
  error: ErrorInput
  onFocus?: (focusedField: Path<TFieldValues>) => void
  label?: string
  placeholder?: string
}

export function MultiSelect<TFieldValues extends FieldValues>({
  name,
  control,
  options,
  placeholder,
  onFocus = () => void 0,
  error,
  label,
}: MultiSelectProps<TFieldValues>) {
  function handleFocus(e: React.FocusEvent | undefined): void {
    onFocus(name)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const selectedValues: string[] = field.value ?? []
        // map to FieldOptions
        const initialSelectedFieldOptions = selectedValues.map((value) =>
          options.find((fo: FieldOption) => fo.value === value),
        )

        // the dropdown values were updated so we have outdated/older values saved in the db
        // we need to do this to clean up the outdated selections from breaking the multiselect
        const selectedFieldOptions = initialSelectedFieldOptions.filter(
          (fo) => fo !== undefined,
        ) as FieldOption[]

        const placeholderIfEmpty = selectedFieldOptions.length ? undefined : placeholder

        return (
          <Autocomplete
            id={`mui-component-select-${name}`}
            options={options}
            value={selectedFieldOptions}
            onChange={(_, fieldOptions: FieldOption[]) => {
              const ids = fieldOptions.map((fo) => fo.value)
              field.onChange(ids)
            }}
            onFocus={handleFocus}
            ChipProps={{ color: 'primary' }}
            renderInput={(params) => {
              return (
                <MuiTextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  label={label}
                  placeholder={placeholderIfEmpty}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )
            }}
            multiple
          />
        )
      }}
    />
  )
}
