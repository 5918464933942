import { ErrorOption, FieldPath } from 'react-hook-form'
import { Box, Typography } from '@mui/material'

import { EmailAnchor } from '@partnerslate/ui-components'

import { AllFormFields } from '../api/parsers'

type Props = {
  isGenericFormError: boolean
  validationErrors: [FieldPath<AllFormFields>, ErrorOption][] | null
}

export function SignupServerError({ isGenericFormError, validationErrors }: Props) {
  if (isGenericFormError) {
    return (
      <Box my={4}>
        <Typography fontSize={20} textAlign="center" color="error">
          We could not complete your signup request. Please contact{' '}
          <EmailAnchor email="support@partnerslate.com" />.
        </Typography>
      </Box>
    )
  }

  if (validationErrors) {
    return (
      <Box my={4}>
        <Typography color="error">
          We could not complete your signup request. The following fields had errors:
        </Typography>
        <ul>
          {validationErrors.map(([field, error]) => (
            <li>
              <Typography color="error" key={field}>
                <span style={{ fontWeight: 'bold' }}>{getFriendlyNameForFormField(field)}</span> :{' '}
                {error.message}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    )
  }
  return null
}

/**
  Will take a camelCase form variable and turn it into an easy to read word(s). It
  will ignore anything after a dot. i.e. "someVar.1" = "Some Var"
 */
function getFriendlyNameForFormField(camelCaseFormVariable: string) {
  const trimmedWord = camelCaseFormVariable.split('.')[0]
  return trimmedWord.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
}
