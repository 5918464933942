import { FieldError, UseFormRegister, UseFormWatch } from 'react-hook-form'
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material'

export interface SelectOption {
  label: string
  value: string | number
}
interface SelectProps {
  error?: FieldError | undefined
  label?: string
  name: string
  options: SelectOption[]
  register: UseFormRegister<any>
  watch: UseFormWatch<any>
}
export function Select({ error, label, name, options, register, watch }: SelectProps) {
  return (
    <FormControl fullWidth margin="normal" error={!!error}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <MuiSelect {...register(name)} label={label} defaultValue={watch(name)} fullWidth>
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText>{error?.message ?? ''}</FormHelperText>
    </FormControl>
  )
}

export function MultipleSelect({ error, label, name, options, register, watch }: SelectProps) {
  const getChipLabel = (options: SelectOption[], value: number) =>
    options.find((o) => o.value === value)?.label

  return (
    <div>
      <FormControl fullWidth margin="normal" error={!!error}>
        <InputLabel>{label}</InputLabel>
        <MuiSelect
          {...register(name)}
          defaultValue={watch(name)}
          label={label}
          fullWidth
          multiple
          renderValue={(selected: string[]) => {
            const selectedValues = selected.map(Number)
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedValues.map((value) => (
                  <Chip key={value} label={getChipLabel(options, value)} />
                ))}
              </Box>
            )
          }}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </MuiSelect>
        <FormHelperText>{error?.message ?? ''}</FormHelperText>
      </FormControl>
    </div>
  )
}
