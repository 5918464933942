import { createContext, PropsWithChildren, useContext, useState } from 'react'

import {
  AccountTypeForm,
  CompanyProfileForm,
  CompleteComanDetailsForm,
  CompleteComanProfileForm,
  CompleteSignupForm,
  DiversityForm,
  ManufacturerTypeForm,
  PersonalAccountForm,
  SignupForm,
} from './domain/signup'

export const SIGNUP_FORM_INITIAL_STATE: SignupForm = {
  accountTypeForm: {
    companyType: '',
  },
  manufacturerTypeForm: {
    manufacturerType: [],
  },
  personalAccountForm: {
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptedTos: false,
  },
  companyProfileForm: {
    companyName: '',
    website: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      countryCode: '',
    },
    companyPhone: '',
    yearFounded: '',
    servicesOffered: [],
    brandStage: '',
    comanRevenue: '',
    distributionType: [],
    howDidYouHearAboutUs: '',
    howDidYouHearAboutUsOther: '',
  },
  completeComanProfileForm: {
    productCategories: [],
    packagingFormats: [],
    processingCapabilities: [],
    productionRunSizes: [],
    facilityLocations: [],
    numberOfFacilityLocations: '',
    comanPreviousBrandSizes: [],
  },
  completeSignupForm: {
    fundingState: '',
    productDevelopment: '',
    initialRunSize: '',
    howDidYouHearAboutUs: '',
    howDidYouHearAboutUsOther: '',
  },
  diversityForm: {
    background: [],
    socialImpact: [],
    missionSummary: '',
  },
  completeComanDetailsForm: {
    about: '',
    servicesAndCapabilities: '',
    matchPreferences: '',
  },
}

interface InitialFormContext {
  form: SignupForm
  setAccountTypeForm: (formData: AccountTypeForm) => void
  setManufacturerTypeForm: (formData: ManufacturerTypeForm) => void
  setPersonalAccountForm: (formData: PersonalAccountForm) => void
  setCompanyProfileForm: (formData: CompanyProfileForm) => void
  setCompleteComanProfileForm: (formData: CompleteComanProfileForm) => void
  setCompleteSignupForm: (formData: CompleteSignupForm) => void
  setCompleteComanDetailsForm: (formData: CompleteComanDetailsForm) => void
  setDiversityForm: (formData: DiversityForm) => void
  resetForm: () => void
}

const FormContext = createContext<InitialFormContext>({
  form: SIGNUP_FORM_INITIAL_STATE,
  setAccountTypeForm: () => {},
  setManufacturerTypeForm: () => {},
  setPersonalAccountForm: () => {},
  setCompanyProfileForm: () => {},
  setCompleteComanProfileForm: () => {},
  setCompleteSignupForm: () => {},
  setCompleteComanDetailsForm: () => {},
  setDiversityForm: () => {},
  resetForm: () => {},
})

export const FormProvider = ({ children }: PropsWithChildren) => {
  const [form, setForm] = useState<SignupForm>(SIGNUP_FORM_INITIAL_STATE)

  const value = {
    form: form,
    setAccountTypeForm: (formData: AccountTypeForm) => {
      return setForm((prevForm) => {
        return { ...prevForm, accountTypeForm: formData }
      })
    },
    setManufacturerTypeForm: (formData: ManufacturerTypeForm) =>
      setForm((prevForm) => {
        return { ...prevForm, manufacturerTypeForm: formData }
      }),

    setPersonalAccountForm: (formData: PersonalAccountForm) =>
      setForm((prevForm) => ({ ...prevForm, personalAccountForm: formData })),
    setCompanyProfileForm: (formData: CompanyProfileForm) =>
      setForm({ ...form, companyProfileForm: formData }),
    setCompleteComanProfileForm: (formData: CompleteComanProfileForm) =>
      setForm({ ...form, completeComanProfileForm: formData }),
    setCompleteSignupForm: (formData: CompleteSignupForm) =>
      setForm({ ...form, completeSignupForm: formData }),
    setDiversityForm: (formData: DiversityForm) => setForm({ ...form, diversityForm: formData }),
    setCompleteComanDetailsForm: (formData: CompleteComanDetailsForm) =>
      setForm({ ...form, completeComanDetailsForm: formData }),

    resetForm: () => setForm(SIGNUP_FORM_INITIAL_STATE),
  }

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

export const useFormContext = () => useContext(FormContext)
