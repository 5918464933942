import { useNavigate } from 'react-router-dom'

import { useRouteInstrumentation } from '@partnerslate/feature/central/commonInstrumentation'
import { FormProvider } from '@partnerslate/segment/signup/SignupContext'
import { Navbar } from '@partnerslate/ui-components/lib/Navbar'

import AppRoutes from './Routes'

export function App() {
  useRouteInstrumentation()
  const navigate = useNavigate()

  function router(to: string) {
    navigate(to)
  }

  return (
    <>
      <Navbar origin="monorepo" router={router} />
      <FormProvider>
        <AppRoutes />
      </FormProvider>
    </>
  )
}
