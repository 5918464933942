import { instrumentation } from '@partnerslate/core'

export const msaTermsReminderSent = (from: string): void => {
  instrumentation.mixpanelTrack(`MSA Terms Reminder Sent from ${from}`)
}

export const clickComanUpgrade = (clickOrigin: string): Promise<void> => {
  return instrumentation.mixpanelTrack(
    'Clicked Coman Upgrade',
    {
      'Click Origin': clickOrigin,
    },
    {
      // We add this property to ensure the event is sent before navigating away from the page
      send_immediately: true,
    },
  )
}
