import './polyfills'
import { PropsWithChildren, StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import { SplitFactory } from '@splitsoftware/splitio-react'

import { instrumentation, setAppMetadata, updateStoredAuthToken } from '@partnerslate/core'
import { queryClient } from '@partnerslate/core/api/queryClient'
import config, { retrieveHijackAuthToken } from '@partnerslate/feature/central/config'
import theme from '@partnerslate/ui-components/theme'

import { App } from './App'

Sentry.init(
  config.sentryOptions({
    dsn: 'https://63678240d10644f499bd257525558077@o91293.ingest.sentry.io/4505347506110464',
    envPrefix: 'prelogin-frontend',
  }),
)
if (config.mixpanelToken) {
  instrumentation.initMixpanel(config.mixpanelToken)
}
if (config.customerIoWriteKey) {
  instrumentation.initCustomerIO(config.customerIoWriteKey)
}

setAppMetadata({ appName: 'prelogin-frontend', buildstamp: config.buildstamp })

// Adopt any impersonation auth token that was passed in from the host page
const hijackAuthToken = retrieveHijackAuthToken()
if (hijackAuthToken) {
  updateStoredAuthToken(hijackAuthToken)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
)

function AppProviders({ children }: PropsWithChildren) {
  return (
    <BrowserRouter>
      <SplitFactory config={config.splitConfig}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={createTheme(theme)}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </QueryClientProvider>
      </SplitFactory>
    </BrowserRouter>
  )
}
