import { useState } from 'react'
import { AddBox as AddBoxIcon } from '@mui/icons-material'
import { Box, Drawer, Link } from '@mui/material'

import { AbridgedProject } from '..'
import MobileMenuHamburger from '../MobileMenuHamburger'
import { NavbarDropdown } from '../NavbarDropdown'
import { NavbarLink, NavbarLinkType } from '../NavbarLink'

type ProjecstsAndNewLinks = {
  linkType: NavbarLinkType
  onClose?: () => void
  projects: AbridgedProject[]
}
function ProjectsAndNewLinks({ projects, linkType, onClose = () => {} }: ProjecstsAndNewLinks) {
  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'contents' },
        flexDirection: { xs: 'column' },
        py: { xs: 2 },
      }}
    >
      {projects.map((p) => {
        return (
          <NavbarLink
            key={p.id}
            text={p.name}
            // TODO: use new Routes.
            url={p.isPaid ? `/project/${p.id}/matches` : `/projects/${p.id}/landing`}
            linkType={linkType}
            onClose={onClose}
            isUppercase={false}
          />
        )
      })}
      <NavbarLink
        text="New Project"
        icon={
          <AddBoxIcon fontSize="small" sx={{ color: 'primary.contrast', alignSelf: 'center' }} />
        }
        // TODO: use new Routes
        url="/projects/new"
        linkType={linkType}
        onClose={onClose}
        isUppercase={false}
      />
    </Box>
  )
}

export function ProjectsDropdown({
  linkType,
  projects,
}: {
  linkType: NavbarLinkType
  projects: AbridgedProject[]
}) {
  return (
    <NavbarDropdown label="Projects">
      {({ onClose }) => (
        <ProjectsAndNewLinks linkType={linkType} onClose={onClose} projects={projects} />
      )}
    </NavbarDropdown>
  )
}

export function MobileProjectsDropdown({
  linkType,
  onMainMobileMenuClose,
  projects,
}: {
  linkType: NavbarLinkType
  onMainMobileMenuClose: () => void
  projects: AbridgedProject[]
}) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Link
        id="mobile-nav-toggle-projects"
        onClick={() => setIsOpen(true)}
        color="primary.contrast"
        fontSize={18}
        fontWeight={600}
        letterSpacing={1.85}
        margin={0}
        paddingY={2.25}
        paddingLeft={4}
        textTransform="uppercase"
        sx={{ textDecoration: 'none', '&:hover': { cursor: 'pointer' } }}
      >
        Projects
      </Link>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isOpen}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: '100vw', boxSizing: 'border-box' },
        }}
      >
        <Box bgcolor="primary.dark">
          <Box
            borderBottom={0.5}
            borderColor="primary.contrast"
            display="flex"
            position="sticky"
            top={0}
            justifyContent="flex-end"
            bgcolor="primary.dark"
            sx={{
              height: '56px',
              width: '100%',
              zIndex: 4,
            }}
          >
            <Box
              onClick={() => setIsOpen(false)}
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              height={isOpen ? 28 : 24}
              right={24}
              position="absolute"
              top={16}
              width={28}
              sx={{
                cursor: 'pointer',
                '&:hover': isOpen
                  ? {}
                  : {
                      top: 12,
                      height: 32,
                    },
              }}
            >
              <MobileMenuHamburger isOpen={isOpen} />
            </Box>
          </Box>
          <ProjectsAndNewLinks
            linkType={linkType}
            onClose={onMainMobileMenuClose}
            projects={projects}
          />
        </Box>
      </Drawer>
    </>
  )
}
