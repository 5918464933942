import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { mixpanelTrackPage } from '@partnerslate/core/instrumentation'

export const useRouteInstrumentation = (): void => {
  const location = useLocation()

  useEffect(() => {
    mixpanelTrackPage(location.pathname)
  }, [location.pathname])
}
