import { useEffect, useState } from 'react'
import { ErrorOption, FieldPath, useForm } from 'react-hook-form'
import { Box, Button, Container, Grid, Link, Paper, Typography } from '@mui/material'

import { DjangoApiError, isGenericError } from '@partnerslate/core/djangoValidation'
import { ButtonWithLoading } from '@partnerslate/ui-components/lib/Button'

import { AllFormFields, extractBackendFormErrors } from '../api/parsers'
import { useRegister } from '../api/queries'
import { CompleteSignupForm } from '../domain/signup'
import * as instrumentation from '../instrumentation'
import { useFormContext } from '../SignupContext'
import { SignupServerError } from './SignupServerError'

const PARTNERSLATE_ACADEMY_URL = 'https://academy.partnerslate.com/'

export default function ConfirmReadiness() {
  const { form } = useFormContext()
  const [genericFormError, setGenericFormError] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<
    [FieldPath<AllFormFields>, ErrorOption][] | null
  >(null)

  const { handleSubmit } = useForm<CompleteSignupForm>()
  const registerService = useRegister()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    instrumentation.signupReadinessWarning(form.personalAccountForm.email)
  }, [form.personalAccountForm.email])

  const onSubmit = () => {
    setIsLoading(true)

    registerService.mutate(form, {
      onSuccess: (user) => {
        const goToLogout = () => window.location.replace('/logout')

        instrumentation.updateIntercomUser({
          name: `${user.firstName} ${user.lastName}`,
          email: user.emailAddress,
          userId: user.id,
          dateJoined: user.dateJoined,
        })
        instrumentation.signupCompleted(user, goToLogout, true)
      },
      onError: (e: DjangoApiError) => {
        if (isGenericError(e)) {
          setGenericFormError(true)
          return
        }

        setValidationErrors(extractBackendFormErrors(e.fieldErrors))
        setIsLoading(false)
      },
    })
  }

  return (
    <Container>
      <Typography variant="h2" mt={6} textAlign="center">
        You may not be ready for a manufacturer
      </Typography>

      <Grid container mt={5} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper>
            <Box px={{ xs: 2, md: 8 }} py={3}>
              <Typography variant="body1" textAlign="left" mt={1}>
                Most co-manufacturers have minimum thresholds to consider before having a serious
                conversation with a brand. A company needs to meet at least one of these two
                criteria:
              </Typography>
              <ul>
                <li>
                  <strong>Successful Product in Market:</strong> You're a brand with a proven record
                  of at least $100,000 in current annual sales.
                </li>
                <li>
                  <strong>Ready for Manufacturing:</strong> Your initial run size is over 25,000
                  units.
                </li>
              </ul>
              <Typography variant="body1" textAlign="left" mt={1}>
                Visit{' '}
                <Link
                  href={PARTNERSLATE_ACADEMY_URL}
                  target="_blank"
                  sx={{ textDecoration: 'none' }}
                >
                  PartnerSlate Academy
                </Link>
                , our comprehensive library on how to develop a recipe, find a commercial kitchen,
                and scale your business.
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid container my={2} direction={'column'} alignItems={'center'}>
        <Box mb={1}>
          <Button variant="contained" href={PARTNERSLATE_ACADEMY_URL} target={'_blank'}>
            Visit PartnerSlate Academy
          </Button>
        </Box>
        <Box onSubmit={handleSubmit(onSubmit)} component="form" px={{ xs: 2, md: 8 }}>
          <ButtonWithLoading isLoading={isLoading} type="submit">
            Create an account anyway
          </ButtonWithLoading>
        </Box>
        <SignupServerError
          isGenericFormError={genericFormError}
          validationErrors={validationErrors}
        />
      </Grid>
    </Container>
  )
}
