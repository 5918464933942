import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Typography,
} from '@mui/material'

import { getReferenceData } from '@partnerslate/feature/central/api/parsers'
import { ManufacturerType } from '@partnerslate/feature/central/domain/referenceData'

import { ManufacturerTypeForm } from '../domain/signup'
import * as instrumentation from '../instrumentation'
import { useFormContext } from '../SignupContext'
import { manufacturerTypeResolver } from '../validations'
import { useRedirectToSignupFirstPage } from './useRedirect'

export default function ManufacturerTypeScreen() {
  const navigate = useNavigate()
  const { form, setManufacturerTypeForm } = useFormContext()
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<ManufacturerTypeForm>({
    defaultValues: form.manufacturerTypeForm,
    resolver: manufacturerTypeResolver,
  })

  const companyType = form.accountTypeForm.companyType
  useRedirectToSignupFirstPage(companyType)

  const onSubmit = (data: ManufacturerTypeForm) => {
    setManufacturerTypeForm(data)
    instrumentation.chooseManufacturerType()

    navigate('/register/account')
  }

  const { manufacturerTypes } = getReferenceData()

  return (
    <Container>
      <Typography variant="h2" mt={6} textAlign="center">
        What Type of Services Do You Offer? (Select All That Apply)
      </Typography>

      <Grid container my={5} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper>
            <Box onSubmit={handleSubmit(onSubmit)} component="form" px={{ xs: 2, md: 8 }} py={3}>
              <Typography variant="h4" textAlign="center">
                It's time to make great connections in the CPG industry.
              </Typography>

              {manufacturerTypes.map((manufacturerType) => {
                const isChecked = watch('manufacturerType').includes(manufacturerType.id.toString())

                return (
                  <Card
                    key={manufacturerType.id}
                    sx={(theme) => ({
                      my: 3,
                      border: isChecked ? `2px solid ${theme.palette.primary.main}` : '',
                    })}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox {...register('manufacturerType')} defaultChecked={isChecked} />
                      }
                      label={<CheckboxLabel manufacturerType={manufacturerType} />}
                      value={manufacturerType.id}
                      sx={{ px: 3, py: 2 }}
                    />
                  </Card>
                )
              })}

              {errors.manufacturerType?.message ? (
                <FormHelperText error margin="dense">
                  {errors.manufacturerType.message}
                </FormHelperText>
              ) : null}

              <Box my={4}>
                <Button type="submit" variant="contained" fullWidth size="large">
                  Continue
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

type CheckboxLabelProps = {
  manufacturerType: ManufacturerType
}
function CheckboxLabel({ manufacturerType }: CheckboxLabelProps) {
  return (
    <Box>
      <Typography fontWeight={600}>{manufacturerType.label}</Typography>
      <Typography>{manufacturerType.subtitle}</Typography>
    </Box>
  )
}
