import { Route, Routes } from 'react-router-dom'

import { loginRouteSet } from '@partnerslate/segment/login/routeSet'
import { signupRouteSet } from '@partnerslate/segment/signup/routeSet'
import ErrorPageWithImage from '@partnerslate/ui-components/lib/ErrorPageWithImage'

export default function AppRoutes() {
  const notFound = <Route key="*" path="*" element={<ErrorPageWithImage />} />
  const fullRouteSet = [...loginRouteSet(), ...signupRouteSet(), notFound]

  return <Routes>{fullRouteSet}</Routes>
}
