import { deepOrange, green, grey, orange, purple, teal } from '@mui/material/colors'

const theme = {
  palette: {
    primary: {
      main: 'rgba(16, 122, 102, 1)',
      mainlightestOpacity: 'rgba(16, 122, 102, .1)',
      dark: 'rgba(17, 24, 39, 1)',
      light: 'rgba(165, 225, 213, 1)',
      contrast: 'rgba(255,255,255,1)',
    },
    secondary: {
      main: 'rgba(243, 134, 64, 1)',
      light: 'rgba(255, 230, 214, 1)',
    },
    error: {
      main: 'rgba(211, 47, 47, 1)',
      light: 'rgba(239, 83, 80, 1)',
    },
    warning: {
      main: 'rgba(237, 108, 2, 1)',
    },
    info: {
      main: 'rgba(2, 136, 209, 1)',
    },
    success: {
      main: 'rgba(46, 125, 50, 1)',
    },
    text: {
      primary: 'rgba(17, 24, 39, 1)',
      secondary: 'rgba(104, 107, 107, 1)',
      tertiary: 'rgba(138, 138, 138, 1)',
      disabled: 'rgba(189, 189, 189, 0.38)',
    },
    background: {
      default: 'rgba(245, 246, 250, 1)',
      lightGrey: grey[100],
      table: 'rgba(251, 252, 253, 1)',
      light: 'rgba(236, 244, 243, 1)',
      lightPinkish: 'rgba(255, 248, 245,1)',
    },
    action: {
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
    },
    border: {
      light: 'rgba(0,0,0,0.12)',
      darkPinkish: 'rgb(255, 193, 154)',
    },
    other: {
      divider: 'rgba(0, 0, 0, 0.12)',
      outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      standardInputLine: 'rgba(0, 0, 0, 0.42)',
    },
    accordion: {
      background: {
        pending: 'rgba(255, 167, 38, 1)',
        inactive: grey[400],
      },
    },
    chip: {
      text: {
        new: deepOrange.A200,
        formulation: green[800],
        pending: teal[500],
        brandNotInterested: grey[600],
        notInterested: grey[800],
        viewed: purple.A200,
        active: green.A700,
      },
      icon: {
        new: deepOrange.A200,
        formulation: green[800],
        pending: teal[500],
        brandNotInterested: grey[600],
        notInterested: grey[800],
        viewed: purple.A200,
        active: green.A700,
      },
      background: {
        new: deepOrange[50],
        formulation: green[50],
        pending: teal[50],
        brandNotInterested: grey[200],
        notInterested: grey[300],
        viewed: purple[50],
        active: green[50],
      },
    },
    avatar: {
      background: {
        random1: orange[300],
        random2: 'aliased-below',
        random3: teal[300],
        random4: 'rgba(0, 207, 148, 1)',
        random5: 'aliased-below',
      },
    },
    projectCreation: {
      sidebar: {
        background: 'rgba(255, 246, 240, 1)',
        altBackground: 'rgba(237, 244, 243, 1)',
      },
    },
    profile: {
      random1: orange[200],
      random2: teal[100],
      random3: green[100],
      random4: purple[50],
      random5: deepOrange[50],
    },
  },
  typography: {
    fontWeight: {
      normal: 400,
      semibold: 600,
      bold: 800,
    },
    fontFamily: 'Source Sans Pro',
    h1: {
      fontWeight: 400,
      fontSize: '36px',
      lineHeight: '42px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: 400,
      fontSize: '30px',
      lineHeight: '35px',
      letterSpacing: '0px',
    },
    h3: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '29.64px',
      letterSpacing: '.25px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '26.68px',
      letterSpacing: '0px',
    },
    h5: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '28.8px',
      letterSpacing: '.15px',
    },
    h6: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '28.8px',
      letterSpacing: '.15px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '.40px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '28px',
      letterSpacing: '.15px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '.1px',
    },
    overline: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '48px',
      letterSpacing: '1px',
    },
    buttonLarge: {
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '26px',
      letterSpacing: '.46px',
    },
    buttonMedium: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '.4px',
    },
    buttonSmall: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '.46px',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiContainer: {
      styleOverrides: {
        // TODO: have this apply only when we use maxWidth="xl" prop
        root: {
          // use default of 16px at theme.xs
          // use default of 24px at theme.sm

          // equivalent to theme.md
          '@media (min-width: 900px)': {
            paddingRight: '32px',
            paddingLeft: '32px',
          },
          // equivalent to theme.lg
          '@media (min-width: 1200px)': {
            paddingRight: '64px',
            paddingLeft: '64px',
          },
          // equivalent to theme.xl + previous paddings
          // let maxWidth of XL take over when there is enough space
          '@media (min-width: calc(1536px + 64px + 64px))': {
            paddingRight: '0px',
            paddingLeft: '0px',
          },
        },
      },
    },
  },
}

// If we want to not use breakpoints in our theme, we'd need to do theme composition:
// https://mui.com/material-ui/customization/theming/#api
// https://mui.com/material-ui/customization/theming/#typescript

// Theme aliases
theme.palette.avatar.background.random2 = theme.palette.secondary.main
theme.palette.avatar.background.random5 = theme.palette.primary.main

export default theme

declare module '@mui/material/styles' {
  interface Palette {
    other: {
      divider: string
    }
    chip: {
      text: {
        new: string
        pending: string
        brandNotInterested: string
        notInterested: string
        viewed: string
        active: string
      }
      icon: {
        new: string
        pending: string
        brandNotInterested: string
        notInterested: string
        viewed: string
        active: string
      }
      background: {
        new: string
        pending: string
        brandNotInterested: string
        notInterested: string
        viewed: string
        active: string
      }
    }
    projectCreation: {
      sidebar: {
        background: string
        altBackground: string
      }
    }
  }

  interface PaletteOptions {
    other?: {
      divider: string
    }
  }

  interface TypeBackground {
    lightGrey: string
    table: string
    light: string
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    buttonSmall: true
    caption: true
  }
}
