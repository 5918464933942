import { useState } from 'react'
import { ErrorOption, FieldPath, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, Paper, TextField, Typography } from '@mui/material'

import { DjangoApiError, isGenericError } from '@partnerslate/core/djangoValidation'
import { getReferenceData } from '@partnerslate/feature/central/api/parsers'
import { ReferenceData } from '@partnerslate/feature/central/domain/referenceData'
import { fieldOptionsFromActiveReferenceItems } from '@partnerslate/segment/projects/domain'
import { ButtonWithLoading } from '@partnerslate/ui-components'

import { AllFormFields, extractBackendFormErrors } from '../api/parsers'
import { useRegister } from '../api/queries'
import { DiversityForm } from '../domain/signup'
import * as instrumentation from '../instrumentation'
import { useFormContext } from '../SignupContext'
import { diversityResolver } from '../validations'
import { SignupServerError } from './SignupServerError'
import { useRedirectToSignupFirstPage } from './useRedirect'
import { MultiSelect } from '@partnerslate/ui-components/lib/forms/MultiSelect'

export default function DiversityScreen() {
  const REFERENCE_DATA: ReferenceData = getReferenceData()
  const DIVERSE_BACKGROUND_OPTIONS = fieldOptionsFromActiveReferenceItems(
    REFERENCE_DATA.diverseBackgrounds,
  )
  const DIVERSE_SOCIAL_IMPACTS_OPTIONS = fieldOptionsFromActiveReferenceItems(
    REFERENCE_DATA.diverseSocialImpacts,
  )

  const [genericFormError, setGenericFormError] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<
    [FieldPath<AllFormFields>, ErrorOption][] | null
  >(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const registerService = useRegister()
  const { form, setDiversityForm } = useFormContext()
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<DiversityForm>({
    defaultValues: form.diversityForm,
    resolver: diversityResolver,
  })

  const companyType = form.accountTypeForm.companyType
  useRedirectToSignupFirstPage(companyType)

  const onSubmit = (data: DiversityForm) => {
    setIsLoading(true)
    instrumentation.fillInDiversityDetails()

    setDiversityForm(data)
    setGenericFormError(false)

    const formData = { ...form, diversityForm: data }

    registerService.mutate(formData, {
      onSuccess: (user) => {
        instrumentation.updateIntercomUser({
          name: `${user.firstName} ${user.lastName}`,
          email: user.emailAddress,
          userId: user.id,
          dateJoined: user.dateJoined,
        })
        instrumentation.signupCompleted(user)
        navigate(`/register/book-a-call`)
        setIsLoading(false)
      },
      onError: (e: DjangoApiError) => {
        if (isGenericError(e)) {
          setGenericFormError(true)
          return
        }

        setValidationErrors(extractBackendFormErrors(e.fieldErrors))

        setIsLoading(false)
      },
    })
  }

  return (
    <Container>
      <Typography variant="h2" mt={6} textAlign="center">
        Demographic Survey
      </Typography>
      <Typography variant="h5" textAlign="center" pt={1} pb={2}>
        Some major retailers and brands have diversity commitments. You are not required to answer
        any of these questions.
      </Typography>

      <Grid container my={5} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper>
            <Box onSubmit={handleSubmit(onSubmit)} component="form" px={{ xs: 2, md: 8 }} py={3}>
              <Box pb={3}>
                <Typography>
                  Is your company founded, led or owned by someone from a diverse background?
                </Typography>
                <MultiSelect
                  control={control}
                  name="background"
                  options={DIVERSE_BACKGROUND_OPTIONS}
                  error={errors.background}
                  placeholder={'Select all that apply'}
                />
              </Box>

              <Box pb={3}>
                <Typography>
                  Is there a social impact component to your company's mission?
                </Typography>
                <MultiSelect
                  control={control}
                  name="socialImpact"
                  options={DIVERSE_SOCIAL_IMPACTS_OPTIONS}
                  label=""
                  placeholder="Select all that
                  apply"
                  error={errors.socialImpact}
                />
              </Box>

              <Typography>
                If your company is mission-driven in some other way, please describe.
              </Typography>
              <TextField
                {...register('missionSummary')}
                error={!!errors.missionSummary}
                fullWidth
                helperText={errors.missionSummary?.message ?? ''}
                label=""
                sx={{ mt: 0 }}
              />

              <Box my={4}>
                <ButtonWithLoading
                  fullWidth
                  isLoading={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create account
                </ButtonWithLoading>
              </Box>
              <SignupServerError
                isGenericFormError={genericFormError}
                validationErrors={validationErrors}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
