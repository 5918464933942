import { Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

type LayoutProps = {
  left: React.ReactNode
  right: React.ReactNode
}
export function Layout(props: LayoutProps) {
  return (
    <Box
      sx={{
        height: {
          xs: 'calc(100vh - 56px)',
          md: 'calc(100vh - 80px)',
        },
      }}
    >
      <Grid height="100%" container>
        <Grid xs={12} md={4} xl={3}>
          {props.left}
        </Grid>
        <Grid xs={12} md={8} xl={9}>
          {props.right}
        </Grid>
      </Grid>
    </Box>
  )
}
