import { ErrorOption, FieldPath } from 'react-hook-form'

import { FieldErrorMap } from '@partnerslate/core/djangoValidation'

import {
  AccountTypeForm,
  CompanyProfileForm,
  CompleteComanDetailsForm,
  CompleteComanProfileForm,
  CompleteSignupForm,
  DiversityForm,
  ManufacturerTypeForm,
  PersonalAccountForm,
  SignupForm,
} from '../domain/signup'
import { InviteInfoPayload, InviteInformation } from './schemas'

export const createAccountRegistrationPayload = (
  data: SignupForm,
  affiliateMetadata: Record<string, unknown> | null,
) => {
  const {
    accountTypeForm,
    manufacturerTypeForm,
    personalAccountForm,
    companyProfileForm,
    completeComanProfileForm,
    completeComanDetailsForm,
    completeSignupForm,
    diversityForm,
  } = data

  let howDidYouHearAboutUsAnswer = companyProfileForm.howDidYouHearAboutUsOther
    ? companyProfileForm.howDidYouHearAboutUsOther
    : companyProfileForm.howDidYouHearAboutUs

  if (accountTypeForm.companyType === 'brand') {
    howDidYouHearAboutUsAnswer = completeSignupForm.howDidYouHearAboutUsOther
      ? completeSignupForm.howDidYouHearAboutUsOther
      : completeSignupForm.howDidYouHearAboutUs
  }

  return {
    email: personalAccountForm.email,
    password: personalAccountForm.password,
    first_name: personalAccountForm.firstName,
    last_name: personalAccountForm.lastName,
    job_title: personalAccountForm.jobTitle,
    accepted_tos: personalAccountForm.acceptedTos,
    company: {
      company_name: companyProfileForm.companyName,
      company_phone: companyProfileForm.companyPhone,
      website: companyProfileForm.website,
      company_type: accountTypeForm.companyType,
      brand_stage: companyProfileForm.brandStage,
      coman_revenue: companyProfileForm.comanRevenue,
      year_founded: companyProfileForm.yearFounded,
      distribution_type: companyProfileForm.distributionType,
      manufacturer_type: manufacturerTypeForm.manufacturerType,
      services_offered: companyProfileForm.servicesOffered,
      how_did_you_hear_about_us: howDidYouHearAboutUsAnswer,
      product_categories: completeComanProfileForm.productCategories,
      packaging_formats: completeComanProfileForm.packagingFormats,
      processing_capabilities: completeComanProfileForm.processingCapabilities,
      production_run_size: completeComanProfileForm.productionRunSizes,
      facility_locations: completeComanProfileForm.facilityLocations,
      number_of_facility_locations:
        completeComanProfileForm.numberOfFacilityLocations === ''
          ? null
          : completeComanProfileForm.numberOfFacilityLocations,
      coman_previous_brand_sizes: completeComanProfileForm.comanPreviousBrandSizes,
      about: completeComanDetailsForm.about,
      services_and_capabilities: completeComanDetailsForm.servicesAndCapabilities,
      match_preferences: completeComanDetailsForm.matchPreferences,
      diverse_backgrounds: diversityForm.background,
      diverse_social_impacts: diversityForm.socialImpact,
      diverse_mission_summary: diversityForm.missionSummary,
      address: {
        address1: companyProfileForm.address.address1,
        address2: companyProfileForm.address.address2,
        city: companyProfileForm.address.city,
        state: companyProfileForm.address.state,
        postal_code: companyProfileForm.address.postalCode,
        country_code: companyProfileForm.address.countryCode || 'US',
      },
    },
    affiliate_metadata: affiliateMetadata,
  }
}

export type AllFormFields = AccountTypeForm &
  ManufacturerTypeForm &
  PersonalAccountForm &
  CompanyProfileForm &
  CompleteComanProfileForm &
  CompleteSignupForm &
  CompleteComanDetailsForm &
  DiversityForm

export function extractBackendFormErrors(
  backendErrors: FieldErrorMap,
): [FieldPath<AllFormFields>, ErrorOption][] {
  // To make sure we are handling all possible errors, we require the `maybeFormErrors` to include
  // all form fields by using the `AllFormFields` type. Even though not all of the `AllFormFields`
  // values end up getting passed to the API endpoint it's ok since those empty values will be
  // filtered out when we call trimPossibleFormErrorsToActualRHFErrors
  const maybeFormErrorsMap: Record<FieldPath<AllFormFields>, string | undefined> = {
    companyType: backendErrors['company_type'],
    manufacturerType: backendErrors['manufacturer_type'],
    firstName: backendErrors['first_name'],
    lastName: backendErrors['last_name'],
    jobTitle: backendErrors['job_title'],
    email: backendErrors['email'],
    password: backendErrors['password'],
    confirmPassword: backendErrors['confirm_password'],
    acceptedTos: backendErrors['accepted_tos'],
    companyName: backendErrors['company_name'],
    website: backendErrors['website'],
    address: backendErrors['address'],
    companyPhone: backendErrors['company_phone'],
    yearFounded: backendErrors['year_founded'],
    servicesOffered: backendErrors['services_offered'],
    brandStage: backendErrors['brand_stage'],
    comanRevenue: backendErrors['coman_revenue'],
    distributionType: backendErrors['distribution_type'],
    howDidYouHearAboutUs: backendErrors['how_did_you_hear_about_us'],
    howDidYouHearAboutUsOther: backendErrors['how_did_you_hear_about_us_other'],
    productCategories: backendErrors['product_categories'],
    packagingFormats: backendErrors['packaging_formats'],
    processingCapabilities: backendErrors['processing_capabilities'],
    productionRunSizes: backendErrors['production_run_size'],
    fundingState: backendErrors['funding_state'],
    productDevelopment: backendErrors['product_development'],
    initialRunSize: backendErrors['intial_run_size'],
    'address.address1': backendErrors['address1'],
    'address.address2': backendErrors['address2'],
    'address.city': backendErrors['city'],
    'address.state': backendErrors['state'],
    'address.postalCode': backendErrors['postal_code'],
    'address.countryCode': backendErrors['country_code'],
    background: backendErrors['diverse_backgrounds'],
    socialImpact: backendErrors['diverse_social_impacts'],
    missionSummary: backendErrors['diverse_mission_summary'],
    facilityLocations: backendErrors['facility_locations'],
    numberOfFacilityLocations: backendErrors['number_of_facility_locations'],
    comanPreviousBrandSizes: backendErrors['coman_previous_brand_sizes'],
    about: backendErrors['about'],
    matchPreferences: backendErrors['match_preferences'],
    servicesAndCapabilities: backendErrors['services_and_capabilities'],
  }

  return trimPossibleFormErrorsToActualRHFErrors(
    Object.entries(maybeFormErrorsMap) as [FieldPath<AllFormFields>, string | undefined][],
  )
}

function trimPossibleFormErrorsToActualRHFErrors<FormFieldName extends string>(
  maybeFormErrors: [FormFieldName, string | undefined][],
): [FormFieldName, ErrorOption][] {
  // first, filter out fields that had no backend errors reported
  const formErrors: [FormFieldName, string][] = maybeFormErrors.filter(
    (x): x is [FormFieldName, string] => x[1] !== undefined,
  )

  // next, convert the raw message string into an RHF ErrorOption
  return formErrors.map(([fieldName, message]) => [fieldName, { type: 'django', message }])
}

export function parseInviteInfo(data: InviteInfoPayload): InviteInformation {
  return {
    email: data.email_address,
    companyName: data.company.company_name,
    companyId: data.company.id,
  }
}
