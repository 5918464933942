import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'

import { ButtonWithLoading } from '@partnerslate/ui-components'

import { useAcceptTeamInvite, useInviteInformation, useLoginAfterAccept } from '../api/queries'
import { InviteInformation, signUpFormSchema, SignUpFormValues } from '../api/schemas'
import SideImage from '../assets/images/leaves.jpg'
import * as instrumentation from '../instrumentation'

export function TeamInviteAcceptScreen(): JSX.Element {
  const { token } = useParams<{ token: string }>()
  const { data: inviteInformation, error, isLoading } = useInviteInformation(token)

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error || !inviteInformation) {
    return <InvalidInvitation />
  }

  return <TeamInviteAccept token={token!} inviteInformation={inviteInformation} />
}

type TeamInviteAcceptProps = {
  token: string
  inviteInformation: InviteInformation
}

export function TeamInviteAccept({ token, inviteInformation }: TeamInviteAcceptProps) {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignUpFormValues>({
    resolver: zodResolver(signUpFormSchema),
    mode: 'onChange',
    defaultValues: {
      email: inviteInformation.email,
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
    },
  })

  const acceptMutation = useAcceptTeamInvite(setError)
  const loginMutation = useLoginAfterAccept()

  useEffect(() => {
    instrumentation.acceptingTeamInvite(inviteInformation.companyId)
  }, [inviteInformation])

  const onSubmit = async (values: SignUpFormValues) => {
    try {
      await acceptMutation.mutateAsync({ values, token })

      await loginMutation.mutateAsync({
        email: values.email,
        password: values.password,
      })

      instrumentation.acceptedTeamInvite(inviteInformation.companyId)

      // Redirect to / to be redirect to appropriate page
      navigate('/')
    } catch (error) {
      console.error('Failed to complete the signup process', error)
    }
  }

  const isLoading = acceptMutation.isLoading || loginMutation.isLoading

  return (
    <Grid container height="100vh">
      <Grid
        item
        xs={false}
        sm={2}
        sx={{
          backgroundImage: `url(${SideImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={10} component={Paper}>
        <Container maxWidth="sm">
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h2" sx={{ mb: 2 }}>
              Join {inviteInformation.companyName}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
              You've been invited to join this team. We'll just need a few details to get you
              started.
            </Typography>

            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                {...register('email')}
                margin="normal"
                fullWidth
                label="Email Address"
                variant="filled"
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register('firstName')}
                    autoComplete="given-name"
                    fullWidth
                    label="First Name"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register('lastName')}
                    fullWidth
                    label="Last Name"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                </Grid>
              </Grid>

              <TextField
                {...register('jobTitle')}
                margin="normal"
                fullWidth
                label="Job Title"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WorkOutlineIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.jobTitle}
                helperText={errors.jobTitle?.message}
              />

              <TextField
                {...register('password')}
                margin="normal"
                fullWidth
                label="Password"
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />

              <TextField
                {...register('confirmPassword')}
                margin="normal"
                fullWidth
                label="Confirm Password"
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />

              {acceptMutation.error ? (
                <Typography textAlign="end" variant="subtitle2" color="error">
                  There was an error while accepting the invite. Please try again in a moment.
                </Typography>
              ) : null}

              <ButtonWithLoading type="submit" fullWidth variant="contained" isLoading={isLoading}>
                Join Team
              </ButtonWithLoading>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

function InvalidInvitation() {
  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 8, textAlign: 'center' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Invalid Invitation
      </Typography>
      <Typography variant="body1" paragraph>
        Sorry, your invitation is no longer active or has expired.
      </Typography>
      <Typography variant="body1">
        If you need a new invite, please contact your primary PartnerSlate account holder.
      </Typography>
    </Container>
  )
}

export default TeamInviteAcceptScreen
