import * as Sentry from '@sentry/react'
import axios from 'axios'
import { z } from 'zod'

class GenericError {
  readonly kind = 'generic' // tagged union aka discriminated union https://basarat.gitbook.io/typescript/type-system/discriminated-unions
}

// key is the backend field name, value is validation error message
export type FieldErrorMap = Record<string, string>

class ValidationErrors {
  readonly kind = 'validation'
  constructor(readonly fieldErrors: FieldErrorMap) {}
}

export type DjangoApiError = GenericError | ValidationErrors

export function isGenericError(error: DjangoApiError): error is GenericError {
  return error.kind === 'generic'
}
export function isValidationErrors(error: DjangoApiError): error is ValidationErrors {
  return error.kind === 'validation'
}

const DjangoValidationErrorPayloadSchema = z.object({
  error: z.array(
    z.object({
      field: z.string(),
      message: z.string(),
    }),
  ),
})

export function castAxiosError(error: unknown): DjangoApiError {
  if (!axios.isAxiosError(error)) {
    return new GenericError()
  }

  if (error.response?.status !== 400) {
    return new GenericError()
  }

  const parseResult = DjangoValidationErrorPayloadSchema.safeParse(error.response?.data)
  if (!parseResult.success) {
    // failing to correctly parse a 400 from Django is a bug that we should raise, but still handle gracefully
    Sentry.captureException(parseResult.error)
    return new GenericError()
  }

  const convertedErrors: FieldErrorMap = Object.fromEntries(
    parseResult.data.error.map(({ field, message }) => [field, message]),
  )

  return new ValidationErrors(convertedErrors)
}
