import { z } from 'zod'

import { zod } from '@partnerslate/core'

export const ExistsSchema = z.object({ exists: z.boolean() })

export const InviteInfoPayloadSchema = z.object({
  email_address: z.string(),
  company: z.object({
    company_name: z.string(),
    id: z.string(),
  }),
})

export const signUpFormSchema = z
  .object({
    email: z.string().email('Invalid email address'),
    password: zod.psPassword(),
    confirmPassword: z.string(),
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    jobTitle: z.string().optional(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  })

export type InviteInformation = {
  email: string
  companyName: string
  companyId: string
}

export type SignUpFormValues = z.infer<typeof signUpFormSchema>
export type InviteInfoPayload = z.infer<typeof InviteInfoPayloadSchema>
